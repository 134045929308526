import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import {
    Col,
    Container,
    Row,
    Form,
    Alert,
    Badge,
    ButtonGroup,
    ToggleButton,
} from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { RouteComponentProps } from "react-router-dom";
import {
    Product as ProductDto,
    Category,
    ProductVariant,
    ProductVariantOverride,
    GetProductInput,
    UpdateProductInput,
    UpdateProductOutput,
    GetProductOutput,
    UpdateProductLifecycleInput,
} from "../../client/core";
import { Color, Path, Storage } from "../../env";
import { Button, icon, variant } from "../form/Button";
import ReactMarkdown from "react-markdown";
import MDEditor from "@uiw/react-md-editor";
import { Typeahead } from "react-bootstrap-typeahead";

export interface ProductState {
    product: ProductDto;
    editProduct: ProductDto;
    productImage?: File;
    activity: Activity;
    error: string;
}

export enum Activity {
    View,
    Archived,
    Edit,
}

export enum ProductAttribute {
    Name,
    Price,
    UOM,
    Volume,
    DetailName,
    DetailDescription,
    VariantName,
    VariantOptions,
    VariantOverride,
    Category,
}

export interface ProductProps extends RouteComponentProps {
    productID: string;
    getProduct: (input: GetProductInput) => Promise<GetProductOutput>;
    updateProduct: (input: UpdateProductInput) => Promise<UpdateProductOutput>;
    updateProductLifecycle: (input: UpdateProductLifecycleInput) => Promise<UpdateProductOutput>;
    auth: typeof Auth;
    imageStore: typeof S3;
    categories: Category[];
    onImageUpdate: (productID: string, image: File) => void;
    onInfoUpdate: (product: ProductDto) => void;
    onClose: () => void;
}

export default class Product extends Component<ProductProps, ProductState> {
    constructor(props: ProductProps) {
        super(props);
        this.state = {
            product: {
                name: "",
                price: 0.0,
                disabled: false,
            },
            editProduct: {
                name: "",
                price: 0.0,
                disabled: false,
            },
            error: "",
            activity: Activity.View,
        };
    }

    componentDidMount() {
        this.getProduct()
    }

    changeActivity = (activity: Activity) => {
        this.setState({
            activity: activity,
        });
    };

    handleChange = (attribute: ProductAttribute, value: any, index?: number, subindex?: number) => {
        let product = this.state.editProduct;
        switch (attribute) {
            case ProductAttribute.Name:
                product.name = value;
                break;
            case ProductAttribute.Price:
                product.price = value;
                break;
            case ProductAttribute.UOM:
                product.uom = value;
                break;
            case ProductAttribute.Volume:
                product.volume = value;
                break;
            case ProductAttribute.Category:
                product.category = value;
                break;
            case ProductAttribute.DetailName:
                product.details![index!].name = value
                break;
            case ProductAttribute.DetailDescription:
                product.details![index!].description = value
                break;
            case ProductAttribute.VariantName:
                product.variants![index!].name = value
                break;
            case ProductAttribute.VariantOptions:
                product.variants![index!].options[subindex!] = value
                break;
        }
        this.setState({
            editProduct: product,
        });
    };

    handleVariantOverrideChange = (key: string, override: ProductVariantOverride) => {
        let overrides = this.state.editProduct.variantOverrides

        if (!overrides) {
            overrides = new Map<string, ProductVariantOverride>()
        }

        if (!override.price || override.price === 0) {
            overrides?.delete(key)
            return this.setState({
                editProduct: {
                    ...this.state.editProduct,
                    variantOverrides: overrides,
                }
            })
        }

        overrides?.set(key, override)
        this.setState({
            editProduct: {
                ...this.state.editProduct,
                variantOverrides: overrides,
            }
        })
    }

    handleAddDetail = () => {
        let product = this.state.editProduct;
        if (!product.details) {
            product.details = []
        }
        product.details?.push({
            name: "",
            description: "",
        })
        this.setState({
            editProduct: product,
        })
    }

    handleAddVariant = () => {
        let product = this.state.editProduct;
        if (!product.variants) {
            product.variants = []
        }
        product.variants?.push({
            name: "",
            options: [""]
        })
        this.setState({
            editProduct: product,
        })
    }

    handleAddVariantOption = (index: number) => {
        let product = this.state.editProduct;
        product.variants![index].options.push("")
        this.setState({
            editProduct: product,
        })
    }

    handleImageChange = (image: File) => {
        this.setState({
            productImage: image,
        });
    };

    handleSave = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            const product: ProductDto = this.cleanProduct(this.state.editProduct)
            this.props.updateProduct({
                product: product,
                identityToken: token,
                shopID: shopID!,
            })
                .then(async (output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to update shop products. Please navigate back to the shop dashboard.",
                        });
                    }

                    this.props.onInfoUpdate(product);

                    if (this.state.productImage) {
                        const imageName =
                            localStorage.getItem(Storage.ShopID) +
                            "/products/" +
                            Date.now().toString() + "/" +
                            product.id!

                        await this.props.imageStore.put(
                            imageName,
                            this.state.productImage!,
                            {
                                contentType: "image/jpeg",
                            }
                        );

                        this.props.onImageUpdate(
                            this.props.productID,
                            this.state.productImage
                        );
                    }

                    this.setState({
                        activity: Activity.View,
                    });
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    cleanProduct = (product: ProductDto): ProductDto => {
        product.price = Number(product.price)
        // Remove variants without a name and remove options with no value
        let variants: ProductVariant[] | undefined = product.variants
        if (variants) {
            variants = variants.filter(variant => {
                if (!variant.name || variant.name.trim() === "") {
                    return false
                }

                return true
            }).map(variant => {
                variant.options = variant.options.filter(option => (option && option.trim() !== ""))
                return variant
            })
        }
        product.variants = variants

        return product
    }

    handleSelect = (productID: string) => {
        this.props.history.push(Path.Products + "/" + productID);
    };

    getProduct = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.getProduct({
                productID: this.props.productID,
                identityToken: token,
                shopID: shopID!,
            })
                .then((output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to list shop products. Please navigate back to the shop dashboard.",
                        });
                    }

                    if (!output.product) {
                        return this.setState({
                            error: "This products details could not be retrieved at this time.",
                        });
                    }

                    // Order product variants alphabetically 
                    if (output.product.variants) {
                        output.product.variants = output.product.variants.sort((a, b) => a.name.localeCompare(b.name))
                        output.product.variants.map(variant => {
                            if (!variant.options || variant.options.length === 0) {
                                return variant
                            }
                            variant.options = variant.options.sort((a, b) => a.localeCompare(b))
                            return variant
                        })
                    }

                    this.setState({
                        product: output.product,
                        editProduct: output.product,
                    });
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    handleArchive = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then(session => {
            if (!this.state.product.id) {
                return this.setState({
                    error: "We were unable to identify this product so it was not put into your archive."
                })
            }
            this.props.updateProductLifecycle({
                identityToken: session.getIdToken().getJwtToken(),
                lifecycle: "Archived",
                productID: this.state.product.id,
                shopID: shopID
            }).then(response => {
                if (response.statusCode !== 204) {
                    return this.setState({
                        error: "We were unable to archive this product at this time. If this problem persists please contact support@stumbled.online."
                    })
                }
                this.setState({
                    activity: Activity.Archived
                })
            })
        })
    }

    render() {
        switch (this.state.activity) {
            case Activity.View:
                const viewProps: ProductDisplayProps = {
                    product: this.state.product,
                    categories: this.props.categories,
                    changeActivity: this.changeActivity,
                    onArchive: this.handleArchive,
                    error: this.state.error,
                };
                return <ViewProductDisplay {...viewProps} />;
            case Activity.Archived:
                const archiveProductProps: ArchivedProductProps = {
                    product: this.state.product,
                    onClose: this.props.onClose,
                }
                return <ArchivedProduct {...archiveProductProps} />
            default:
                const editProps: ProductDisplayProps = {
                    product: this.state.editProduct,
                    categories: this.props.categories,
                    changeActivity: this.changeActivity,
                    saveChanges: this.handleSave,
                    onChange: this.handleChange,
                    addImage: this.handleImageChange,
                    onProductDetailAdd: this.handleAddDetail,
                    onProductVariantAdd: this.handleAddVariant,
                    onProductVariantOptionAdd: this.handleAddVariantOption,
                    onProductVariantOverrideChange: this.handleVariantOverrideChange,
                    error: this.state.error,
                };
                return <EditProductDisplay {...editProps} />;
        }
    }
}

export interface ArchivedProductProps {
    product: ProductDto;
    onClose: () => void;
}

export const ArchivedProduct: FunctionComponent<ArchivedProductProps> = (
    props
) => (
    <Container fluid>
        <Row>
            <Col>
                <p>Product was archived successfully. You can restore the product by viewing your archived products under 'Actions', finding the associated product and clicking the 'Restore' button.</p>
            </Col>
        </Row>
        <Row>
            <Col>
                <Button
                    name={"OK"}
                    variant={variant.Primary}
                    id="archive-product"
                    style={{ float: "right" }}
                    onClick={() => props.onClose()}
                />
            </Col>
        </Row>
    </Container>
)

export interface ProductDisplayProps {
    product: ProductDto;
    productImage?: File;
    error: string;
    categories: Category[];
    changeActivity: (activity: Activity) => void;
    onChange?: (attribute: ProductAttribute, value: any, index?: number, subindex?: number) => void;
    addImage?: (image: File) => void;
    onProductDetailAdd?: () => void;
    onProductVariantAdd?: () => void;
    onProductVariantOptionAdd?: (index: number) => void;
    onProductVariantOverrideChange?: (key: string, variant: ProductVariantOverride) => void
    onArchive?: () => void;
    saveChanges?: () => void;
}

export const ViewProductDisplay: FunctionComponent<ProductDisplayProps> = (
    props
) => (
    <Container fluid>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row>
            <Col>
                <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                    <Col xs={3}>
                        <span>Name</span>
                    </Col>
                    <Col>
                        <span>{props.product.name}</span>
                    </Col>
                </Row>
                <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                    <Col xs={3}>
                        <span>Price</span>
                    </Col>
                    <Col>
                        <span>£{Number(props.product.price).toFixed(2)}</span>
                    </Col>
                </Row>
                {(props.product.volume !== 0) && (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>Units</span>
                        </Col>
                        <Col>
                            <span>{props.product.volume!}</span>
                        </Col>
                    </Row>
                )}
                {props.product.uom && (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>UOM</span>
                        </Col>
                        <Col>
                            <span>{props.product.uom!}</span>
                        </Col>
                    </Row>
                )}
                {props.product.category && (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>Category</span>
                        </Col>
                        <Col>
                            <span>{props.product.category!}</span>
                        </Col>
                    </Row>
                )}
                {props.product.details ? (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>Details</span>
                        </Col>
                        <Col>
                            {props.product.details.map(detail => (
                                <Row key={`product-details-${detail.name}`}>
                                    {(detail.name !== "") && (
                                        <>
                                            <Col xs={12} sm={4} lg={3}>{detail.name}:</Col>
                                            <Col>
                                                <ReactMarkdown>
                                                    {detail.description}
                                                </ReactMarkdown>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            ))}
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>Details</span>
                        </Col>
                        <Col>
                            <span>No details</span>
                        </Col>
                    </Row>
                )}
                {props.product.variants ? (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>Variants</span>
                        </Col>
                        <Col>
                            {props.product.variants.map(detail => (
                                <Row key={`product-variant-${detail.name}`}>
                                    <>
                                        <Col xs={12} sm={4} lg={3}>{detail.name}</Col>
                                        <Col>
                                            {detail.options.map(option => (
                                                <Badge key={`product-variant-option-${detail.name}-${option}`} bg="secondary" style={{ background: Color.Primary, padding: ".5rem", color: Color.White, margin: ".1rem" }}>{option}</Badge>
                                            ))}
                                        </Col>
                                    </>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                        <Col xs={3}>
                            <span>Variants</span>
                        </Col>
                        <Col>
                            <span>No variants</span>
                        </Col>
                    </Row>
                )}
            </Col>
            <Col xs={2}>
                <Button
                    name={"Edit"}
                    variant={variant.Primary}
                    id="edit-product"
                    style={{ float: "right" }}
                    onClick={() => props.changeActivity(Activity.Edit)}
                />
                <Button
                    name={"Archive"}
                    variant={variant.Primary}
                    id="archive-product"
                    style={{ float: "right" }}
                    onClick={() => props.onArchive!()}
                />
            </Col>
        </Row>
    </Container >
);

export enum EditProductMode {
    Product,
    Variants,
}

export interface EditProductState {
    ImageName: string;
    Mode: EditProductMode;
    IsCustomUnits: boolean;
}

export class EditProductDisplay extends Component<
    ProductDisplayProps,
    EditProductState
> {
    constructor(props: ProductDisplayProps) {
        super(props);
        this.state = {
            ImageName: "Select Image",
            Mode: EditProductMode.Product,
            IsCustomUnits: (this.props.product.uom !== undefined),
        };
    }

    handleModeChange = (mode: EditProductMode): void => {
        this.setState({
            Mode: mode,
        })
    }

    render() {
        return (
            <Container fluid>
                <Row style={{ marginBottom: "1rem" }}>
                    <Col style={{ textAlign: "left" }}>
                        <ButtonGroup className="mb-2">
                            <ToggleButton
                                color={Color.Primary}
                                id={"variant-override"}
                                type="radio"
                                name="Product"
                                variant="outline-secondary"
                                style={{
                                    borderColor: Color.Primary,
                                    background: (this.state.Mode == EditProductMode.Product) ? Color.Primary : "none",
                                    color: (this.state.Mode == EditProductMode.Product) ? Color.White : Color.Primary
                                }}
                                checked={(this.state.Mode == EditProductMode.Product)}
                                onClick={() => this.handleModeChange(EditProductMode.Product)}
                                value={"Product"}
                            >
                                Product
                            </ToggleButton>
                            <ToggleButton
                                color={Color.Primary}
                                id={"variant-override"}
                                type="radio"
                                variant="outline-secondary"
                                name="Variant Overrides"
                                style={{
                                    borderColor: Color.Primary,
                                    background: (this.state.Mode == EditProductMode.Variants) ? Color.Primary : "none",
                                    color: (this.state.Mode == EditProductMode.Variants) ? Color.White : Color.Primary
                                }}
                                checked={(this.state.Mode == EditProductMode.Variants)}
                                onClick={() => this.handleModeChange(EditProductMode.Variants)}
                                value={"Variant Overrides"}
                            >
                                Variant Overrides
                            </ToggleButton>
                        </ButtonGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                        <Button
                            id="cancel-product"
                            variant={variant.Secondary}
                            name="Cancel"
                            onClick={() => this.props.changeActivity(Activity.View)}
                        />
                        <Button
                            variant={variant.Primary}
                            name={"Save"}
                            id="submit-product"
                            onClick={() => this.props.saveChanges!()}
                        />
                    </Col>
                </Row>
                <div>
                    {(this.state.Mode == EditProductMode.Variants) && <ProductVariantOverridesForm onChange={this.props.onProductVariantOverrideChange!} product={this.props.product} />}
                    {(this.state.Mode == EditProductMode.Product) && <Form>
                        <Alert variant={"danger"} show={this.props.error != ""}>
                            {this.props.error}
                        </Alert>
                        <Row>
                            <h5>Image</h5>
                            <Col>
                                <div className="input-group mb-3">
                                    <div className="custom-file" style={{ width: "100%" }}>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="inputGroupFile"
                                            accept="image/jpeg"
                                            style={{
                                                display: "block",
                                                width: "100%"
                                            }}
                                            onChange={(e) => {
                                                this.props.addImage!(e.target.files![0]);
                                                this.setState({
                                                    ImageName: e.target.files![0].name,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <h5>Summary</h5>
                            <Col xs={12} md={6}>
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={"name"}
                                        value={this.props.product.name}
                                        id={"name"}
                                        placeholder="Name"
                                        alt="Product Name Textbox"
                                        onChange={(e) =>
                                            this.props.onChange!(
                                                ProductAttribute.Name,
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="input-group mb-3">
                                    <Typeahead
                                        labelKey="category"
                                        style={{ flex: "1 1 auto", border: 0 }}
                                        id={"category"}
                                        placeholder="Category"
                                        defaultInputValue={this.props.product.category}
                                        onInputChange={value =>
                                            this.props.onChange!(
                                                ProductAttribute.Category,
                                                value as string,
                                            )
                                        }
                                        onChange={value =>
                                            this.props.onChange!(
                                                ProductAttribute.Category,
                                                value[0] as string,
                                            )
                                        }
                                        options={(this.props.categories) ? this.props.categories.map(category => category.name) : []}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={"price"}
                                        id={"price"}
                                        value={this.props.product.price}
                                        alt="Product Price Textbox"
                                        placeholder="Price"
                                        onChange={(e) =>
                                            this.props.onChange!(
                                                ProductAttribute.Price,
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Col>
                            {(this.state.IsCustomUnits) && (
                                <>
                                    <Col xs={12} md={3}>
                                        <div className="input-group mb-3">
                                            <input
                                                className={"form-control"}
                                                name={"volume"}
                                                id={"volume"}
                                                onChange={(e) =>
                                                    this.props.onChange!(
                                                        ProductAttribute.Volume,
                                                        e.target.value
                                                    )
                                                }
                                                value={this.props.product.volume}
                                                placeholder="Quantity"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <div className="input-group mb-3">
                                            <input
                                                className={"form-control"}
                                                name={"uom"}
                                                id={"uom"}
                                                value={this.props.product.uom}
                                                onChange={(e) =>
                                                    this.props.onChange!(
                                                        ProductAttribute.UOM,
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Units"
                                            />
                                        </div>
                                    </Col>
                                </>
                            )}
                            <Col xs={12}>
                                <div className="input-group mb-3">
                                    <Form.Check
                                        inline
                                        label="Set price using custom units"
                                        name="is-custom-units"
                                        type={"checkbox"}
                                        id={"is-custom-units"}
                                        onChange={(e) => {
                                            this.setState({
                                                IsCustomUnits: e.target.checked,
                                            })
                                        }}
                                        checked={this.state.IsCustomUnits}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <h5>Details</h5>
                            <div className="input-group mb-3">
                                <Row>
                                    {(this.props.product.details) && this.props.product.details.map((detail, index) => (
                                        <ProductDetailForm key={`product-details-form-${index}-wrapper`} name={detail.name} description={detail.description} onChange={((attr: ProductAttribute, value: any) => this.props.onChange!(attr, value, index))} />
                                    ))}
                                    <Col>
                                        <Button variant={variant.Primary} icon={icon.Plus} onClick={() => this.props.onProductDetailAdd!()} />
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        <Row>
                            <h5>Variants</h5>
                            <div className="input-group mb-3">
                                {(this.props.product.variants) && this.props.product.variants.map((variant, index) => (
                                    <ProductVariantForm key={`product-variant-form-${index}-wrapper`} name={variant.name} options={variant.options} onOptionAdd={() => this.props.onProductVariantOptionAdd!(index)} onChange={((attr: ProductAttribute, value: any, optionIndex?: number) => this.props.onChange!(attr, value, index, optionIndex))} />
                                ))}
                                <Col>
                                    <Button variant={variant.Primary} icon={icon.Plus} onClick={() => this.props.onProductVariantAdd!()} />
                                </Col>
                            </div>
                        </Row>
                    </Form>}
                </div>
            </Container >
        );
    }
}

export interface ProductDetailFormProps {
    name: string
    description: string
    onChange?: (attribute: ProductAttribute, value: any) => void;
}

export const ProductDetailForm: FunctionComponent<ProductDetailFormProps> = (props) => (
    <Row style={{ margin: "0px", width: "100%", padding: "0px" }}>
        <Col xs={3}>
            <input
                style={{ border: 0 }}
                type="text"
                className="form-control"
                name={"detail-name"}
                id={"detail-name"}
                placeholder="Section"
                value={props.name}
                onChange={(e) =>
                    props.onChange!(
                        ProductAttribute.DetailName,
                        e.target.value
                    )
                }
            />
        </Col>
        <Col xs={9}>
            <MDEditor
                style={{ background: Color.White, border: 0, boxShadow: "0" }}
                color={Color.White}
                value={props.description}
                onChange={value => props.onChange!(
                    ProductAttribute.DetailDescription,
                    value
                )}
            />
        </Col>
    </Row>
)

export interface ProductVariantFormProps {
    name: string
    options: string[]
    onChange: (attribute: ProductAttribute, value: any, optionIndex?: number) => void;
    onOptionAdd: () => void;
}

export const ProductVariantForm: FunctionComponent<ProductVariantFormProps> = (props) => (
    <Row style={{ margin: "0px", width: "100%", padding: "0px" }}>
        <Col xs={3} style={{ paddingLeft: "0px" }}>
            <input
                style={{ border: 0 }}
                type="text"
                className="form-control"
                name={"variant-name"}
                id={"variant-name"}
                placeholder="Name"
                value={props.name}
                onChange={(e) =>
                    props.onChange(
                        ProductAttribute.VariantName,
                        e.target.value
                    )
                }
            />
        </Col>
        <Col xs={9}>
            <Row>
                {props.options.map((option, index) => (
                    <Col key={`product-variant-option-${props.name}-${option}`} xs={10}>
                        <input
                            style={{ border: 0 }}
                            type="text"
                            className="form-control"
                            name={"variant-name"}
                            id={"variant-name"}
                            placeholder="Option"
                            value={option}
                            onChange={(e) =>
                                props.onChange!(ProductAttribute.VariantOptions, e.target.value, index)
                            }
                        />
                    </Col>

                ))}
                <Col xs={2} style={{ textAlign: "right" }}>
                    <Button variant={variant.Primary} icon={icon.Plus} onClick={() => props.onOptionAdd()} />
                </Col>
            </Row>
        </Col>
    </Row>
)

export interface ProductVariantOverridesFormProps {
    product: ProductDto
    onChange: (key: string, variant: ProductVariantOverride) => void
}

export const ProductVariantOverridesForm: FunctionComponent<ProductVariantOverridesFormProps> = (props) => (
    <>
        {(!props.product.variants) && <div>
            <p>This product has no variants.</p>
        </div>}
        {(props.product.variants) && <div>
            <p>Overrides are highlighted with a green border. If you do not specify an override, the default product value will be used.</p>
            <Row style={{ borderBottom: `1px solid ${Color.Grey}`, padding: ".5rem" }}>
                {props.product.variants.map(variant => (
                    <Col>
                        <span style={{ fontWeight: 700 }}>{variant.name}</span>
                    </Col>
                ))}
                <Col>
                    <span style={{ fontWeight: 700 }}>Price</span>
                </Col>
            </Row>
            {generateVariantCombinations(props.product.variants).map((row, rowIndex) => {
                const override: ProductVariantOverride | undefined = getVariantOverride(row.join("#"), props.product.variantOverrides)
                return (
                    <Row key={rowIndex} style={{ borderBottom: `1px solid ${Color.Grey}`, padding: ".5rem" }}>
                        {row.map((cell, cellIndex) => (
                            <Col key={cellIndex}>
                                <span>{cell}</span>
                            </Col>
                        ))}
                        <Col>
                            <CurrencyInput
                                className="form-control"
                                name={`variant-price-override-${rowIndex}`}
                                id={`variant-price-override-${rowIndex}`}
                                placeholder="Enter price"
                                style={{ border: (override && override?.price) ? `2px solid ${Color.Primary}` : `1px solid ${Color.Grey}` }}
                                defaultValue={(override) ? override.price || props.product.price : props.product.price}
                                decimalsLimit={2}
                                onValueChange={(value) =>
                                    props.onChange(row.join("#"), {
                                        key: row.join("#"),
                                        price: Number(value),
                                    })
                                }
                            />
                        </Col>
                    </Row>
                )
            })}
        </div>}
    </>
)

export function getVariantOverride(key: string, overrides?: Map<string, ProductVariantOverride>): ProductVariantOverride | undefined {
    if (!overrides) {
        return
    }

    if (overrides.has(key)) {
        return overrides.get(key)
    }

    const values: string[] = key.split("#")

    const result = Array.from(overrides.values()).filter(value => {
        let isPresent = true
        if (!value.key || !arraysEqual(value.key.split("#"), values)) {
            isPresent = false
        }
        return isPresent
    })

    if (result.length === 0) {
        return
    }

    return result[0]
}

function arraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return arr1.every((elem) => arr2.includes(elem));
}

export function generateVariantCombinations(variants: ProductVariant[]) {
    if (!variants || variants.length === 0) {
        return [];
    }

    const combinations: string[][] = [[]];

    for (let i = 0; i < variants.length; i++) {
        const temp: string[][] = [];
        for (let j = 0; j < combinations.length; j++) {
            for (let k = 0; k < variants[i].options.length; k++) {
                temp.push(combinations[j].concat(variants[i].options[k]));
            }
        }
        combinations.length = 0;
        combinations.push(...temp);
    }

    return combinations;
}