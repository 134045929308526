import { Auth } from "aws-amplify";
import React, { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, ListGroup, Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { IAssignUserShopRequest, IGetShopContactDetails, IRegisterShop, ShopSummary } from "../../../client/core";
import { Color, Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";
import {
    Image,
} from "react-bootstrap";

export interface RegisterShopState {
    error: string;
    shop?: ShopSummary;
}

export interface RegisterShopProps extends RouteComponentProps {
    getShopContactAPI: IGetShopContactDetails
    assignUserToShopAPI: IAssignUserShopRequest
    coreAPI: IRegisterShop;
    auth: typeof Auth;
}

export default class RegisterShop extends Component<
    RegisterShopProps,
    RegisterShopState
> {
    constructor(props: RegisterShopProps) {
        super(props);
        this.state = {
            error: "",
        };
    }

    componentDidMount(): void {
        const shopIdToCreate = localStorage.getItem(Storage.OpenShop)
        if (shopIdToCreate == null) {
            return
        }

        this.props.getShopContactAPI.GetShopContactDetails({
            shopId: shopIdToCreate
        }).then(response => {
            if (response instanceof Error) {
                return
            }

            if (!response.body) {
                return
            }

            this.setState({
                shop: {
                    name: response.body.name,
                    email: response.body.email,
                    address: response.body.address,
                    phone: response.body.phone,
                    accountStatus: "",
                    actions: [],
                    active: false,
                    alias: "",
                    color: "",
                    credit: 0,
                    deliveryStatus: "",
                    logo: response.body.logo || "",
                }
            })
        })
    }

    handleSubmit = () => {
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.coreAPI
                    .RegisterShop({
                        identityToken: token,
                        referral: localStorage.getItem(Storage.Referral) || undefined
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            return this.props.history.push(Path.Login);
                        }

                        if (output.statusCode === 201) {
                            localStorage.setItem(Storage.ShopID, output.shopID);
                            return this.props.history.push(Path.RegisterShopName);
                        }

                        this.setState({
                            error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                        });
                    })
                    .catch(() =>
                        this.setState({
                            error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                        })
                    );
            })
            .catch(() => {
                this.props.history.push(Path.Login);
            });
    };

    handleAssignUserToShop = () => {
        const shopIdToAssignUser = localStorage.getItem(Storage.OpenShop)
        if (shopIdToAssignUser == null) {
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.assignUserToShopAPI.AssignUserToShopRequest({
                identityToken: session.getIdToken().getJwtToken(),
                shopId: shopIdToAssignUser
            }).then(response => {
                if (response instanceof Error || response.statusCode !== 200) {
                    return this.setState({
                        error: "We were unable to allocate you to this shop at this time. Please contact support@stumbled.online if this issue persists."
                    })
                }
            })
        }).catch(err => {
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.auth.signOut()
            this.props.history.push(Path.Login)
        })
    }

    render() {
        const props: RegisterShopFormProps = {
            error: this.state.error,
            shop: this.state.shop,
            onSubmit: this.handleSubmit,
            onAssignUserToShop: this.handleAssignUserToShop,
        };
        return <RegisterShopForm {...props} />;
    }
}

export interface RegisterShopFormProps {
    error: string;
    shop?: ShopSummary;
    onSubmit: () => void;
    onAssignUserToShop: () => void;
}

export const RegisterShopForm: FunctionComponent<RegisterShopFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        {(props.shop) && (
            <>
                <Row>
                    <Col xs={12} style={{ margin: "auto" }}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Is this your shop?</Card.Title>
                                <Row>
                                    <Col>
                                        <div>
                                            <div className={"elevate"} style={{ width: "250px", textAlign: "center", margin: "auto", border: "1px solid lightgrey" }}>
                                                <Image style={{ display: "block", padding: "1rem" }} width="100%" src={props.shop.logo || "https://via.placeholder.com/350x350.png?text=Logo"} rounded />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Name</div>
                                                    {props.shop?.name || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Address</div>
                                                    {props.shop?.address || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                    <Col>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Email</div>
                                                    {props.shop?.email || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Phone</div>
                                                    {props.shop?.phone || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "right" }}>
                                    <Col>
                                        <Button
                                            variant={variant.Primary}
                                            name={"Yes, that's my shop"}
                                            onClick={() => props.onAssignUserToShop()}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: "center", marginTop: "1rem" }}>
                        <h4 style={{ color: Color.Primary }}>Or</h4>
                    </Col>
                </Row>
            </>
        )}
        <Card style={{ marginTop: "1rem" }}>
            <Card.Body>
                <Card.Title>Register your shop</Card.Title>
                <Card.Text>If you are <strong style={{ color: Color.Primary }}>NOT</strong> registering to sell produce, and instead want to buy produce, you can download our mobile app from the App Store: <span style={{ color: Color.Primary, fontWeight: "bold" }}>Stumbled: Local Shops</span> or visit <a style={{ color: Color.Primary }} href={"https://shops.stumbled.online"}>https://shops.stumbled.online</a>. You will be able to login with the same credentials.</Card.Text>
                <Card.Text>If you wish to register your shop please click on the button below and continue with your journey.</Card.Text>
                <Row>
                    <Col style={{ textAlign: "right" }}>
                        <Button
                            variant={variant.Primary}
                            name={"Register your shop"}
                            onClick={() => props.onSubmit()} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Form>
);
