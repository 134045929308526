import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { ListGroup } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { IDeleteProducts, Product } from "../../client/core";
import {
    Product as ProductDto,
} from "../../client/core";
import { Color, Storage } from "../../env";
import { Button, variant } from "../form/Button";

export interface DeleteProductsState {
    error: string;
    selectedProducts: Map<string, ProductDto>;
}

export interface DeleteProductsProps extends RouteComponentProps {
    auth: typeof Auth
    coreAPI: IDeleteProducts
    products: Product[]
    postSubmit: (productIds: string[]) => void
}

export enum formAttribute {
    name = "name",
    image = "image"
}

export default class DeleteProducts extends Component<
    DeleteProductsProps,
    DeleteProductsState
> {
    constructor(props: DeleteProductsProps) {
        super(props);
        this.state = {
            selectedProducts: new Map(),
            error: "",
        };
    }

    handleSelect = (productId: string) => {
        const selectedProducts: Map<string, Product> = this.state.selectedProducts
        if (selectedProducts.has(productId)) {
            selectedProducts.delete(productId)
            return this.setState({
                selectedProducts: selectedProducts,
            })
        }
        if (!this.props.products) {
            return
        }
        const products: Product[] = this.props.products.filter(product => product.id! == productId)
        if (products.length == 0) {
            return
        }
        selectedProducts.set(productId, products[0])
        return this.setState({
            selectedProducts: selectedProducts
        })
    };

    handleDelete = (): void => {
        this.props.auth.currentSession().then(session => {
            const productIds = Array.from(this.state.selectedProducts.keys())
            this.props.coreAPI.DeleteProducts({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: localStorage.getItem(Storage.ShopID)!,
                productIDs: productIds,
            }).then(response => {
                if (response.statusCode === 401 || response.statusCode === 403) {
                    localStorage.removeItem(Storage.ShopID)
                    return this.setState({
                        error: "You were forbidden to update shop products. Please navigate back to the shop dashboard to resync."
                    })
                }

                if (response.statusCode !== 200) {
                    this.setState({
                        error: "Sorry we were unable to delete these products at this time. Please try again later.",
                    })
                }

                this.props.postSubmit(productIds)
            }).catch(() => {
                this.setState({
                    error: "Sorry we were unable to delete these products at this time. Please try again later.",
                })
            })
        }).catch(() => {
            this.setState({
                error: "Something went wrong with your authentication. Please navigate back to the shop dashboard to resync.",
            })
        })
    }

    render() {
        const props: DeleteProductsFormProps = {
            error: this.state.error,
            selectedProducts: this.state.selectedProducts,
            products: this.props.products,
            onSelect: this.handleSelect,
            onDelete: this.handleDelete,
        };
        return <DeleteProductsForm {...props} />;
    }
}

export interface DeleteProductsFormProps {
    error: string;
    products: Product[];
    selectedProducts: Map<string, Product>;
    onSelect: (productName: string) => void;
    onDelete: () => void;
}

export const DeleteProductsForm: FunctionComponent<DeleteProductsFormProps> = props => {
    return (
        <>
            <ListGroup style={{ overflowY: "scroll", height: "60vh" }}>
                {props.products && props.products.map(product => (
                    <>
                        <ListGroup.Item active={props.selectedProducts.has(product.id!)} onClick={() => props.onSelect(product.id!)}>{product.name}</ListGroup.Item>
                    </>
                ))}
            </ListGroup>
            <Button
                name="Delete"
                style={{float: "right"}}
                variant={variant.Danger}
                onClick={() => props.onDelete()}
            />
        </>
    )
}