import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
    Col,
    Container,
    Row,
    Card,
    Alert,
    ListGroup,
    Nav,
    Dropdown,
    Tabs,
    Tab,
    Badge,
    Accordion,
    Table,
    Modal,
} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { OrderItem, IGetOrders, IUpdateOrderStatus, Order, OrderStatus, OrderType, AmendOrder as AmendOrderAPI } from "../../client/core";
import { Color, Path, Storage } from "../../env";
import { CSVLink } from "react-csv";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, icon, variant } from "../form/Button";
import AmendOrder, { AmendOrderView } from "./AmendOrder";

export interface ListOrdersState {
    day: string;
    deliveryDays: string[];
    deliveryOrders: Map<string, Map<string, Order[]>>;
    collectionOrders: Order[];
    ordersCSVData: Map<string, CSVData>
    activeAmendOrder?: Order;
    error: string;
}

export interface ListOrdersProps extends RouteComponentProps {
    coreAPI: IGetOrders;
    updateOrderStatusAPI: IUpdateOrderStatus;
    auth: typeof Auth;
}

export default class ListOrders extends Component<
    ListOrdersProps,
    ListOrdersState
> {
    constructor(props: ListOrdersProps) {
        super(props);
        this.state = {
            day: "",
            deliveryDays: [],
            deliveryOrders: new Map(),
            ordersCSVData: new Map(),
            error: "",
            collectionOrders: [],
        };
    }

    handleDayChange = (day: string): void => {
        this.setState({
            day: day,
        })
    }

    componentDidMount() {
        this.getOrders();
    }

    logOut = (): void => {
        localStorage.removeItem(Storage.ShopID);
        this.props.auth.signOut();
        localStorage.removeItem(Storage.IsLoggedIn);
        return this.props.history.push(Path.Login);
    };

    getSession = async (): Promise<CognitoUserSession | Error> => {
        try {
            const session = await this.props.auth.currentSession();
            return session;
        } catch (err) {
            return new Error("could not retrieve user session");
        }
    };

    updateOrderStatus = async (status: OrderStatus, order: Order): Promise<void> => {
        const session = await this.getSession();
        if (session instanceof Error) {
            return this.logOut();
        }

        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return this.logOut();
        }

        const response = await this.props.updateOrderStatusAPI.UpdateOrderStatus({
            shopID: shopID,
            identityToken: session.getIdToken().getJwtToken(),
            paymentID: order.paymentId!,
            status: status,
        })

        if (response instanceof Error) {
            return this.setState({
                error: "We could not update the order status."
            })
        }

        if (response.statusCode === 403) {
            return this.logOut();
        }

        if (order.type === "collection" || !order.deliveryDate) {
            return this.setState({
                collectionOrders: this.state.collectionOrders.map(o => {
                    if (o.paymentId === order.paymentId) {
                        o.status = status
                        return o
                    }
                    return o
                })
            })
        }

        const timeIndex = order.deliveryDate.time.start + "-" + order.deliveryDate.time.end
        const orders = this.state.deliveryOrders.get(order.deliveryDate.day)?.get(timeIndex)!.map(o => {
            if (o.paymentId == order.paymentId) {
                o.status = status
                return o
            }
            return o
        })!
        const stateOrders = this.state.deliveryOrders
        stateOrders.get(order.deliveryDate.day)?.set(timeIndex, orders)
        this.setState({
            deliveryOrders: stateOrders,
        })
    }

    getOrders = async (): Promise<void> => {
        const session = await this.getSession();
        if (session instanceof Error) {
            return this.logOut();
        }

        const shopID = localStorage.getItem(Storage.ShopID);
        if (!shopID) {
            return;
        }

        const response = await this.props.coreAPI.GetOrders({
            shopID: shopID,
            identityToken: session.getIdToken().getJwtToken(),
        });
        if (response instanceof Error) {
            this.setState({
                error: response.message,
            });
            return;
        }

        if (response.statusCode == 403) {
            return this.logOut();
        }

        if (response.statusCode != 201) {
            this.setState({
                error: "Could not retrieve orders. Please try again shorty.",
            });
        }

        const deliveryOrders: Map<string, Map<string, Order[]>> = this.marshalDeliveryOrders(response.response?.orders)
        const collectionOrders: Order[] = this.marshalCollectionOrders(response.response?.orders)
        const deliveryDays: string[] = this.marshalDeliveryDays(response.response?.orders || [])

        this.setState({
            day: (deliveryDays.length > 0) ? deliveryDays[deliveryDays.length - 1] : "",
            error: "",
            deliveryOrders: deliveryOrders,
            collectionOrders: collectionOrders,
            ordersCSVData: this.mapDeliveryOrdersToCSV(response.response?.orders),
            deliveryDays: deliveryDays,
        });
    };

    marshalDeliveryDays = (orders: Order[]): string[] => {
        return Array.from(new Set(orders.sort((a, b) => ((a.timestamp || 0) > (b.timestamp || 0)) ? 1 : -1).filter(order => order.type == "delivery").map(order => String(order.deliveryDate?.day))))
    }

    marshalCollectionOrders = (orders: Order[] | undefined): Order[] => {
        if (!orders) {
            return [];
        }
        return orders.filter(o => o.type == "collection").sort((a, b) => (a.timestamp! < b.timestamp!) ? -1 : 1)
    }

    marshalDeliveryOrders = (orders: Order[] | undefined): Map<string, Map<string, Order[]>> => {
        const response: Map<string, Map<string, Order[]>> = new Map();
        if (!orders) {
            return response
        }

        for (const order of orders) {
            if (order.type !== "delivery" || !order.deliveryDate) {
                continue;
            }
            if (!response.get(order.deliveryDate.day)) {
                response.set(order.deliveryDate.day, new Map<string, Order[]>())
            }
            const o: Map<string, Order[]> = response.get(order.deliveryDate.day)!
            let timeOrders = o.get(order.deliveryDate.time.start + "-" + order.deliveryDate.time.end)
            if (!timeOrders) {
                timeOrders = []
            }
            timeOrders.push(order)
            o.set(order.deliveryDate.time.start + "-" + order.deliveryDate.time.end, timeOrders)
            response.set(order.deliveryDate.day, o)
        }

        return response
    }

    mapDeliveryOrdersToCSV = (orders: Order[] | undefined): Map<string, CSVData> => {
        const response: Map<string, CSVData> = new Map();
        if (!orders) {
            return response;
        }

        const marshalledOrders: Map<string, Map<string, Order[]>> = this.marshalDeliveryOrders(orders)

        for (const day of Array.from(marshalledOrders.keys())) {
            response.set(day, {
                data: [],
                headers: [
                    {
                        label: "Timerange",
                        key: "timerange"
                    },
                    {
                        label: "Order Id",
                        key: "orderId"
                    },
                    {
                        label: "Address",
                        key: "address"
                    },
                    {
                        label: "Details",
                        key: "details"
                    },
                    {
                        label: "Delivered",
                        key: "delivered",
                    }
                ],
                filename: day + " Deliveries.csv"
            })

            for (const time of Array.from(marshalledOrders.get(day)!.keys())) {
                response.get(day)?.data.push({
                    timerange: time,
                    orderId: "-",
                    address: "-",
                    delivered: "-",
                })
                for (const order of marshalledOrders.get(day)!.get(time)!) {
                    response.get(day)?.data.push({
                        orderId: order.paymentId!.substring(0, 10),
                        address: order.address,
                        details: order.deliveryDetails,
                        delivered: (order.status == OrderStatus.Delivered) ? "Yes" : ""
                    })
                }
            }
        }

        return response;
    }

    handleAmendOrderSubmit = (order: Order): void => {
        let orders: Order[]
        if (order.type == OrderType.OrderTypeCollection) {
            orders = this.state.collectionOrders
            orders = orders.map(o => {
                if (o.paymentId !== order.paymentId) {
                    return o
                }
                return order
            })
            return this.setState({
                collectionOrders: orders
            })
        }

        const timeKey = `${order.deliveryDate?.time.start}-${order.deliveryDate?.time.end}`
        orders = this.state.deliveryOrders.get(order.deliveryDate?.day!)!.get(timeKey)!
        if (!orders) {
            return
        }

        orders = orders.map(o => {
            if (o.paymentId !== order.paymentId) {
                return o
            }
            return order
        })
        const deliveryOrders = this.state.deliveryOrders
        const dayDeliveryOrders = deliveryOrders.get(order.deliveryDate?.day!)
        dayDeliveryOrders?.set(timeKey, orders)
        deliveryOrders.set(order.deliveryDate?.day!, dayDeliveryOrders!)
        this.setState({
            activeAmendOrder: undefined,
            deliveryOrders: deliveryOrders,
        })
    }

    render() {
        const props: ListOrdersViewProps = {
            ...this.props,
            orderDays: this.state.deliveryDays,
            collectionOrders: this.state.collectionOrders,
            day: this.state.day,
            deliveryOrders: this.state.deliveryOrders,
            deliveryOrdersCSVData: this.state.ordersCSVData,
            error: this.state.error,
            activeAmendOrder: this.state.activeAmendOrder,
            auth: this.props.auth,
            onDayChange: this.handleDayChange,
            onOrderStatusUpdate: this.updateOrderStatus,
            onAmendOrder: (order?: Order) => this.setState({ activeAmendOrder: order }),
            onAmendOrderSubmit: this.handleAmendOrderSubmit,
        };
        return <ListOrdersView {...props} />;
    }
}

export interface ListOrdersViewProps extends RouteComponentProps {
    orderDays: string[];
    deliveryOrders: Map<string, Map<string, Order[]>>;
    collectionOrders: Order[];
    deliveryOrdersCSVData: Map<string, CSVData>;
    day: string;
    error: string;
    activeAmendOrder?: Order;
    auth: typeof Auth;
    onDayChange: (day: string) => void;
    onOrderStatusUpdate: (status: OrderStatus, order: Order) => void
    onAmendOrder: (order?: Order) => void;
    onAmendOrderSubmit: (order: Order) => void;
}

export interface CSVData {
    data: object[]
    headers?: any;
    separator?: string;
    filename?: string;
}

export const ListOrdersView: FunctionComponent<ListOrdersViewProps> = (
    props
) => (
    <>
        <Modal
            size="lg"
            show={props.activeAmendOrder !== undefined}
            onHide={() => props.onAmendOrder()}
        >
            <Modal.Header>
                <Modal.Title>Amend Order</Modal.Title>
                <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.onAmendOrder()} />
            </Modal.Header>
            <Modal.Body>
                {(props.activeAmendOrder) && <AmendOrder {...props} onCancel={() => props.onAmendOrder()} postSubmit={(order) => props.onAmendOrderSubmit(order)} auth={props.auth} amendOrderAPI={new AmendOrderAPI()} order={props.activeAmendOrder} />}
            </Modal.Body>
        </Modal>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <h1 style={{ marginBottom: "2rem" }}>Orders</h1>
        <div style={{ marginTop: "1rem" }}>
            <Tabs
                defaultActiveKey={OrderType.OrderTypeDelivery}
                className="mb-3"
                variant="pills"
            >
                <Tab eventKey={OrderType.OrderTypeDelivery} title="Deliveries">
                    <Row>
                        <Col xs={12} sm={9}>
                            {props.deliveryOrders.has(props.day) && (
                                <DaySelector activeDay={props.day} days={props.orderDays} {...props} />
                            )}
                        </Col>
                        <Col xs={12} sm={3} style={{ textAlign: "right" }}>
                            <Dropdown>
                                <Dropdown.Toggle style={{ background: Color.Primary, border: 0, borderRadius: 0 }} id="actions">
                                    Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.deliveryOrdersCSVData.has(props.day) && (
                                        <CSVLink
                                            className="dropdown-item"
                                            data={props.deliveryOrdersCSVData.get(props.day)!.data}
                                            headers={props.deliveryOrdersCSVData.get(props.day)!.headers}
                                            filename={props.deliveryOrdersCSVData.get(props.day)?.filename}
                                        >
                                            Export to CSV
                                        </CSVLink>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    {props.deliveryOrders.has(props.day) && (
                        <>
                            {Array.from(props.deliveryOrders.get(props.day)!.keys()).map((time) => (
                                <div key={`order-time-board-${props.day}-${time}`} style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                                    <h6 style={{ textAlign: "center", padding: "1rem", borderBottom: `.5px solid ${Color.Grey}` }}>Delivery Time: {time}</h6>
                                    <Row>
                                        <Col>
                                            <Row style={{ borderRight: "1px dashed lightgrey" }}>
                                                <OrdersView
                                                    title="To Be Delivered"
                                                    status={OrderStatus.ToBeDelivered}
                                                    orders={props.deliveryOrders.get(props.day)!.get(time)!.filter(o => o.status == OrderStatus.ToBeDelivered && o.type == OrderType.OrderTypeDelivery)}
                                                    onClick={order => props.onOrderStatusUpdate(OrderStatus.Delivered, order)}
                                                    onAmend={props.onAmendOrder}
                                                />
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row style={{ borderLeft: "1px dashed lightgrey" }}>
                                                <OrdersView
                                                    title="Delivered"
                                                    status={OrderStatus.Delivered}
                                                    orders={props.deliveryOrders.get(props.day)!.get(time)!.filter(o => o.status != OrderStatus.ToBeDelivered && o.type == OrderType.OrderTypeDelivery)}
                                                    onClick={order => props.onOrderStatusUpdate(OrderStatus.ToBeDelivered, order)}
                                                    onAmend={props.onAmendOrder}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </>
                    )}
                </Tab>

                <Tab eventKey={OrderType.OrderTypeCollection} title="Collections">
                    {props.collectionOrders && (
                        <Row>
                            <Col>
                                <Row style={{ borderRight: "1px dashed lightgrey" }}>
                                    <OrdersView
                                        title="To Be Collected"
                                        status={OrderStatus.ToBeCollected}
                                        orders={props.collectionOrders.filter(o => o.status == OrderStatus.ToBeCollected)}
                                        onClick={order => props.onOrderStatusUpdate(OrderStatus.Delivered, order)}
                                        onAmend={props.onAmendOrder}
                                    />
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ borderLeft: "1px dashed lightgrey" }}>
                                    <OrdersView
                                        title="Collected"
                                        status={OrderStatus.Delivered}
                                        orders={props.collectionOrders.filter(o => o.status != OrderStatus.ToBeCollected)}
                                        onClick={order => props.onOrderStatusUpdate(OrderStatus.ToBeCollected, order)}
                                        onAmend={props.onAmendOrder}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Tab>
            </Tabs>
            {((props.deliveryOrders.size === 0 && props.collectionOrders.length === 0) && props.error.trim() == "") && (
                <div style={{ marginTop: ".2rem", padding: "0 2rem" }}>
                    <FontAwesomeIcon color={Color.Primary} style={{ display: "inline-block", fontSize: "1.5rem", marginRight: "1rem" }} icon={faCircleCheck} />
                    <Card.Text style={{ display: "inline-block" }}>You currently have no orders</Card.Text>
                </div>
            )}
        </div>
    </>
);

export interface OrdersViewProps {
    title: string
    status: OrderStatus
    orders: Order[]
    onClick: (order: Order) => void
    onAmend: (order?: Order) => void
}

export const OrdersView: FunctionComponent<OrdersViewProps> = props => (
    <div style={{ padding: "1rem", display: "block" }}>
        <h6>{props.title}</h6>
        <Accordion>
            {props.orders.map((order) => (
                <Row style={{ marginBottom: ".5rem" }}>
                    {props.status == OrderStatus.Delivered && (
                        <Col xs={4} md={2}>
                            <Button
                                style={{ width: "100%", height: "100%" }}
                                icon={icon.LeftArrow}
                                variant={variant.Danger}
                                onClick={() => props.onClick(order)} />
                        </Col>
                    )}
                    <Col xs={8} md={10}>
                        <Accordion.Item style={{ width: "100%", display: "inline-block" }} eventKey={order.paymentId!}>
                            <Accordion.Header>
                                <Row style={{ width: "100%", marginRight: ".2rem", marginLeft: ".2rem" }}>
                                    <Col>
                                        <span>Order: {order.paymentId!.substring(0, 10)}</span>
                                    </Col>
                                    <Col style={{ textAlign: "right" }}>
                                        <span style={{ fontWeight: 700 }}>Total: £{Number(order.paymentTotal).toFixed(2)}</span>
                                    </Col>
                                </Row>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>Actions</td>
                                            <td><Button onClick={() => props.onAmend(order)} variant={variant.Primary} name="Amend" /></td>
                                        </tr>
                                        <tr>
                                            <td>ID</td>
                                            <td>{order.customerId}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{order.customerEmail}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{order.address}</td>
                                        </tr>
                                        <tr>
                                            <td>Details</td>
                                            {(!order.deliveryDetails) && (<td>None</td>)}
                                            {(order.deliveryDetails) && (<td>{order.deliveryDetails.split(",").map(detail => <li style={{ listStyleType: "none" }}>{detail}</li>)}</td>)}
                                        </tr>
                                        <tr>
                                            <td>Items</td>
                                            <td><ListItemsView items={order.items || []} /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Col>
                    {props.status == OrderStatus.ToBeDelivered && (
                        <Col xs={4} md={2}>
                            <Button
                                style={{ width: "100%", height: "100%" }}
                                icon={icon.RightArrow}
                                variant={variant.Primary}
                                onClick={() => props.onClick(order)} />
                        </Col>
                    )}
                    {props.status == OrderStatus.ToBeCollected && (
                        <Col xs={4} md={2}>
                            <Button
                                style={{ width: "100%", height: "100%" }}
                                icon={icon.RightArrow}
                                variant={variant.Primary}
                                onClick={() => props.onClick(order)} />
                        </Col>
                    )}

                </Row>
            ))}
        </Accordion >
    </div >
)

export interface DaySelectorProps {
    activeDay: string
    days: string[]
    onDayChange: (day: string) => void;
}

export const DaySelector: FunctionComponent<DaySelectorProps> = props => (
    <Nav className="me-auto">
        {props.days.map(day => (props.activeDay === day) ? (
            <Nav.Link style={{ background: Color.Primary, padding: 10, color: Color.White, fontWeight: "bold" }} onClick={() => props.onDayChange(day)}>{day}</Nav.Link>
        ) : (

            <Nav.Link style={{ color: Color.Primary, fontWeight: 600 }} onClick={() => props.onDayChange(day)}>{day}</Nav.Link>
        ))}
    </Nav>
)

export interface ListItemsViewProps {
    items: OrderItem[];
}

export const ListItemsView: FunctionComponent<ListItemsViewProps> = (props) => (
    <ListGroup style={{ width: "100%" }}>
        {props.items &&
            props.items.map((item, key) => (
                <ListGroup.Item
                    as="li"
                    style={{ width: "100%" }}
                >
                    <div className="ms-2">
                        <div className="fw-bold">{item.name}</div>
                        <div className="fw-bold" style={{ float: "right" }}>£{Number(item.price).toFixed(2)}</div>
                    </div>
                    {item.variants && item.variants.map(variant => (
                        <Badge bg={"secondary"} style={{ color: Color.White, padding: ".5rem", margin: ".1rem" }}>{variant.name}: {variant.value}</Badge>
                    ))}
                    <Badge bg={"secondary"} style={{ color: Color.White, padding: ".5rem", margin: ".1rem" }}>{item.volume + " " + item.uom} {((item.volume && item.volume > 1) && (!item.uom || item.uom == "Unit")) ? "s" : ""}</Badge>
                </ListGroup.Item>
            ))}
    </ListGroup>
);