import { Component, FunctionComponent, PureComponent, ReactElement, useRef } from "react";
import { ListGroup } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { Product } from "../../client/core";
import {
    Product as ProductDto,
} from "../../client/core";
import { Button, variant } from "../form/Button";

export interface LabelMakerState {
    error: string;
    selectedProducts: Map<string, ProductDto>;
}

export interface LabelMakerProps {
    products: Product[]
}

export enum formAttribute {
    name = "name",
    image = "image"
}

export default class LabelMaker extends Component<
    LabelMakerProps,
    LabelMakerState
> {
    constructor(props: LabelMakerProps) {
        super(props);
        this.state = {
            selectedProducts: new Map(),
            error: "",
        };
    }

    handleSelect = (productName: string) => {
        const selectedProducts: Map<string, Product> = this.state.selectedProducts
        if (selectedProducts.has(productName)) {
            selectedProducts.delete(productName)
            return this.setState({
                selectedProducts: selectedProducts,
            })
        }
        if (!this.props.products) {
            return
        }
        const products: Product[] = this.props.products.filter(product => product.name == productName)
        if (products.length == 0) {
            return
        }
        selectedProducts.set(productName, products[0])
        return this.setState({
            selectedProducts: selectedProducts
        })
    };

    render() {
        const props: LabelMakerFormProps = {
            error: this.state.error,
            selectedProducts: this.state.selectedProducts,
            products: this.props.products,
            onSelect: this.handleSelect,
        };
        return <LabelMakerForm {...props} />;
    }
}

export interface LabelMakerFormProps {
    error: string;
    products: Product[];
    selectedProducts: Map<string, Product>;
    onSelect: (productName: string) => void;
}

export const LabelMakerForm: FunctionComponent<LabelMakerFormProps> = props => {
    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <ListGroup style={{ overflowY: "scroll", height: "60vh" }}>
                {props.products && props.products.map(product => (
                    <>
                        <ListGroup.Item active={props.selectedProducts.has(product.name)} onClick={() => props.onSelect(product.name)}>{product.name}</ListGroup.Item>
                    </>
                ))}
            </ListGroup>
            <Button
                style={{ float: "right" }}
                variant={variant.Primary}
                name={"Generate"}
                onClick={handlePrint}
            />
            <div style={{ display: "none" }}>
                {(props.selectedProducts) && <LabelContent ref={componentRef} products={Array.from(props.selectedProducts.values())} />}
            </div>
        </>
    )
}

export interface LabelContentProps {
    products: Product[];
}

export class LabelContent extends PureComponent<LabelContentProps> {

    productLabels() {
        let components = []
        if (this.props.products.length === 0) {
            components.push(<div></div>)
            return components
        }
        if (this.props.products.length === 1) {
            components.push((
                <div>
                    <ProductLabel product={this.props.products[0]} />
                </div>
            ))
            return components
        }
        for (let i = 0; i < this.props.products.length; i = i + 2) {
            components.push((
                <div style={{ display: "inline-block", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <ProductLabel product={this.props.products[i]} />
                        {(i + 1 < this.props.products.length) && <ProductLabel product={this.props.products[i + 1]} />}
                    </div>
                </div>
            ))
        }
        return components
    }

    render() {
        return (
            <div>
                {this.productLabels()}
            </div>
        )
    }
}

export interface ProductLabelProps {
    product: Product
}


export const ProductLabel: FunctionComponent<ProductLabelProps> = ({ product }) => (
    <div style={{ width: "49.9999%", display: "inline-block", padding: "2rem 4rem 2rem 4rem", border: ".5px dotted grey" }}>
        <div style={{ width: "100%", display: "block", textAlign: "center" }}>
            <h1>{product.name}</h1>
            <h2>£{product.price} {(product.volume) ? `per ${product.volume}${product.uom}` : ""}</h2>
        </div>
        <hr />
        <div style={{ display: "block", width: "100%", textAlign: "left" }}>
            <table>
                {product.details?.map(detail => (
                    <>
                        {(detail.name !== "") ? (
                            <p style={{ fontSize: "1.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>{detail.name}:</span> <span>{detail.description}</span>
                            </p>
                        ) : ""}
                    </>
                ))}
            </table>
        </div>
    </div>
)