import { FunctionComponent } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Cart } from "react-bootstrap-icons";
import { FaRegCheckCircle } from "react-icons/fa";
import { Color, Path } from "../../../env";
import { TbSquareNumber1, TbSquareNumber2, TbSquareNumber3 } from "react-icons/tb";

export const MemberRegisterConfirmation: FunctionComponent<{}> = () => (
    <Container fluid>
        <Row>
            <Col xs={12} md={6} lg={5} style={{ margin: "auto", marginTop: "10vh" }}>
                <Card>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center", marginBottom: "2rem", color: Color.Primary, fontWeight: 700, fontSize: "1.5rem" }}>We have sent you a verification email</Card.Title>
                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={12} style={{ textAlign: "center" }}>
                                <FaRegCheckCircle style={{ margin: "auto" }} size={100} color={Color.Primary} />
                            </Col>
                        </Row>

                        <Card.Text>Great news! You are one step away from becoming a Stumbled member. You should have received an email confirming that you are the owner of the email address.</Card.Text>
                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={2} style={{ textAlign: "center" }}>
                                <TbSquareNumber1 size={60} color={Color.Primary} />
                            </Col>
                            <Col>
                                <Card.Text style={{ color: Color.Primary, fontWeight: 700, verticalAlign: "middle", }}>Open the verification email in your inbox</Card.Text>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={2} style={{ textAlign: "center" }}>
                                <TbSquareNumber2 size={60} color={Color.Primary} />
                            </Col>
                            <Col>
                                <Card.Text style={{ color: Color.Primary, fontWeight: 700, verticalAlign: "middle", }}>Click on the verification link</Card.Text>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={2} style={{ textAlign: "center" }}>
                                <TbSquareNumber3 size={50} color={Color.Primary} />
                            </Col>
                            <Col>
                                <Card.Text style={{ color: Color.Primary, fontWeight: 700, verticalAlign: "middle", }}>And finally, <a href={Path.Login}>login</a> and set-up your online shop.</Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);
