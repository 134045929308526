import { Component, FunctionComponent } from "react";
import { IAmendOrder, Order, OrderItem } from "../../client/core";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import { Color, Path, Storage } from "../../env";
import { Button, icon, variant } from "../form/Button";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";

export interface AmendOrderState {
    error: string
    // order that is edited
    order: Order
}

export interface AmendOrderProps extends RouteComponentProps {
    // original order prior to amendment
    order: Order
    auth: typeof Auth
    amendOrderAPI: IAmendOrder
    postSubmit?: (order: Order) => void
    onCancel: () => void
}

export default class AmendOrder extends Component<AmendOrderProps, AmendOrderState> {

    constructor(props: AmendOrderProps) {
        super(props)
        this.state = {
            error: "",
            order: props.order
        }
    }

    handleItemDelete = (item: OrderItem): void => {
        const { order } = this.state
        if (!order.items || !order.paymentTotal) {
            return
        }

        const amendedItems = order.items.filter(i => i.id !== item.id)
        const amendedPaymentTotal = ((order.paymentTotal * 100) - (item.price * 100)) / 100
        order.items = amendedItems
        order.paymentTotal = amendedPaymentTotal
        this.setState({
            order: order
        })
    }

    handleSubmit = (): void => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return this.setState({
                error: "Shop could not be identified this might be because the browser session has expired. Please navigate back to shop Dashboard to resync."
            })
        }

        if (!this.state.order.paymentId) {
            return this.setState({
                error: "Sorry, we could not uniquely identify which order you are trying to amend. If this issue persists please contact: support@stumbled.online."
            })
        }

        this.props.auth.currentSession().then(session => {
            this.props.amendOrderAPI.AmendOrder({
                identityToken: session.getIdToken().getJwtToken(),
                order: this.state.order,
                paymentID: this.state.order.paymentId!,
                shopID: shopID,
            }).then(response => {
                if (response instanceof Error) {
                    return this.setState({
                        error: "Sorry, we were unable to amend this order. If this issue persists please contact support@stumbled.online."
                    })
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Sorry, we were unable to amend this order. If this issue persists please contact support@stumbled.online."
                    })
                }

                if (!this.props.postSubmit) {
                    return
                }

                this.props.postSubmit(this.state.order)
            })
        }).catch(err => {
            this.logOut()
        })
    }

    logOut = (): void => {
        localStorage.clear();
        this.props.auth.signOut();
        return this.props.history.push(Path.Login);
    };

    render() {
        const props: AmendOrderViewProps = {
            order: this.state.order,
            error: this.state.error,
            onItemDelete: this.handleItemDelete,
            onSubmit: this.handleSubmit,
            onCancel: this.props.onCancel,
        }
        return <AmendOrderView {...props} />
    }
}

export interface AmendOrderViewProps {
    error: string
    order: Order
    onItemDelete: (item: OrderItem) => void
    onSubmit: () => void
    onCancel: () => void
}

export const AmendOrderView: FunctionComponent<AmendOrderViewProps> = (
    props
) => (
    <Container fluid>
        <Row>
            <Col>
                <Alert variant={"danger"} show={props.error != ""}>
                    {props.error}
                </Alert>
            </Col>
        </Row>
        <Row>
            <Col>
                <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                    <Col xs={3}>
                        <span>Order</span>
                    </Col>
                    <Col>
                        <span>{(props.order.paymentId) && props.order.paymentId.slice(0, 10)}</span>
                    </Col>
                </Row>
                <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                    <Col xs={3}>
                        <span>Total</span>
                    </Col>
                    <Col>
                        <span>£{Number(props.order.paymentTotal).toFixed(2)}</span>
                    </Col>
                </Row>
                <Row style={{ borderBottom: "1px lightgrey solid", marginBottom: ".5rem", padding: ".5rem" }}>
                    <Col xs={3}>
                        <span>Items</span>
                    </Col>
                    <Col>
                        {(props.order.items) && props.order.items.map(item => (
                            <Row>
                                <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderBottom: `1px solid ${Color.Grey}` }}>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={6} style={{ textAlign: "left" }}>
                                                <Card.Text>{item.name}</Card.Text>
                                            </Col>
                                            <Col xs={2} style={{ textAlign: "left" }}>
                                                <Card.Text>£{Number(item.price).toFixed(2)}</Card.Text>
                                            </Col>
                                            <Col xs={2} style={{ textAlign: "right" }}>
                                                <Button variant={variant.Danger} name="X" onClick={() => props.onItemDelete(item)} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card >
                            </Row>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: "right" }}>
                        <Button onClick={() => props.onCancel()} variant={variant.Secondary} name="Cancel" />
                        <Button onClick={() => props.onSubmit()} variant={variant.Primary} name="Submit" />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
)