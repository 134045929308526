import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Alert, Card, Col, Row, Tabs, Tab, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { CustomPaymentDetails, IGetPaymentDetails, IPaymentsOptIn, IPaymentsOptOut, IRegisterAccount, IUpdateCustomPaymentDetails, PaymentDetails } from "../../client/core";
import { Color, Path, Storage } from "../../env";
import { Button, variant } from "../form/Button";
import MDEditor from "@uiw/react-md-editor";
import { TbShirtSport } from "react-icons/tb";
import { textChangeRangeIsUnchanged } from "typescript";
import { InfoCircle } from "react-bootstrap-icons";
import { FaThemeisle } from "react-icons/fa";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ReactMarkdown from "react-markdown";

export interface AccountState {
    isLoading: boolean;
    error: string;
    state: State;
    paymentDetails: PaymentDetails;
}

export enum State {
    None,
    EditPaymentInstructions,
}

export interface AccountProps extends RouteComponentProps {
    getPaymentDetailsAPI: IGetPaymentDetails
    updateCustomPaymentDetailsAPI: IUpdateCustomPaymentDetails
    registerAccountAPI: IRegisterAccount;
    paymentOptOutAPI: IPaymentsOptOut;
    paymentOptInAPI: IPaymentsOptIn;
    auth: typeof Auth;
}

export enum PaymentType {
    Custom,
    Integrated
}

export default class Account extends Component<
    AccountProps,
    AccountState
> {
    constructor(props: AccountProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: "",
            state: State.None,
            paymentDetails: {
                isOptOut: false,
                custom: {
                    instructions: "",
                },
                integrated: {
                    accountId: "",
                }
            }
        };
    }

    componentDidMount(): void {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.auth.signOut()
            return
        }
        this.props.auth.currentSession().then(session => {
            this.props.getPaymentDetailsAPI.GetPaymentDetails({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID,
            }).then(response => {
                if (response.statusCode !== 200) {
                    this.setState({
                        error: "Sorry, we could not retrieve your shop payment details. Please try again later."
                    })
                }

                this.setState({
                    isLoading: false,
                    paymentDetails: response.body!
                })
            }).catch(err => {
                this.setState({
                    error: "Sorry, we could not retrieve your shop payment details. Please try again later."
                })
            })
        }).catch(() => {
            this.props.auth.signOut()
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.history.push(Path.Login);
        })
    }

    handleStripeSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            return this.props.auth.signOut()
        }
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.registerAccountAPI
                    .RegisterAccount({
                        identityToken: token,
                        shopID: shopID!,
                        refreshURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.Payments,
                        returnURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.Payments,
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            localStorage.removeItem(Storage.IsLoggedIn)
                            return this.props.history.push(Path.Login);
                        }

                        if (output.statusCode != 200) {
                            return this.setState({
                                error: "Sorry, something unexpected went wrong during the registration. Please try again later.",
                            });
                        }

                        return window.location.replace(output.accountLink);
                    })
                    .catch(() =>
                        this.setState({
                            error: "Sorry, something unexpected went wrong during the registration. Please try again later.",
                        })
                    );
            })
            .catch(() => {
                localStorage.removeItem(Storage.IsLoggedIn)
                this.props.history.push(Path.Login);
            });
    };

    handleOptInSubmit = () => {
        if (this.state.paymentDetails.integrated.accountId == "") {
            return this.setState({
                error: "Before you can opt-in for integrated payments please active your Stripe payments. You can activate this in the \"Integrated\" tab."
            })
        }

        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            return this.props.auth.signOut()
        }
        this.props.auth.currentSession().then(session => {
            this.props.paymentOptInAPI.PaymentsOptIn({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID,
            }).then(response => {
                if (response.statusCode === 403) {
                    localStorage.removeItem(Storage.IsLoggedIn)
                    return this.props.history.push(Path.Login)
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Sorry, something unexpected went wrong whilst opting-in for payments. Please try again later.",
                    });
                }
            }).catch(() => {
                return this.setState({
                    error: "Sorry, something unexpected went wrong whilst opting-in for payments. Please try again later.",
                });
            })
        }).catch(() => {
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.history.push(Path.Login)
        })
    };

    handleOptOutSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            return this.props.auth.signOut()
        }
        this.props.auth.currentSession().then(session => {
            this.props.paymentOptOutAPI.PaymentsOptOut({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID,
            }).then(response => {
                if (response.statusCode === 403) {
                    localStorage.removeItem(Storage.IsLoggedIn)
                    return this.props.history.push(Path.Login)
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Sorry, something unexpected went wrong whilst opting-out from payments. Please try again later.",
                    });
                }
            }).catch(() => {
                return this.setState({
                    error: "Sorry, something unexpected went wrong whilst opting-out from payments. Please try again later.",
                });
            })
        }).catch(() => {
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.history.push(Path.Login)
        })
    };

    handleStateChange = (state: State) => {
        this.setState({
            error: "",
            state: state,
        })
    }

    handlePaymentDetailsChange = (paymentDetails: PaymentDetails) => {
        this.setState({
            paymentDetails: paymentDetails,
        })
    }

    handleCustomPaymentDetailsSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            return this.props.auth.signOut()
        }
        this.props.auth.currentSession().then(session => {
            this.props.updateCustomPaymentDetailsAPI.UpdateCustomPaymentDetails({
                details: this.state.paymentDetails.custom,
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID,
            }).then(response => {
                if (response instanceof Error || response.statusCode !== 201) {
                    return this.setState({
                        error: "Sorry, something unexpected went wrong whilst updating your custom payment details. Please try again later."
                    })
                }

                this.setState({
                    state: State.None,
                })
            })
        }).catch(() => {
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.history.push(Path.Login)
        })
    }

    render() {
        const selectionProps: PaymentsViewProps = {
            error: this.state.error,
            state: this.state.state,
            paymentDetails: this.state.paymentDetails,
            onStripeActive: this.handleStripeSubmit,
            onOptOut: this.handleOptOutSubmit,
            onOptIn: this.handleOptInSubmit,
            onStateChange: this.handleStateChange,
            onChange: this.handlePaymentDetailsChange,
            onCustomPaymentDetailsSubmit: this.handleCustomPaymentDetailsSubmit,
        };

        if (this.state.isLoading) {
            if (this.state.error !== "") {
                return <Alert variant={"danger"} show={this.state.error != ""}>
                    {this.state.error}
                </Alert>
            }
            return <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" /></div>
        }

        return <PaymentsView {...selectionProps} />;
    }
}

export interface PaymentsViewProps extends IntegratedPaymentsViewProps, CustomPaymentsViewProps { }

export const PaymentsView: FunctionComponent<PaymentsViewProps> = (
    props
) => (
    <div>
        <Row>
            <Col>
                <h1 style={{ marginBottom: "2rem" }}>Payments</h1>
            </Col>
            <Col>
                <div style={{ float: "right" }}>
                    <BootstrapSwitchButton
                        checked={!props.paymentDetails.isOptOut}
                        onstyle={"success"}
                        offstyle={"secondary"}
                        width={150}
                        onlabel="Integrated"
                        offlabel="Custom"
                        onChange={(checked: boolean) => {
                            if (checked) {
                                return props.onOptIn()
                            }
                            return props.onOptOut()
                        }}
                    />
                </div>
            </Col>
        </Row>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Tabs
            defaultActiveKey={PaymentType.Custom}
            className="mb-3"
            variant="pills"
        >
            <Tab eventKey={PaymentType.Custom} title="Custom">
                <CustomPaymentsView {...props} />
            </Tab>
            <Tab eventKey={PaymentType.Integrated} title="Integrated">
                <IntegratedPaymentsView {...props} />
            </Tab>

        </Tabs>
    </div>
)

export interface CustomPaymentsViewProps {
    error: string
    state: State
    paymentDetails: PaymentDetails
    onOptOut: () => void
    onStateChange: (state: State) => void
    onChange: (test: PaymentDetails) => void
    onCustomPaymentDetailsSubmit: () => void
}

export const CustomPaymentsView: FunctionComponent<CustomPaymentsViewProps> = (
    props
) => (
    <Row>
        <Col sm={12}>
            <Row style={{ marginBottom: "2rem" }}>
                <Col>
                    <Card.Title>Custom Payments</Card.Title>
                    <Card.Text>If you would like to create your own custom payments journey you can add custom communications here.</Card.Text>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ margin: "auto" }}>
                    <hr />
                    <Row>
                        <Col xs={9} md={10} xl={11}>
                            <Row>
                                <Col xs={12} sm={3} xl={2} style={{ marginBottom: "1rem" }}>
                                    <Card.Text style={{ fontWeight: 600 }}>Payment Instructions</Card.Text>
                                </Col>
                                <Col xs={12} sm={9} xl={10}>
                                    {(props.state === State.EditPaymentInstructions) ? (
                                        <MDEditor
                                            style={{ background: Color.White, border: 0, boxShadow: "0" }}
                                            color={Color.White}
                                            value={props.paymentDetails.custom.instructions}
                                            onChange={value => props.onChange({
                                                ...props.paymentDetails,
                                                custom: {
                                                    ...props.paymentDetails.custom,
                                                    instructions: value || ""
                                                }
                                            })}
                                        />
                                    ) : (
                                        <>
                                            <Card.Text style={{ fontSize: ".9rem", marginBottom: ".5rem" }}><InfoCircle size={18} /> Payment instructions will be displayed to the customer on order confirmation communications and checkout</Card.Text>
                                            <div className="elevate" style={{ padding: "1rem", marginTop: "1rem" }}>
                                                <ReactMarkdown>{props.paymentDetails.custom.instructions}</ReactMarkdown>
                                            </div>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={3} md={2} xl={1}>
                            <Row>
                                <Col xs={12}>
                                    {(props.state === State.EditPaymentInstructions) ? (
                                        <>
                                            <Button style={{ width: "100%" }} variant={variant.Primary} name="Submit" onClick={() => props.onCustomPaymentDetailsSubmit()} />
                                            <Button style={{ width: "100%" }} variant={variant.Secondary} name="Cancel" onClick={() => props.onStateChange(State.None)} />
                                        </>
                                    ) : (
                                        <Button style={{ width: "100%" }} variant={variant.Primary} name="Edit" onClick={() => props.onStateChange(State.EditPaymentInstructions)} />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col >
    </Row >
)


export interface IntegratedPaymentsViewProps {
    error: string
    paymentDetails: PaymentDetails
    onOptOut: () => void
    onOptIn: () => void
    onStripeActive: () => void
}

export const IntegratedPaymentsView: FunctionComponent<IntegratedPaymentsViewProps> = (
    props
) => (
    <Row>
        <Col sm={12}>
            <Row style={{ marginBottom: "2rem" }}>
                <Col>
                    <Card.Title>Integrated Payments</Card.Title>
                    <Card.Text>Our goal is to simplify the payment process for you and your customers. To achieve this, we have integrated with Stipe Payments and are actively working to integrate with more.</Card.Text>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={4} xl={3}>
                    <Card>
                        <Card.Img variant="top" src={`${window.location.protocol}//${window.location.hostname}${(window.location.port !== "") ? `:${window.location.port}` : ""}/thirdparty/stripe.png`} />
                        <Card.Body>
                            {(props.paymentDetails.isOptOut) ? <Button variant={variant.Primary} style={{ width: "100%" }} onClick={props.onStripeActive} name={"Activate"} /> : <Button variant={variant.Secondary} style={{ width: "100%" }} onClick={props.onOptOut} name={"Deactivate"} />}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Col >
    </Row >
)