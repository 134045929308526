import { Auth } from "aws-amplify";
import { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, Col, Row } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { RouteComponentProps } from "react-router-dom";
import { IPutShopName } from "../../../client/core";
import { Color, Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";

export interface ShopNameState {
    name: string;
    error: string;
}

export interface ShopNameProps extends RouteComponentProps {
    coreAPI: IPutShopName;
    auth: typeof Auth;
}

export default class ShopName extends Component<ShopNameProps, ShopNameState> {
    constructor(props: ShopNameProps) {
        super(props);
        this.state = {
            name: "",
            error: "",
        };
    }

    handleChange = (name: string) => {
        this.setState({
            name: name,
        });
    };

    handleSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error:
                    "Shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }
        if (this.state.name.trim() === "") {
            return this.setState({
                error:
                    "Please enter the name of your shop you wish to trade under",
            });
        }
        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .PutShopName({
                    identityToken: token,
                    shopName: this.state.name,
                    shopID: shopID!,
                })
                .then((output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error:
                                "You were forbidden to change the shop name - please navigate back to the shop dashboard.",
                        });
                    }
                    return this.props.history.push(Path.RegisterShopContact);
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    render() {
        const props: ShopNameFormProps = {
            name: this.state.name,
            error: this.state.error,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
        };
        return <ShopNameRegistrationForm {...props} />;
    }
}

export interface ShopNameFormProps {
    name: string;
    error: string;
    onChange: (name: string) => void;
    onSubmit: () => void;
}

export const ShopNameRegistrationForm: FunctionComponent<ShopNameFormProps> = (props) => (
    <Form>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={7}>
                <Card>
                    <Card.Body>
                        <Form.Group style={{ "width": "100%" }}>
                            <h4 style={{ textAlign: "center" }}>Registration Form</h4>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col>
                                    <Form.Label>Shop Name</Form.Label>
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Control
                                        type="text"
                                        name={"name"}
                                        id={"name"}
                                        onChange={(e) => props.onChange(e.target.value)}
                                        placeholder="Enter shop name"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Button
                                        variant={variant.Primary}
                                        name={"Next"}
                                        onClick={() => props.onSubmit()}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Card.Body>
                </Card >
            </Col>
            <Col sm={5}>
                <Card>
                    <Card.Body>
                        <Card.Title><InfoCircle size={18} /> Info about shop name</Card.Title>
                        <Card.Text>
                            <p>The Shop Name will be how the customers identify your shop on the platform.</p>
                        </Card.Text>
                    </Card.Body>
                </Card >
            </Col>
        </Row>
    </Form >
);
