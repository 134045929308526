import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path } from "../../../env";
import { Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Button, variant } from "../../form/Button";

export interface ResetPasswordState {
    status: Status;
    fields: Map<Field, string>;
    error: string;
}

export interface ResetPasswordProps extends RouteComponentProps {
    auth: typeof Auth;
}

export enum Field {
    Username = "username",
    Code = "code",
    Password = "password",
}

export enum Status {
    ForgotPassword,
    PasswordConfirmation,
}

export default class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
    constructor(props: ResetPasswordProps) {
        super(props);
        this.state = {
            status: Status.ForgotPassword,
            fields: new Map([
                [Field.Username, ""],
            ]),
            error: "",
        };
    }

    handleNavigate = (path: Path) => {
        this.props.history.replace(path);
    };

    handleResetPassword = (): void => {
        if (this.isValidField(Field.Username, this.state.fields) == false) {
            const error = "Please provide your username";
            return this.setState({
                error: error,
            });
        }

        const username: string = this.state.fields.get(Field.Username)!.trim();

        this.props.auth.forgotPassword(username).then(() => {
            this.setState({
                status: Status.PasswordConfirmation,
            })
        }).catch(() => {
            this.setState({
                error: "Sorry, we could not reset your password right now"
            })
        })
    };

    handleConfirmPassword = (): void => {
        if (this.isValidField(Field.Code, this.state.fields) == false) {
            const error = "Please provide the verification code sent to you by email";
            return this.setState({
                error: error,
            });
        }

        if (this.isValidField(Field.Username, this.state.fields) == false) {
            const error = "Please enter your username";
            return this.setState({
                error: error,
            });
        }

        if (this.isValidField(Field.Password, this.state.fields) == false) {
            const error = "Please enter your new password";
            return this.setState({
                error: error,
            });
        }

        const code: string = this.state.fields.get(Field.Code)!.trim();
        const username: string = this.state.fields.get(Field.Username)!.trim();
        const password: string = this.state.fields.get(Field.Password)!.trim();

        this.props.auth.forgotPasswordSubmit(username, code, password).then(() => {
            this.props.history.replace(Path.Login)
        }).catch(() => {
            this.setState({
                error: "Sorry, we could not reset your password right now"
            })
        })
    };

    isValidField = (field: Field, fields: Map<string, string>): boolean => {
        return (
            typeof fields.get(field) !== "undefined" &&
            fields.get(field)!.trim() != ""
        );
    };

    handleChange = (field: Field, value: string): void => {
        const fields = this.state.fields;
        fields.set(field, value);
        this.setState({
            fields: fields,
        });
    };

    handleSubmit = (): void => {
        if (this.state.status == Status.ForgotPassword) {
            return this.handleResetPassword()
        }
        return this.handleConfirmPassword()
    }

    render() {
        const loginFormProps: ResetPasswordFormProps = {
            error: this.state.error,
            status: this.state.status,
            username: this.state.fields.get(Field.Username)!,
            code: this.state.fields.get(Field.Code)!,
            password: this.state.fields.get(Field.Password)!,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
        };
        return <ResetPasswordForm {...loginFormProps} />;
    }
}

export interface ResetPasswordFormProps {
    status: Status;
    error: string;
    username: string;
    code: string;
    password: string;
    onChange: (name: Field, value: string) => void;
    onSubmit: () => void;
    onNavigate: (path: Path) => void;
}

export const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = (props) => (
    <Container style={{padding: "1rem"}} fluid>
        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={5} xl={4}>
                <div style={{ textAlign: "center" }}>
                    <img src={`/logos/${process.env.REACT_APP_LOGO}`} width={150} height={150} />
                </div>
                <Alert variant="danger" show={props.error !== ""}>{props.error}</Alert>
                {props.status == Status.ForgotPassword &&
                    <>
                        <Card.Text style={{ fontSize: ".9rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}>Enter the username associated with your account, and we'll email you a verification code to be used to reset your password.</Card.Text>
                        <Form.Group controlId="formBasicUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                id={"username"}
                                type="text"
                                name={"username"}
                                onChange={(e) =>
                                    props.onChange(Field.Username, e.target.value)
                                }
                            />
                        </Form.Group>
                    </>
                }
                {props.status == Status.PasswordConfirmation &&
                    <>
                        <Card.Text style={{ fontSize: ".9rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}>We have sent you a verification code via Email. It might take a couple of minutes to arrive.</Card.Text>
                        <Form.Group style={{ marginTop: "1rem" }} controlId="verification-code">
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control
                                id={Field.Code}
                                type="text"
                                name={Field.Code}
                                onChange={(e) =>
                                    props.onChange(Field.Code, e.target.value)
                                }
                            />
                        </Form.Group>
                        <Form.Group style={{ marginTop: "1rem" }} controlId="new-password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                id={Field.Password}
                                type="password"
                                name={Field.Password}
                                onChange={(e) =>
                                    props.onChange(Field.Password, e.target.value)
                                }
                            />
                        </Form.Group>
                    </>
                }
                <Row style={{ marginTop: "1rem" }}>
                    <Col xs={12}>
                        <Button
                            variant={variant.Primary}
                            name={"Reset Password"}
                            style={{ width: "100%", marginTop: "1rem" }}
                            onClick={props.onSubmit}
                        />
                    </Col>
                    <Col xs={12} style={{ textAlign: "center", fontSize: ".9rem", marginTop: "1rem" }}>
                        <a
                            style={{
                                color: Color.Primary,
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                props.onNavigate(
                                    Path.Login
                                )
                            }
                        >
                            Back to Login
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
);
