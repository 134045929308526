import React, { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, Col, Row } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../env";
import { IUpdateDeliveryPrice } from "../../client/core";
import { Button, variant } from "../form/Button";

export interface DeliveryPriceState {
    action: Action;
    price: number;
    error: string;
}

export enum Action {
    Update,
    View,
}

export interface DeliveryPriceProps extends RouteComponentProps {
    price: number;
    auth: typeof Auth;
    updateDeliveryPriceAPI: IUpdateDeliveryPrice;
}

export default class DeliveryPrice extends Component<
    DeliveryPriceProps,
    DeliveryPriceState
> {
    constructor(props: DeliveryPriceProps) {
        super(props);
        this.state = {
            action: Action.View,
            price: 0,
            error: "",
        };
    }

    handleChange = (value: number) => {
        this.setState({
            price: value,
        });
    };

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    handleSubmit = async () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (!shopID) {
            return;
        }

        const token = await this.getToken();
        if (token instanceof Error) {
            this.props.auth.signOut();
            return;
        }

        const response =
            await this.props.updateDeliveryPriceAPI.UpdateDeliveryPrice({
                identityToken: token,
                price: this.state.price,
                shopID: shopID,
            });
        if (response instanceof Error) {
            return this.setState({
                error: "Unable to update delivery price.",
            });
        }
        if (response.statusCode === 403) {
            localStorage.removeItem(Storage.ShopID);
            return this.setState({
                error: "You were forbidden to update the delivery price - please navigate back to the shop dashboard.",
            });
        }
        if (response.statusCode !== 201) {
            return this.setState({
                error: "Unable to update delivery price",
            });
        }
        this.setState({
            action: Action.View,
        });
    };

    getToken = async (): Promise<string | Error> => {
        try {
            const session = await this.props.auth.currentSession();
            return session.getIdToken().getJwtToken();
        } catch (err) {
            return new Error("Failed to authenticate");
        }
    };

    changeAction = (action: Action) => {
        this.setState({
            action: action,
        });
    };

    render() {
        const deliverypriceViewProps: DeliveryPriceViewProps = {
            price: this.state.price == 0 ? this.props.price : this.state.price,
            error: this.state.error,
            changeAction: this.changeAction,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
        };
        return (
            <>
                {this.state.action === Action.Update && (
                    <UpdateDeliveryPriceForm {...deliverypriceViewProps} />
                )}
                {this.state.action === Action.View && (
                    <ViewDeliveryPrice {...deliverypriceViewProps} />
                )}
            </>
        );
    }
}

export interface DeliveryPriceViewProps {
    price: number;
    error: string;
    changeAction: (action: Action) => void;
    onChange?: (value: number) => void;
    onNavigate?: (path: Path) => void;
    onSubmit?: () => void;
}

export const UpdateDeliveryPriceForm: FunctionComponent<
    DeliveryPriceViewProps
> = (props) => (
    <Card style={{ height: "100%" }}>
        <Card.Body>
            <Card.Title
                style={{
                    paddingBottom: ".3rem",
                }}
            >
                Delivery Price
            </Card.Title>
            <Card.Subtitle
                style={{
                    paddingBottom: ".5rem",
                }}
            >
                Set a delivery price in <strong>pounds</strong>
            </Card.Subtitle>
            <Form>
                <Alert variant={"danger"} show={props.error != ""}>
                    {props.error}
                </Alert>
                <Row>
                    <Col xs={9}>
                        <Form.Group>
                            <Form.Control
                                id={"price"}
                                type={"number"}
                                step={".01"}
                                name={"price"}
                                placeholder="price"
                                onChange={(e) =>
                                    props.onChange!(Number(e.target.value))
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <span>in pounds</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col>
                        <Button
                            variant={variant.Secondary}
                            name={"Cancel"}
                            style={{ margin: "0.1rem" }}
                            onClick={() => props.changeAction(Action.View)}
                        />
                        <Button
                            id={"delivery-price-submit-btn"}
                            name={"Submit"}
                            variant={variant.Primary}
                            onClick={() => props.onSubmit!()}
                        />
                    </Col>
                </Row>
            </Form>
        </Card.Body>
    </Card>
);

export const ViewDeliveryPrice: FunctionComponent<DeliveryPriceViewProps> = (
    props
) => (
    <Card style={{ height: "100%" }}>
        <Card.Body>
            <Row>
                <Col xs={12} sm={10}>
                    <Card.Title
                        style={{
                            paddingBottom: ".3rem",
                        }}
                    >
                        Delivery Price
                    </Card.Title>
                    <Card.Subtitle
                        style={{
                            paddingBottom: ".5rem",
                        }}
                    >
                        Set a delivery price in <strong>pounds</strong>
                    </Card.Subtitle>
                    <Card.Text>Current price: £{props.price.toFixed(2)}</Card.Text>
                </Col>
                <Col xs={12} sm={2}>
                    <Button
                        id={"delivery-price-update-btn"}
                        name={"Edit"}
                        variant={variant.Primary}
                        style={{ float: "right" }}
                        onClick={() => props.changeAction(Action.Update)}
                    />
                </Col>
            </Row>
        </Card.Body>
    </Card>
);
