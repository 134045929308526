import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ConfirmShopCreationInput, ConfirmShopCreationOutput } from "../../client/core";
import { Card, Spinner } from "react-bootstrap";
import { Path } from "../../env";
import { Button, variant } from "../form/Button";

export interface ShopReferralConfirmationState {
    shopName: string
    shopEmail: string
    status: string
    isLoading: boolean
    error: boolean
}

export interface ShopReferralConfirmationProps extends RouteComponentProps {
    confirmShopCreation: (input: ConfirmShopCreationInput) => Promise<ConfirmShopCreationOutput>
}

export default class ShopReferralConfirmation extends Component<ShopReferralConfirmationProps, ShopReferralConfirmationState> {
    constructor(props: ShopReferralConfirmationProps) {
        super(props);
        this.state = {
            shopEmail: "",
            shopName: "",
            status: "",
            isLoading: true,
            error: false,
        };
    }

    componentDidMount(): void {
        const queryParams = new URLSearchParams(this.props.history.location.search)

        const shopName = queryParams.get("shopname") || ""
        const status = queryParams.get("status") || "REJECTED"
        const shopEmail = queryParams.get("shopemail") || ""

        this.setState({
            shopName: shopName,
            status: status,
            shopEmail: shopEmail,
        })

        this.props.confirmShopCreation({
            email: shopEmail,
            status: status,
        }).then(session => {
            if (session.statusCode !== 200) {
                return this.setState({
                    isLoading: false,
                    error: true,
                })
            }

            this.setState({
                isLoading: false,
            })
        })
    }

    handleContinue(): void {
        this.props.history.push(`${Path.RegisterMember}?email=${this.state.shopEmail}`)
    }

    render() {
        if (this.state.isLoading) {
            return <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" /></div>
        }

        const props: ShopReferralConfirmationViewProps = {
            shopEmail: this.state.shopEmail,
            shopName: this.state.shopName,
            status: this.state.status,
            onContinue: this.handleContinue,
        };

        if (this.state.error) {
            return <ErrorView />
        }

        if (this.state.status == "REJECTED") {
            return <ShopRejectedConfirmationView {...props} />
        }

        return <ShopAcceptedConfirmationView {...props} />;
    }
}

export interface ShopReferralConfirmationViewProps {
    shopName: string
    shopEmail: string
    status: string
    onContinue: () => void
}

export const ShopAcceptedConfirmationView: FunctionComponent<ShopReferralConfirmationViewProps> = (props) => (
    <Card>
        <Card.Body>
            <p>Thank you for joining us, {props.shopName}!</p>
            <p>We're thrilled you've confirmed to be part of our mission to bring more people to local shops just like yours.
                Your details are now in our system, and you're all set! There's nothing else you need to do unless you’re interested
                in exploring our online shop options, which you can activate at any time.</p>
            <p>Get ready to welcome some new faces — we’re so excited to help more customers discover your unique offerings.
                Here’s to more footfall and friendly faces in your shop!</p>
            <p>If you’d like to set up an online shop, simply register with the same email: {props.shopEmail}, add a few products, and you’re all set!</p>
            <Button onClick={() => props.onContinue()} variant={variant.Primary} name="Continue" />
        </Card.Body>
    </Card>
);

export const ShopRejectedConfirmationView: FunctionComponent<ShopReferralConfirmationViewProps> = (props) => (
    <Card>
        <Card.Body>
            <p>We understand!</p>
            <p>Thanks for considering us, {props.shopName}. We completely respect your decision and appreciate your time. If you ever change your mind, we’re here to help connect more people with amazing local shops like yours.</p>
            <p>If you’d like to chat or learn more about what we’re doing for local businesses, feel free to reach out anytime. Here’s to supporting our local community, whether it’s now or down the road.</p>
        </Card.Body>
    </Card>
);

export interface ErrorViewProps { }

export const ErrorView: FunctionComponent<ErrorViewProps> = (props) => (
    <Card>
        <Card.Body>
            <div>
                <p>We'll register your details shortly.</p>
                <p>Sorry, we were unable to register you details at this time. Don't worry, we've got it sorted and will confirm your details from our side - you don't need to do anything.</p>
                <p>If you’d like to set-up an online shop, simply register, add a few products, and you’re all set.</p>
            </div>
        </Card.Body>
    </Card>
);
