import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Form, Alert, Card, Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { Button, variant } from "../../form/Button";
import { AddressDetails, IAddAdditionalShop } from "../../../client/core";
import { Path, Storage } from "../../../env";

export interface AddAdditionalShopState {
    address: AddressDetails
    error: string;
}

export interface AddAdditionalShopProps extends RouteComponentProps {
    coreAPI: IAddAdditionalShop
    auth: typeof Auth;
}

export default class AddAdditionalShop extends Component<
    AddAdditionalShopProps,
    AddAdditionalShopState
> {
    constructor(props: AddAdditionalShopProps) {
        super(props);
        this.state = {
            address: {
                address: "",
                id: "",
            },
            error: "",
        };
    }

    handleSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return this.props.history.replace(Path.ShopDashboard)
        }

        this.props.auth.currentSession().then(session => {
            this.props.coreAPI.AddAdditionalShop({
                shopID: shopID,
                addressDetails: this.state.address,
                identityToken: session.getIdToken().getJwtToken(),
            }).then(async response => {
                if (response.statusCode === 403) {
                    localStorage.removeItem(Storage.ShopID)
                    await this.props.auth.signOut()
                    this.props.history.push(Path.Login)
                }

                if (response.statusCode !== 201) {
                    this.setState({
                        error: "We were unable to add a new shop at this time. Please, try again later and if this issue persists please contact: support@stumbled.online",
                    })
                }

                return this.props.history.replace(Path.ShopDashboard)

            })
        })
    };

    handleAddressChange = (address: AddressDetails): void => {
        this.setState({
            address: address,
        })
    }

    render() {
        const props: AddAdditionalShopFormProps = {
            error: this.state.error,
            googleApiKey: process.env.REACT_APP_CORE_GOOGLE_PLACES_KEY!,
            onSubmit: this.handleSubmit,
            onChange: this.handleAddressChange,
        };
        return <AddAdditionalShopForm {...props} />;
    }
}

export interface AddAdditionalShopFormProps {
    error: string;
    googleApiKey: string;
    onChange: (address: AddressDetails) => void;
    onSubmit: () => void;
}

export const AddAdditionalShopForm: FunctionComponent<AddAdditionalShopFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Card>
            <Card.Body>
                <Card.Title>Add another shop</Card.Title>
                <Card.Text>If you have another shop that located in a different geographical location you can add it here. This will enable your customers to discover and collect from their local shop.</Card.Text>
                <Form.Group style={{ "width": "100%" }}>
                    <Row>
                        <Col sm={12}>
                            <Form.Label>Shop Address</Form.Label>
                        </Col>
                        <Col style={{ marginBottom: "1rem" }} sm={12}>
                            <Autocomplete
                                apiKey={props.googleApiKey}
                                onPlaceSelected={(place) => props.onChange({
                                    id: place.place_id || "",
                                    address: place.formatted_address || "",
                                })}
                                style={{
                                    width: "100%",
                                    padding: ".375rem .75rem",
                                    borderRadius: ".25rem",
                                    border: "1px solid #ced4da",
                                }}
                                options={{
                                    types: ["address"],
                                }}
                            />
                        </Col>
                        <Col style={{ marginBottom: "1rem" }} sm={12}>
                            <Button
                                variant={variant.Primary}
                                name={"Add shop"}
                                onClick={() => props.onSubmit()} />
                        </Col>
                    </Row>
                </Form.Group>

            </Card.Body>
        </Card>
    </Form>
);
