import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import {
    Customer, IGetShopCustomers,
} from "../../client/core";
import { Alert, Spinner, Table } from "react-bootstrap";
import { Color, Path, Storage } from "../../env";

export interface CustomersState {
    customers: Customer[];
    error: string;
    isLoading: boolean;
}

export interface CustomersProps extends RouteComponentProps {
    auth: typeof Auth;
    getCustomersAPI: IGetShopCustomers;
}

export default class Customers extends Component<CustomersProps, CustomersState> {
    constructor(props: CustomersProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: "",
            customers: [],
        };
    }

    componentDidMount() {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return this.setState({
                isLoading: false,
                error: "Could not identify what shop is logged in. Please navigate back to the home dashboard to resync."
            })
        }

        this.props.auth.currentSession().then(session => {
            this.props.getCustomersAPI.GetShopCustomers({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID,
            }).then(response => {
                if (response instanceof Error) {
                    return
                }

                if (response.statusCode == 403) {
                    localStorage.removeItem(Storage.ShopID)
                    return this.setState({
                        error: "You are forbidden to access the shops customer data. Please navigate back to the shop dashboard to resync."
                    })
                }

                this.setState({
                    isLoading: false,
                    customers: response.response?.customers || [],
                })
            }).catch(() => {
                return this.setState({
                    isLoading: false,
                    error: "Unable to get customer data at this current time. Please try again later."
                })
            })
        }).catch(() => {
            this.props.auth.signOut();
            localStorage.removeItem(Storage.IsLoggedIn);
            localStorage.removeItem(Storage.ShopID);
            return this.props.history.push(Path.Login);
        })
    }

    render() {
        const customersViewProps: CustomersViewProps = {
            isLoading: this.state.isLoading,
            customers: this.state.customers,
            error: this.state.error,
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
        };
        return <ViewCustomers {...customersViewProps} />;
    }
}

export interface CustomersViewProps extends RouteComponentProps {
    isLoading: boolean
    customers: Customer[]
    error: string;
}

export const ViewCustomers: FunctionComponent<CustomersViewProps> = (props) => (
    <div>
        <h1 style={{ marginBottom: "2rem" }}>Customers</h1>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        {props.isLoading ? (
            <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" /></div>
        ) : (
            <div>
                <Table bordered hover>
                    <thead style={{ background: Color.Primary, color: Color.White }}>
                        <tr>
                            <th>Email</th>
                            <th>Username</th>
                            <th>Marketing Opt-in</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.customers.map(customer => (
                            <tr>
                                <td>{customer.customerEmail}</td>
                                <td>{(!customer.username || customer.username === "") ? "Guest" : customer.username}</td>
                                <td>{(customer.isOptInMarketing) ? "Yes" : "No"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )}
    </div>
);