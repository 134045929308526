import { Component, FunctionComponent } from "react";
import {
    Form,
    Alert,
    Card,
    Container,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../../env";
import { Button, Icon, icon, variant } from "../../form/Button";

export interface LoginState {
    fields: Map<formAttribute, string>;
    isLoading: boolean;
    error: string;
}

export interface LoginProps extends RouteComponentProps {
    auth: typeof Auth;
}

export enum formAttribute {
    username = "username",
    password = "password",
}

export default class Login extends Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            isLoading: false,
            fields: new Map([
                [formAttribute.username, ""],
                [formAttribute.password, ""],
            ]),
            error: "",
        };
    }

    handleChange = (name: formAttribute, value: string) => {
        const fields = this.state.fields;
        fields.set(name, value);
        this.setState({
            fields: fields,
        });
    };

    componentDidMount(): void {
        this.isLoggedIn().then(isLoggedIn => {
            if (isLoggedIn) {
                localStorage.setItem(Storage.IsLoggedIn, "true");
                this.props.history.push(Path.ShopDashboard);
            }
        })
    }

    isLoggedIn = async (): Promise<boolean> => {
        try {
            await Auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
    }

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, string>
    ): boolean => {
        return (
            typeof fields.get(field) !== "undefined" &&
            fields.get(field)!.trim() !== ""
        );
    };

    handleSubmit = () => {
        if (
            this.isValidField(formAttribute.password, this.state.fields) ===
            false
        ) {
            const error = "Please provide a password";
            return this.setState({
                error: error,
            });
        }

        if (
            this.isValidField(formAttribute.username, this.state.fields) ===
            false
        ) {
            const error = "Please provide your username";
            return this.setState({
                error: error,
            });
        }

        const password: string = this.state.fields
            .get(formAttribute.password)!
            .trim();
        const username: string = this.state.fields
            .get(formAttribute.username)!
            .trim();

        this.setState({
            isLoading: true,
        })

        this.props.auth
            .signIn(username, password)
            .then(() => {
                localStorage.setItem(Storage.IsLoggedIn, "true");
                this.props.history.push(Path.ShopDashboard);
            })
            .catch((err) => {
                this.setState({
                    error: err.message,
                });
            }).finally(() => {
                this.setState({
                    isLoading: false,
                })
            })
    };

    handleFederatedLogin = async (type: CognitoHostedUIIdentityProvider) => {
        this.props.auth.federatedSignIn({
            provider: type,
        })
    }

    render() {
        const credentialFormProps: LoginFormProps = {
            username: this.state.fields.get(formAttribute.username)!,
            password: this.state.fields.get(formAttribute.password)!,
            error: this.state.error,
            isLoading: this.state.isLoading,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
            onFederatedLogin: this.handleFederatedLogin,
        };
        return <LoginForm {...credentialFormProps} />;
    }
}

export interface LoginFormProps {
    username: string;
    password: string;
    error: string;
    isLoading: boolean;
    onChange: (name: formAttribute, value: string) => void;
    onNavigate: (path: Path) => void;
    onSubmit: () => void;
    onFederatedLogin: (type: CognitoHostedUIIdentityProvider) => void;
}

export const LoginForm: FunctionComponent<LoginFormProps> = (props) => (
    <Container style={{ padding: "1rem" }} fluid>
        <Row>
            <Col xs={12} sm={10} md={6} xl={5} xxl={4} style={{ margin: "auto" }}>
                <Card style={{padding: "2rem"}} className={"elevate"}>
                    <Card.Body>
                        <div style={{ textAlign: "center" }}>
                            <img src={`/logos/${process.env.REACT_APP_LOGO}`} width={150} height={150} style={{ display: "inline-block" }} />
                        </div>
                        <Form>
                            <Row>
                                <Col>
                                    <Alert
                                        variant={"danger"}
                                        show={props.error != ""}
                                    >
                                        {props.error}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col>
                                    <Form.Group style={{ marginTop: ".7rem" }} controlId="formBasicUsername">
                                        <Form.Label style={{ fontSize: ".9rem" }}>Enter your username</Form.Label>
                                        <Form.Control
                                            id={"username"}
                                            type="text"
                                            name={"username"}
                                            style={{ padding: ".5rem" }}
                                            onChange={(e) =>
                                                props.onChange(
                                                    formAttribute.username,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginTop: ".7rem" }} controlId="formBasicPassword">
                                        <Form.Label style={{ fontSize: ".9rem" }}>Enter your password</Form.Label>
                                        <Form.Label style={{ float: "right" }}><a
                                            style={{
                                                color: Color.Primary,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                props.onNavigate(
                                                    Path.ResetPassword
                                                )
                                            }
                                        >Forgot your password?</a></Form.Label>
                                        <Form.Control
                                            style={{ padding: ".5rem" }}
                                            id={"password"}
                                            type="password"
                                            name={"password"}
                                            onChange={(e) =>
                                                props.onChange(
                                                    formAttribute.password,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "2rem" }}>
                                <Col xs={12}>
                                    <Button style={{ width: "100%" }} id={"login-submit-btn"} disabled={props.isLoading} name="Log In" variant={variant.Primary} onClick={props.onSubmit} />
                                </Col>
                                <Col xs={12} style={{ textAlign: "center", fontSize: ".9rem", marginTop: "1rem" }}>
                                    <span >
                                        Don't have an account? <a
                                            style={{
                                                color: Color.Primary,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                props.onNavigate(
                                                    Path.RegisterMember
                                                )
                                            }
                                        >
                                            Sign up
                                        </a>
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                        <div style={{ width: "100%", height: "20px", borderBottom: `1px solid ${Color.Grey}`, textAlign: "center", marginTop: "2rem", marginBottom: "1rem" }}>
                            <span style={{ padding: "0 10px", backgroundColor: Color.White, fontSize: "1.2rem", color: Color.DarkGrey }}>
                                Or
                            </span>
                        </div>
                        <Row>
                            <Col xs={12}>
                                <button style={{ border: 0, background: "#436BC6", padding: ".4rem", color: Color.White, width: "100%", marginTop: "1rem" }} onClick={() => props.onFederatedLogin(CognitoHostedUIIdentityProvider.Facebook)}><Icon icon={icon.Facebook} size={30} /> Continue with Facebook</button>
                                <button style={{ border: 0, background: "#DE4A3A", padding: ".4rem", color: Color.White, width: "100%", marginTop: "1rem" }} onClick={() => props.onFederatedLogin(CognitoHostedUIIdentityProvider.Google)}><Icon icon={icon.Google} size={30} /> Continue with Google</button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container >
);