import { combineReducers } from "redux";
import { shopsReducer } from "./shops";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

export const rootReducer = combineReducers({
    shops: shopsReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootReducer = ReturnType<typeof persistedReducer>;