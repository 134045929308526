import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GetProductsInput, GetProductsOutput, IGetArchivedProducts, Product, UpdateProductLifecycleInput, UpdateProductLifecycleOutput } from "../../client/core";
import { Color, Storage } from "../../env";
import { Alert, Card, Col, Row, Spinner } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { string } from "@pact-foundation/pact/src/dsl/matchers";

export interface ArchivedProductsState {
    isLoading: boolean;
    error: string;
    products: Product[];
}

export interface ArchivedProductsProps extends RouteComponentProps {
    getArchivedProducts: (input: GetProductsInput) => Promise<GetProductsOutput>;
    UpdateProductLifecycle: (input: UpdateProductLifecycleInput) => Promise<UpdateProductLifecycleOutput>;
    auth: typeof Auth;
    onUnarchive?: (product: Product) => void;
}

export default class ArchivedProducts extends Component<
    ArchivedProductsProps,
    ArchivedProductsState
> {
    constructor(props: ArchivedProductsProps) {
        super(props);
        this.state = {
            isLoading: true,
            products: [],
            error: "",
        };
    }

    componentDidMount(): void {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.getArchivedProducts({
                identityToken: token,
                shopID: shopID!,
            })
                .then((output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to list archived products, please navigate back to the shop dashboard.",
                        });
                    }

                    if (output.statusCode !== 200) {
                        return this.setState({
                            error: "We could not retrieve your archived products at this time. If this problem persists please contact support@stumbled.online."
                        })
                    }

                    console.log(output)

                    this.setState({
                        products: output.products,
                    });
                })
                .catch((err) =>
                    this.setState({
                        error: "We were unable to retrieve the archived products at this time. If this problem persists please contact support@stumbled.online.",
                    })
                ).finally(() => {
                    this.setState({
                        isLoading: false,
                    })
                })
        }).catch(() => {
            return this.setState({
                isLoading: false,
                error: "You were forbidden to list shop products, please navigate back to the shop dashboard.",
            });
        })
    }

    handleUnarchive = (product: Product): void => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync.",
            });
        }

        if (!product.id) {
            return this.setState({
                error: "We were unable to identify this product."
            })
        }

        this.props.auth.currentSession().then(session => {
            this.props.UpdateProductLifecycle({
                identityToken: session.getIdToken().getJwtToken(),
                lifecycle: "Recover",
                productID: product.id!,
                shopID: shopID,
            }).then(response => {
                if (response.statusCode !== 204) {
                    return this.setState({
                        error: "We could not recover the product at this time. If this problem persist, please contact support@stumbled.online."
                    })
                }

                this.updateLocalProductLifecycle(product.id!, undefined)
            })
        })
    }

    updateLocalProductLifecycle = (productId: string, lifecycle: string | undefined): void => {
        const products = this.state.products

        const newProducts = products.map(product => {
            if (productId === product.id) {
                product.lifecycle = lifecycle
            }
            return product
        })

        this.setState({
            products: newProducts,
        })
    }

    render() {
        if (this.state.isLoading) {
            return <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" color={Color.Primary} /></div>
        }
        const props: ArchivedProductsViewProps = {
            error: this.state.error,
            products: this.state.products,
            onUnarchive: this.handleUnarchive,
        };
        return <ArchivedProductsView {...props} />;
    }
}

export interface ArchivedProductsViewProps {
    error: string;
    products: Product[];
    onUnarchive: (product: Product) => void;
}

export const ArchivedProductsView: FunctionComponent<ArchivedProductsViewProps> = (
    props
) => (
    <div>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        {(!props.products || props.products.length == 0) ? (
            <p>You currently have no archived products.</p>
        ) : (
            props.products.map((product) => (
                <Col
                    style={{ marginTop: "1rem" }}
                    key={product.id!}
                    xs={12}
                >
                    <Card style={{ border: 0, height: "100%" }}>
                        <Row>
                            <Col xs={2}>
                                {product.image && (
                                    <Card.Img
                                        style={{
                                            width: "100%",
                                            objectFit: "cover",
                                        }}
                                        src={product.image!}
                                    />
                                )}
                                {!product.image && (
                                    <Card.Img
                                        style={{
                                            width: "100%",
                                            objectFit: "cover",
                                        }}
                                        src={`${process.env.REACT_APP_DEFAULT_IMAGE}${product.name.charAt(0).toUpperCase()}`}
                                    />
                                )}
                            </Col>
                            <Col xs={8}>
                                <Card.Body style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                    <Card.Title>{product.name}</Card.Title>
                                    <Card.Text style={{ marginTop: ".8rem" }}>£{Number(product.price).toFixed(2)} {(!product.uom || product.uom === "Unit") ? "" : "per " + (((product.volume && product.volume > 1) ? `${product.volume} ` : "") + product.uom)}</Card.Text>
                                </Card.Body>
                            </Col>
                            <Col xs={2}>
                                {(product.lifecycle && product.lifecycle === "Archived") ? (
                                    <Button onClick={() => props.onUnarchive(product)} variant={variant.Primary} name="Recover" />
                                ) : (
                                    <span>Recovered</span>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            ))
        )}
    </div>
)