import { Component } from "react";
import { Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../env";


export interface ReferralState {
    error: string;
}

export interface ReferralProps extends RouteComponentProps { }

export default class Referral extends Component<
    ReferralProps,
    ReferralState
> {
    constructor(props: ReferralProps) {
        super(props)
    }

    componentDidMount(): void {
        const queryParams = new URLSearchParams(this.props.history.location.search)
        const referral = queryParams.get('referrer')
        if (referral) {
            localStorage.setItem(Storage.Referral, referral)
        }
        const openShop = queryParams.get('openShop')
        if (openShop) {
            localStorage.setItem(Storage.OpenShop, openShop)
        }
        this.props.history.replace(Path.ShopDashboard)
    }

    render() {
        return <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" color={Color.Primary} /></div>
    }
}