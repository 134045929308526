import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FormEvent } from "react";
import { Form, Alert } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { IAddCategory, Category, AddCategoryOutput } from "../../client/core";
import { Color, Storage } from "../../env";
import { Button, variant } from "../form/Button";

export interface AddCategoryState {
    fields: Map<formAttribute, any>;
    error: string;
}

export interface AddCategoryProps extends RouteComponentProps {
    coreAPI: IAddCategory;
    auth: typeof Auth;
    imageStore: typeof S3;
    postSubmit: (category: Category) => void;
}

export enum formAttribute {
    name = "name",
    image = "image"
}

export default class AddCategory extends Component<
    AddCategoryProps,
    AddCategoryState
> {
    constructor(props: AddCategoryProps) {
        super(props);
        this.state = {
            fields: new Map(),
            error: "",
        };
    }

    handleChange = (name: formAttribute, value: any) => {
        const fields = this.state.fields;
        fields.set(name, value);
        this.setState({
            fields: fields,
        });
    };

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, any>
    ): boolean => {
        return fields.has(field) && fields.get(field)!.trim() !== "";
    };

    handleSubmit = () => {
        if (!this.isValidField(formAttribute.name, this.state.fields)) {
            const error = "Category name must be filled in before submission";
            return this.setState({
                error: error,
            });
        }

        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .AddCategory({
                    identityToken: token,
                    shopID: shopID!,
                    category: {
                        name: this.state.fields.get(formAttribute.name)!,
                    },
                })
                .then(async (output: AddCategoryOutput) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to add a category - please navigate back to the shop dashboard.",
                        });
                    }

                    if (!this.state.fields.has(formAttribute.image)) {
                        return this.props.postSubmit(output.category!);
                    }

                    const imageName =
                        localStorage.getItem(Storage.ShopID) +
                        "/product-categories/" +
                        output.category?.id;

                    await this.props.imageStore.put(
                        imageName,
                        this.state.fields.get(formAttribute.image),
                        {
                            contentType: "image/jpeg",
                        }
                    );
                    this.props.postSubmit(output.category!);
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    render() {
        const props: AddCategoryFormProps = {
            name: this.state.fields.get(formAttribute.name),
            image: this.state.fields.get(formAttribute.image),
            error: this.state.error,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
        };
        return <AddCategoryForm {...props} />;
    }
}

export interface AddCategoryFormProps {
    name?: string;
    image?: File;
    error: string;
    onChange: (field: formAttribute, name: any) => void;
    onSubmit: () => void;
}

export interface AddCategoryFormState {
    ImageName: string;
}

export class AddCategoryForm extends Component<
    AddCategoryFormProps,
    AddCategoryFormState
> {
    constructor(props: AddCategoryFormProps) {
        super(props);
        this.state = {
            ImageName: "Select Image",
        };
    }

    render() {
        return (
            <Form>
                <Alert variant={"danger"} show={this.props.error != ""}>
                    {this.props.error}
                </Alert>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Image</span>
                    </div>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            accept="image/jpeg"
                            style={{
                                display: "block",
                            }}
                            onChange={(e) => {
                                this.props.onChange(
                                    formAttribute.image,
                                    e.target.files![0]
                                );
                                this.setState({
                                    ImageName: e.target.files![0].name,
                                });
                            }}
                        />
                        <label className="custom-file-label">
                            {this.state.ImageName}
                        </label>
                    </div>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Name</div>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        name={"name"}
                        id={"name"}
                        placeholder="Enter category name"
                        onChange={(e) =>
                            this.props.onChange(
                                formAttribute.name,
                                e.target.value
                            )
                        }
                    />
                </div>
                <Button
                    style={{float: "right"}}
                    id={"add-category-btn"}
                    name={"Submit"}
                    variant={variant.Primary}
                    onClick={() => this.props.onSubmit()} />
            </Form>
        );
    }
}