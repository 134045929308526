import React, { FunctionComponent, ReactNode } from "react";
import {
    Route,
    BrowserRouter as Router,
    Switch,
    RouteComponentProps,
} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Credentials from "../components/register/person/Credentials";
import { MemberRegisterConfirmation } from "../components/register/person/MemberRegisterConfirmation";
import { Color, Path } from "../env";
import { Storage as S3 } from "aws-amplify";
import RegisterShop from "../components/register/shop/RegisterShop";
import {
    RegisterShop as RegisterShopAPI,
    PutShopName,
    SetShopAddress as SetShopAddressAPI,
    GetProducts,
    GetDelivery,
    GetOrders,
    GetShopSummary,
    RegisterAccount as RegisterAccountAPI,
    UpdateOrderStatus,
    SetShopContactDetails as SetShopContactDetailsAPI,
    PaymentsOptOut,
    GetCategories,
    GetPaymentDetails,
    UpdateCustomPaymentDetails,
    ToggleProduct,
    GetAttachedShops,
    PaymentsOptIn,
    UpdateZettleIntegration,
    GetShopCustomers,
    DeliveryOptIn,
    DeliveryOptOut,
    AddAdditionalShop as AddAdditionalShopAPI,
    GetIntegrations,
    ToggleActiveShop,
    GetShopContactDetails,
    AssignUserToShopRequest,
    ReferShop as ReferShopAPI,
    ConfirmShopCreation
} from "../client/core";
import ShopName from "../components/register/shop/ShopName";
import Login from "../components/register/person/Login";
import SetShopAddress from "../components/register/shop/SetShopAddress";
import ListProducts from "../components/products/ListProducts";
import Navbar, { Sidebar } from "../components/menu/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import ShopDashboard from "../components/dashboard/ShopDashboard";
import Delivery from "../components/delivery/Delivery";
import ListOrders from "../components/orders/ListOrders";
import ShopContactDetails from "../components/register/shop/SetContactDetails";
import RegisterAccount from "../components/register/shop/RegisterAccount";
import { Footer } from "../components/menu/Footer";
import Integrations from "../components/integrations/Integrations";
import Account from "../components/payments/Account";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Zettle from "../components/integrations/Zettle";
import ResetPassword from "../components/register/person/ResetPassword";
import Customers from "../components/customers/Customers";
import AddAdditionalShop from "../components/register/shop/AddAdditionalShop";
import Referral from "../components/referral/Referral";
import ReferShop from "../components/referral/ReferShop";
import ShopReferralConfirmation from "../components/referral/ShopReferralConfirmation";


export const Routes: FunctionComponent<{}> = () => {
    const dispatch = useDispatch()
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={Path.Home}
                    render={(props) =>
                        Screen(
                            <ShopDashboard
                                {...props}
                                auth={Auth}
                                getShopAPI={new GetShopSummary()}
                                toggleActiveShop={new ToggleActiveShop()}
                                registerShopAPI={new RegisterAccountAPI()}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.ShopDashboard}
                    render={(props) =>
                        Screen(
                            <ShopDashboard
                                {...props}
                                auth={Auth}
                                getShopAPI={new GetShopSummary()}
                                toggleActiveShop={new ToggleActiveShop()}
                                registerShopAPI={new RegisterAccountAPI()}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterMember}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <Credentials {...props} auth={Auth} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.RegisterMemberConfirmation}
                    render={(props) =>
                        <MemberRegisterConfirmation />
                    }
                />
                <Route
                    exact
                    path={Path.RegisterShop}
                    render={(props) =>
                        Screen(
                            <RegisterShop
                                getShopContactAPI={new GetShopContactDetails()}
                                auth={Auth}
                                coreAPI={new RegisterShopAPI()}
                                assignUserToShopAPI={new AssignUserToShopRequest()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterShopName}
                    render={(props) =>
                        Screen(
                            <ShopName
                                auth={Auth}
                                coreAPI={new PutShopName()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterShopContact}
                    render={(props) =>
                        Screen(
                            <ShopContactDetails
                                auth={Auth}
                                coreAPI={new SetShopContactDetailsAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterShopAccount}
                    render={(props) =>
                        Screen(
                            <RegisterAccount
                                auth={Auth}
                                registerAccountAPI={new RegisterAccountAPI()}
                                paymentOptOutAPI={new PaymentsOptOut()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterShopAddress}
                    render={(props) =>
                        Screen(
                            <SetShopAddress
                                auth={Auth}
                                coreAPI={new SetShopAddressAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Products}
                    render={(props) =>
                        Screen(
                            <ListProducts
                                getCategoriesAPI={new GetCategories()}
                                toggleProductAPI={new ToggleProduct()}
                                auth={Auth}
                                coreAPI={new GetProducts()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Customers}
                    render={(props) =>
                        Screen(
                            <Customers
                                auth={Auth}
                                getCustomersAPI={new GetShopCustomers()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Delivery}
                    render={(props) =>
                        Screen(
                            <Delivery
                                auth={Auth}
                                getDeliveryAPI={new GetDelivery()}
                                optInDeliveryAPI={new DeliveryOptIn()}
                                optOutDeliveryAPI={new DeliveryOptOut()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.AddShop}
                    render={(props) =>
                        Screen(
                            <AddAdditionalShop
                                auth={Auth}
                                coreAPI={new AddAdditionalShopAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Orders}
                    render={(props) =>
                        Screen(
                            <ListOrders
                                auth={Auth}
                                coreAPI={new GetOrders()}
                                updateOrderStatusAPI={new UpdateOrderStatus()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Integrations}
                    render={(props) =>
                        Screen(
                            <Integrations
                                auth={Auth}
                                getIntegrationsApi={new GetIntegrations()}
                                registerStripeAccountApi={new RegisterAccountAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.IntegrationsZettle}
                    render={(props) => (
                        <Zettle
                            updateZettleIntegrationAPI={new UpdateZettleIntegration()}
                            auth={Auth}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={Path.Payments}
                    render={(props) =>
                        Screen(
                            <Account
                                auth={Auth}
                                registerAccountAPI={new RegisterAccountAPI()}
                                paymentOptOutAPI={new PaymentsOptOut()}
                                getPaymentDetailsAPI={new GetPaymentDetails()}
                                updateCustomPaymentDetailsAPI={new UpdateCustomPaymentDetails()}
                                paymentOptInAPI={new PaymentsOptIn()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Referral}
                    render={(props) =>
                        <Referral {...props} />
                    }
                />
                <Route
                    exact
                    path={Path.Login}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <Login auth={Auth} {...props} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.ResetPassword}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <ResetPassword auth={Auth} {...props} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.ReferShop}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <ReferShop googleApiKey={process.env.REACT_APP_CORE_GOOGLE_PLACES_KEY!} imageStore={S3} referShopAPI={new ReferShopAPI()} {...props} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.ConfirmShopCreation}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <ShopReferralConfirmation confirmShopCreation={new ConfirmShopCreation().ConfirmShopCreation} {...props} />
                        </div>
                    }
                />
            </Switch>
        </Router>
    );
}

function Screen(component: ReactNode, props: RouteComponentProps, dispatch: Dispatch) {
    return (
        <div>
            <Navbar shopsDispatch={dispatch} getAttachedShopsAPI={new GetAttachedShops()} auth={Auth} {...props} />
            <Container fluid style={{ maxWidth: "100%", height: "100vh" }}>
                <Row style={{ height: "100vh" }}>
                    <Col md={3} lg={2} className={"d-none d-md-block"} style={{ background: Color.LightGrey, padding: "4rem 0 0 0" }}>
                        <Row style={{ position: "fixed", width: "100%", margin: 0, padding: 0 }}>
                            <Col md={3} lg={2} style={{ padding: 0 }} className={"d-none d-md-block"}>
                                <Sidebar {...props} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={9} lg={10} style={{ background: Color.White, paddingTop: "4rem", paddingLeft: "4vw", paddingRight: "4vw", marginTop: "2rem", zIndex: 2 }}>
                        {component}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}