import axios, { AxiosRequestConfig } from "axios";

export interface PutShopNameInput {
    identityToken: string;
    shopID: string;
    shopName: string;
}

export interface PutShopNameOutput {
    statusCode: number;
}

export interface IPutShopName {
    PutShopName(input: PutShopNameInput): Promise<PutShopNameOutput>;
}

export class PutShopName implements IPutShopName {
    async PutShopName(input: PutShopNameInput): Promise<PutShopNameOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/name",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                name: input.shopName,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface RegisterShopInput {
    identityToken: string;
    referral?: string;
}

export interface RegisterShopOutput {
    statusCode: number;
    shopID: string;
}

export interface IRegisterShop {
    RegisterShop(input: RegisterShopInput): Promise<RegisterShopOutput>;
}

export class RegisterShop implements IRegisterShop {
    async RegisterShop(input: RegisterShopInput): Promise<RegisterShopOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/shop",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                referral: input.referral,
                entity: "shop"
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                shopID: response.data["id"],
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                    shopID: "",
                };
            }
            throw err
        }
    }
}

export interface AddressDetails {
    id: string;
    address: string;
}

export interface SetShopAddressInput {
    identityToken: string;
    shopID: string;
    addressDetails: AddressDetails;
}

export interface SetShopAddressOutput {
    statusCode: number;
}

export interface ISetShopAddress {
    SetShopAddress(input: SetShopAddressInput): Promise<SetShopAddressOutput>;
}

export class SetShopAddress implements ISetShopAddress {
    async SetShopAddress(
        input: SetShopAddressInput
    ): Promise<SetShopAddressOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/address",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                id: input.addressDetails.id,
                address: input.addressDetails.address,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface AddAdditionalShopInput {
    identityToken: string;
    shopID: string;
    addressDetails: AddressDetails;
}

export interface AddAdditionalShopOutput {
    statusCode: number;
}

export interface IAddAdditionalShop {
    AddAdditionalShop(input: AddAdditionalShopInput): Promise<AddAdditionalShopOutput>;
}

export class AddAdditionalShop implements IAddAdditionalShop {
    async AddAdditionalShop(
        input: AddAdditionalShopInput
    ): Promise<AddAdditionalShopOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                id: input.addressDetails.id,
                address: input.addressDetails.address,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface ContactDetails {
    email: string
    phone: string
    whatsapp?: string
}

export interface SetShopContactDetailsInput {
    identityToken: string;
    shopID: string;
    contactDetails: ContactDetails;
}

export interface SetShopContactDetailsOutput {
    statusCode: number;
}

export interface ISetShopContactDetails {
    SetShopContactDetails(input: SetShopContactDetailsInput): Promise<SetShopContactDetailsOutput>;
}

export class SetShopContactDetails implements ISetShopContactDetails {
    async SetShopContactDetails(
        input: SetShopContactDetailsInput
    ): Promise<SetShopContactDetailsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/contact",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                email: input.contactDetails.email,
                phone: input.contactDetails.phone,
                whatsapp: input.contactDetails.whatsapp,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface AddProductInput {
    identityToken: string;
    shopID: string;
    product: Product;
}

export interface Product {
    id?: string;
    name: string;
    price: number;
    volume?: number;
    uom?: string;
    image?: string;
    stock?: number;
    details?: ProductDetail[];
    category?: string;
    variants?: ProductVariant[];
    variantOverrides?: Map<string, ProductVariantOverride>;
    disabled: boolean;
    synchronized?: string;
    lifecycle?: string
}

export interface ProductVariantOverride {
    key: string
    price?: number
}

export interface ProductVariant {
    name: string
    options: string[]
}

export interface ProductDetail {
    name: string;
    description: string;
}

export interface AddProductOutput {
    statusCode: number;
    product?: Product;
}

export interface IAddProduct {
    AddProduct(input: AddProductInput): Promise<AddProductOutput>;
}

export class AddProduct implements IAddProduct {
    async AddProduct(input: AddProductInput): Promise<AddProductOutput> {
        console.log(input)
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: input.product,
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 201) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                product: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface SyncProductsInput {
    identityToken: string;
    shopID: string
    products: Product[]
}

export interface SyncProductsOutput {
    statusCode: number;
    products?: Product[];
}

export interface ISyncProducts {
    SyncProducts(input: SyncProductsInput): Promise<SyncProductsOutput>;
}

export class SyncProducts implements ISyncProducts {
    async SyncProducts(input: SyncProductsInput): Promise<SyncProductsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PATCH",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                products: input.products,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                products: response.data.products as Product[],
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetProductsInput {
    identityToken: string;
    shopID: string;
}

export interface GetProductsOutput {
    statusCode: number;
    products: Array<Product>;
}

export interface IGetProducts {
    GetProducts(input: GetProductsInput): Promise<GetProductsOutput>;
}

export class GetProducts implements IGetProducts {
    async GetProducts(input: GetProductsInput): Promise<GetProductsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                    products: [],
                };
            }
            return {
                statusCode: response.status,
                products: response.data.products,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                    products: [],
                };
            }
            throw err
        }
    }
}

export interface GetArchivedProductsInput {
    identityToken: string;
    shopID: string;
}

export interface GetArchivedProductsOutput {
    statusCode: number;
    products: Array<Product>;
}

export interface IGetArchivedProducts {
    GetArchivedProducts(input: GetProductsInput): Promise<GetProductsOutput>;
}

export class GetArchivedProducts implements IGetArchivedProducts {
    async GetArchivedProducts(input: GetProductsInput): Promise<GetProductsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/archived-products",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                    products: [],
                };
            }
            return {
                statusCode: response.status,
                products: response.data.products,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                    products: [],
                };
            }
            throw err
        }
    }
}

export interface AddCategoryInput {
    identityToken: string;
    shopID: string;
    category: Category;
}

export interface Category {
    id?: string;
    name: string;
}

export interface AddCategoryOutput {
    statusCode: number;
    category?: Category;
}

export interface IAddCategory {
    AddCategory(input: AddCategoryInput): Promise<AddCategoryOutput>;
}

export class AddCategory implements IAddCategory {
    async AddCategory(input: AddCategoryInput): Promise<AddCategoryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/product-categories",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                name: input.category.name,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 201) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                category: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetCategoriesOutput {
    statusCode: number;
    categories?: Category[];
}

export interface GetCategoriesInput {
    identityToken: string;
    shopID: string;
}

export interface IGetCategories {
    GetCategories(input: GetCategoriesInput): Promise<GetCategoriesOutput>;
}

export class GetCategories implements IGetCategories {
    async GetCategories(input: GetCategoriesInput): Promise<GetCategoriesOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/product-categories",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                categories: response.data.categories,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetShopSummaryInput {
    identityToken: string;
    shopID: string;
    addressID: string | null;
}

export interface GetShopSummaryOutput {
    statusCode: number;
    shopSummary?: ShopSummary;
}

export interface ShopSummary {
    name: string;
    alias: string;
    address: string;
    email: string;
    phone: string;
    accountStatus: string;
    deliveryStatus: string;
    credit: number;
    logo: string;
    color: string;
    active: boolean;
    actions: ShopAction[];
    whatsapp?: string;
}

export interface ShopAction {
    id: string
    dueDate: number
}

export interface IGetShopSummary {
    GetShopSummary(input: GetShopSummaryInput): Promise<GetShopSummaryOutput>;
}

export interface GetShopIDInput {
    identityToken: string;
}

export interface GetShopIDOutput {
    statusCode: number;
    shopID?: string;
}

export interface IGetShopID {
    GetShopID(input: GetShopIDInput): Promise<GetShopIDOutput>;
}

export interface IGetShop extends IGetShopID, IGetShopSummary { }

export class GetShopSummary implements IGetShopID, IGetShopSummary {
    async GetShopSummary(
        input: GetShopSummaryInput
    ): Promise<GetShopSummaryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        if (input.addressID) {
            requestOptions.params = {
                addressId: input.addressID
            }
        }
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                shopSummary: {
                    name: response.data.name,
                    address: response.data.address,
                    accountStatus: response.data.accountStatus,
                    deliveryStatus: response.data.deliveryStatus,
                    credit: response.data.credit,
                    logo: response.data.logo,
                    alias: response.data.alias,
                    color: response.data.color,
                    email: response.data.email,
                    phone: response.data.phone,
                    actions: response.data.actions,
                    whatsapp: response.data.whatsapp,
                    active: response.data.active,
                },
            };
        } catch (err: any) {
            throw err
        }
    }

    async GetShopID(input: GetShopIDInput): Promise<GetShopIDOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/me/shop",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            params: {
                "entity": "shop"
            },
        };
        try {
            const response = await axios(requestOptions);

            return {
                statusCode: response.status,
                shopID: response.data.id,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface ShopAbridged {
    id: string;
    addressId: string;
    name: string;
    address: string;
    logo: string;
}

export interface GetAttachedShopsInput {
    identityToken: string;
}

export interface GetAttachedShopsOutput {
    statusCode: number;
    shops?: ShopAbridged[];
}

export interface IGetAttachedShops {
    GetAttachedShops(input: GetAttachedShopsInput): Promise<GetAttachedShopsOutput>;
}

export class GetAttachedShops implements IGetAttachedShops {
    async GetAttachedShops(
        input: GetAttachedShopsInput
    ): Promise<GetAttachedShopsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me/shops",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            params: {
                "entity": "shop"
            }
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                shops: response.data.shops
            };
        } catch (err: any) {
            throw err
        }
    }
}

export interface GetProductInput {
    identityToken: string;
    shopID: string;
    productID: string;
}

export interface GetProductOutput {
    statusCode: number;
    product?: Product;
}

export interface IGetProduct {
    GetProduct(input: GetProductInput): Promise<GetProductOutput>;
}

export class GetProduct implements IGetProduct {
    async GetProduct(input: GetProductInput): Promise<GetProductOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products/" +
                input.productID,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                product: {
                    name: response.data.name,
                    price: Number(response.data.price),
                    category: response.data.category,
                    details: response.data.details,
                    id: response.data.id,
                    image: response.data.image,
                    stock: response.data.stock,
                    uom: response.data.uom,
                    variantOverrides: (response.data.variantOverrides) ? new Map(Object.entries(response.data.variantOverrides)) : undefined,
                    variants: response.data.variants,
                    volume: response.data.volume,
                    disabled: response.data.disabled,
                    synchronized: response.data.synchronized,
                },
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateProductInput {
    identityToken: string;
    shopID: string;
    product: Product;
}

export interface UpdateProductOutput {
    statusCode: number;
}

export interface IUpdateProduct {
    UpdateProduct(input: UpdateProductInput): Promise<UpdateProductOutput>;
}

export class UpdateProduct implements IUpdateProduct {
    async UpdateProduct(
        input: UpdateProductInput
    ): Promise<UpdateProductOutput> {
        let variantOverrides: Object | undefined
        if (input.product.variantOverrides) {
            variantOverrides = Object.fromEntries(input.product.variantOverrides)
        }
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products/" +
                input.product.id,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                id: input.product.id,
                name: input.product.name,
                price: Number(input.product.price),
                volume: Number(input.product.volume),
                uom: input.product.uom,
                image: input.product.image,
                details: input.product.details,
                category: input.product.category,
                variants: input.product.variants,
                variantOverrides: variantOverrides
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateProductLifecycleInput {
    identityToken: string;
    shopID: string;
    productID: string;
    lifecycle: string;
}

export interface UpdateProductLifecycleOutput {
    statusCode: number;
}

export interface IUpdateProductLifecycle {
    UpdateProductLifecycle(input: UpdateProductLifecycleInput): Promise<UpdateProductLifecycleOutput>;
}

export class UpdateProductLifecycle implements IUpdateProductLifecycle {
    async UpdateProductLifecycle(
        input: UpdateProductLifecycleInput
    ): Promise<UpdateProductLifecycleOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/products/${input.productID}/lifecycle/${input.lifecycle}`,
            headers: {
                Authorization: `Bearer ${input.identityToken}`,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface ToggleProductInput {
    identityToken: string;
    shopID: string;
    productID: string;
    enable: boolean;
}

export interface ToggleProductOutput {
    statusCode: number;
}

export interface IToggleProduct {
    ToggleProduct(input: ToggleProductInput): Promise<ToggleProductOutput>;
}

export class ToggleProduct implements IToggleProduct {
    async ToggleProduct(input: ToggleProductInput): Promise<ToggleProductOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/products/${input.productID}/${(input.enable) ? "enable" : "disable"}`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface DeleteProductsInput {
    identityToken: string;
    shopID: string;
    productIDs: string[];
}

export interface DeleteProductsOutput {
    statusCode: number;
}

export interface IDeleteProducts {
    DeleteProducts(input: DeleteProductsInput): Promise<DeleteProductsOutput>;
}

export class DeleteProducts implements IDeleteProducts {
    async DeleteProducts(
        input: DeleteProductsInput
    ): Promise<DeleteProductsOutput> {
        const size = 30;
        const arrayOfArrays = [];
        for (var i = 0; i < input.productIDs.length; i += size) {
            arrayOfArrays.push(input.productIDs.slice(i, i + size));
        }

        const commands = arrayOfArrays.map(ids => {
            const requestOptions: AxiosRequestConfig = {
                method: "DELETE",
                url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/products`,
                params: {
                    ids: ids.join(","),
                },
                headers: {
                    Authorization: "Bearer " + input.identityToken,
                },
            };
            return axios(requestOptions)
        })

        try {
            await Promise.all(commands)
            return {
                statusCode: 200
            }
        } catch (err: any) {
            throw err
        }
    }
}

export interface UpdateShopAliasInput {
    identityToken: string;
    shopID: string;
    alias: string;
}

export interface UpdateShopAliasOutput {
    statusCode: number;
}

export interface IUpdateShopAlias {
    UpdateShopAlias(
        input: UpdateShopAliasInput
    ): Promise<UpdateShopAliasOutput>;
}

export class UpdateShopAlias implements IUpdateShopAlias {
    async UpdateShopAlias(
        input: UpdateShopAliasInput
    ): Promise<UpdateShopAliasOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/alias",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                alias: input.alias,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}


export interface UpdateDeliveryRadiusInput {
    identityToken: string;
    shopID: string;
    radius: number;
}

export interface UpdateDeliveryRadiusOutput {
    statusCode: number;
}

export interface IUpdateDeliveryRadius {
    UpdateDeliveryRadius(
        input: UpdateDeliveryRadiusInput
    ): Promise<UpdateDeliveryRadiusOutput>;
}

export class UpdateDeliveryRadius implements IUpdateDeliveryRadius {
    async UpdateDeliveryRadius(
        input: UpdateDeliveryRadiusInput
    ): Promise<UpdateDeliveryRadiusOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/delivery/radius",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                radius: input.radius,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateDeliveryDateInput {
    identityToken: string;
    shopID: string;
    dates: DeliveryDate[];
}

export interface DeliveryDate {
    day: Day;
    timeranges: Timerange[];
    orderBefore?: OrderDate;
}

export interface OrderDate {
    day: Day;
    time: string;
}

export interface Timerange {
    start: string;
    end: string;
}

export interface UpdateDeliveryDateOutput {
    statusCode: number;
}

export type Day =
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";

export interface IUpdateDeliveryDate {
    UpdateDeliveryDate(
        input: UpdateDeliveryDateInput
    ): Promise<UpdateDeliveryDateOutput | Error>;
}

export class UpdateDeliveryDate implements IUpdateDeliveryDate {
    async UpdateDeliveryDate(
        input: UpdateDeliveryDateInput
    ): Promise<UpdateDeliveryDateOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/delivery/date",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                dates: input.dates,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateDeliveryPriceInput {
    identityToken: string;
    shopID: string;
    price: number;
}

export interface UpdateDeliveryPriceOutput {
    statusCode: number;
}

export interface IUpdateDeliveryPrice {
    UpdateDeliveryPrice(
        input: UpdateDeliveryPriceInput
    ): Promise<UpdateDeliveryPriceOutput | Error>;
}

export class UpdateDeliveryPrice implements IUpdateDeliveryPrice {
    async UpdateDeliveryPrice(
        input: UpdateDeliveryPriceInput
    ): Promise<UpdateDeliveryPriceOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/delivery/price",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                price: input.price,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateMinimumPriceInput {
    identityToken: string;
    shopID: string;
    price: number;
}

export interface UpdateMinimumPriceOutput {
    statusCode: number;
}

export interface IUpdateMinimumPrice {
    UpdateMinimumPrice(
        input: UpdateMinimumPriceInput
    ): Promise<UpdateMinimumPriceOutput | Error>;
}

export class UpdateMinimumPrice implements IUpdateMinimumPrice {
    async UpdateMinimumPrice(
        input: UpdateDeliveryPriceInput
    ): Promise<UpdateDeliveryPriceOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/delivery/minimum-price",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                price: input.price,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetDeliveryInput {
    identityToken: string;
    shopID: string;
}

export interface GetDeliveryOutput {
    statusCode: number;
    delivery?: Delivery;
}

export interface Delivery {
    radius: number;
    price: number;
    dates: DeliveryDate[];
    minimumPrice: number;
    // isIntegrated means a third party courier that is integrated in the app will be in charge of picking up and delivering the items.
    isIntegrated: boolean;
    isIntegratedAvailable: boolean;
}

export interface IGetDelivery {
    GetDelivery(input: GetDeliveryInput): Promise<GetDeliveryOutput>;
}

export class GetDelivery implements IGetDelivery {
    async GetDelivery(input: GetDeliveryInput): Promise<GetDeliveryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/delivery",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                delivery: {
                    radius: response.data.radius,
                    price: response.data.price,
                    dates: response.data.dates,
                    minimumPrice: response.data.minimumPrice,
                    isIntegrated: response.data.isIntegrated,
                    isIntegratedAvailable: response.data.isIntegratedAvailable,
                },
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export enum OrderType {
    OrderTypeDelivery = "delivery",
    OrderTypeCollection = "collection"
}

export interface Order {
    paymentId?: string;
    address?: string;
    paymentTotal?: number;
    status?: string;
    customerId?: string;
    customerEmail?: string;
    items?: OrderItem[];
    deliveryDate?: OrderDeliveryDate;
    type?: string;
    timestamp?: number;
    deliveryDetails?: string;
}

export interface OrderItem {
    id: string;
    name: string;
    price: number;
    uom?: string;
    volume?: number;
    variants?: OrderItemVariant[];
}

export interface OrderItemVariant {
    name: string
    value: string
}

export interface OrderDeliveryDate {
    day: Day;
    time: Timerange;
}

export interface IGetOrders {
    GetOrders(input: GetOrdersInput): Promise<GetOrdersOutput | Error>;
}

export interface GetOrdersInput {
    identityToken: string;
    shopID: string;
}

export interface GetOrdersOutput {
    statusCode: number;
    response?: GetOrdersResponse;
}

export interface GetOrdersResponse {
    orders: Order[];
}

export class GetOrders implements IGetOrders {
    async GetOrders(input: GetOrdersInput): Promise<GetOrdersOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/orders",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                response: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IUpdateOrderStatus {
    UpdateOrderStatus(input: UpdateOrderStatusInput): Promise<UpdateOrderStatusOutput | Error>
}

export interface UpdateOrderStatusInput {
    identityToken: string;
    shopID: string;
    paymentID: string;
    status: OrderStatus;
}

export enum OrderStatus {
    ToBeDelivered = "ToBeDelivered",
    ToBeCollected = "ToBeCollected",
    Delivered = "Delivered",
}

export interface UpdateOrderStatusOutput {
    statusCode: number;
}

export class UpdateOrderStatus implements IUpdateOrderStatus {
    async UpdateOrderStatus(input: UpdateOrderStatusInput): Promise<UpdateOrderStatusOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/orders/" +
                input.paymentID +
                "/status",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                status: input.status,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface RegisterAccountInput {
    identityToken: string;
    refreshURL: string;
    returnURL: string;
    shopID: string
}

export interface RegisterAccountOutput {
    statusCode: number;
    accountLink: string;
}

export interface IRegisterAccount {
    RegisterAccount(input: RegisterAccountInput): Promise<RegisterAccountOutput>;
}

export class RegisterAccount implements IRegisterAccount {
    async RegisterAccount(input: RegisterAccountInput): Promise<RegisterAccountOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/shop/" + input.shopID + "/account",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                refreshURL: input.refreshURL,
                returnURL: input.returnURL,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                accountLink: response.data["accountLink"],
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                    accountLink: "",
                };
            }
            throw err
        }
    }
}

export interface ReferShopInput {
    email: string;
    name: string;
    addressId: string;
    phone: string;
    whatsapp: string;
    instagram: string;
    facebook: string;
    logo: string;
}

export interface ReferShopOutput {
    statusCode: number;
}

export interface IReferShop {
    ReferShop(input: ReferShopInput): Promise<ReferShopOutput>;
}

export class ReferShop implements IReferShop {
    async ReferShop(input: ReferShopInput): Promise<ReferShopOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/registrations",
            data: {
                email: input.email,
                name: input.name,
                addressId: input.addressId,
                phone: input.phone,
                whatsapp: input.whatsapp,
                instagram: input.instagram,
                facebook: input.facebook,
                logo: input.logo,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface ConfirmShopCreationInput {
    email: string
    status: string
}

export interface ConfirmShopCreationOutput {
    statusCode: number;
}

export interface IConfirmShopCreation {
    ConfirmShopCreation(input: ConfirmShopCreationInput): Promise<ConfirmShopCreationOutput>;
}

export class ConfirmShopCreation implements IConfirmShopCreation {
    async ConfirmShopCreation(input: ConfirmShopCreationInput): Promise<ConfirmShopCreationOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/registrations/${input.email}/status/${input.status}`,
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface PaymentsOptOutInput {
    identityToken: string;
    shopID: string
}

export interface PaymentsOptOutOutput {
    statusCode: number;
}

export interface IPaymentsOptOut {
    PaymentsOptOut(input: PaymentsOptOutInput): Promise<PaymentsOptOutOutput>;
}

export class PaymentsOptOut implements IPaymentsOptOut {
    async PaymentsOptOut(input: PaymentsOptOutInput): Promise<PaymentsOptOutOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/shop/" + input.shopID + "/account/payments/opt-out",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface PaymentsOptInInput {
    identityToken: string;
    shopID: string
}

export interface PaymentsOptInOutput {
    statusCode: number;
}

export interface IPaymentsOptIn {
    PaymentsOptIn(input: PaymentsOptInInput): Promise<PaymentsOptInOutput>;
}

export class PaymentsOptIn implements IPaymentsOptIn {
    async PaymentsOptIn(input: PaymentsOptOutInput): Promise<PaymentsOptInOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/shop/" + input.shopID + "/account/payments/opt-in",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface CustomPaymentDetails {
    instructions: string
}

export interface IntegratedPaymentDetails {
    accountId: string
}

export interface UpdateCustomPaymentDetailsInput {
    identityToken: string;
    shopID: string;
    details: CustomPaymentDetails;
}

export interface UpdateCustomPaymentDetailsOutput {
    statusCode: number;
}

export interface IUpdateCustomPaymentDetails {
    UpdateCustomPaymentDetails(
        input: UpdateCustomPaymentDetailsInput
    ): Promise<UpdateMinimumPriceOutput | Error>;
}

export class UpdateCustomPaymentDetails implements IUpdateCustomPaymentDetails {
    async UpdateCustomPaymentDetails(
        input: UpdateCustomPaymentDetailsInput
    ): Promise<UpdateMinimumPriceOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/account/payments/custom`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: input.details,
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetPaymentDetailsInput {
    identityToken: string;
    shopID: string;
}

export interface GetPaymentDetailsOutput {
    statusCode: number;
    body?: PaymentDetails;
}

export interface PaymentDetails {
    isOptOut: boolean
    custom: CustomPaymentDetails
    integrated: IntegratedPaymentDetails
}

export interface IGetPaymentDetails {
    GetPaymentDetails(input: GetPaymentDetailsInput): Promise<GetPaymentDetailsOutput>;
}

export class GetPaymentDetails implements IGetPaymentDetails {
    async GetPaymentDetails(input: GetPaymentDetailsInput): Promise<GetPaymentDetailsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/account/payments`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            throw err
        }
    }
}

export interface UpdateZettleIntegrationInput {
    identityToken: string;
    shopID: string;
    code: string
}

export interface UpdateZettleIntegrationOutput {
    statusCode: number;
}

export interface IUpdateZettleIntegration {
    UpdateZettleIntegration(
        input: UpdateZettleIntegrationInput
    ): Promise<UpdateZettleIntegrationOutput | Error>;
}

export class UpdateZettleIntegration implements IUpdateZettleIntegration {
    async UpdateZettleIntegration(
        input: UpdateZettleIntegrationInput
    ): Promise<UpdateZettleIntegrationOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/integrations/zettle`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                code: input.code,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateShopBrandInput {
    identityToken: string;
    shopID: string;
    color: string
}

export interface UpdateShopBrandOutput {
    statusCode: number;
}

export interface IUpdateShopBrand {
    UpdateShopBrand(
        input: UpdateShopBrandInput
    ): Promise<UpdateShopBrandOutput | Error>;
}

export class UpdateShopBrand implements IUpdateShopBrand {
    async UpdateShopBrand(
        input: UpdateShopBrandInput
    ): Promise<UpdateShopBrandOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PATCH",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/brand`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                color: input.color,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface Customer {
    customerEmail: string
    username: string
    isOptInMarketing: boolean
}

export interface IGetShopCustomers {
    GetShopCustomers(
        input: GetShopCustomersInput
    ): Promise<GetShopCustomersOutput | Error>;
}

export interface GetShopCustomersInput {
    identityToken: string;
    shopID: string;
}

export interface GetShopCustomersOutput {
    statusCode: number;
    response?: GetShopCustomersResponse;
}

export interface GetShopCustomersResponse {
    customers: Customer[]
}

export class GetShopCustomers implements IGetShopCustomers {
    async GetShopCustomers(
        input: GetShopCustomersInput
    ): Promise<GetShopCustomersOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/customers",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                response: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface DeliveryOptInInput {
    identityToken: string;
    shopID: string
}

export interface DeliveryOptInOutput {
    statusCode: number;
}

export interface IDeliveryOptIn {
    DeliveryOptIn(input: DeliveryOptInInput): Promise<DeliveryOptInOutput>;
}

export class DeliveryOptIn implements IDeliveryOptIn {
    async DeliveryOptIn(input: DeliveryOptInInput): Promise<DeliveryOptInOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/delivery/opt-in`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface DeliveryOptOutInput {
    identityToken: string;
    shopID: string
}

export interface DeliveryOptOutOutput {
    statusCode: number;
}

export interface IDeliveryOptOut {
    DeliveryOptOut(input: DeliveryOptOutInput): Promise<DeliveryOptOutOutput>;
}

export class DeliveryOptOut implements IDeliveryOptOut {
    async DeliveryOptOut(input: DeliveryOptOutInput): Promise<DeliveryOptOutOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/delivery/opt-out`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IAmendOrder {
    AmendOrder(input: AmendOrderInput): Promise<UpdateOrderStatusOutput | Error>
}

export interface AmendOrderInput {
    identityToken: string;
    shopID: string;
    paymentID: string;
    order: Order;
}

export interface AmendOrderOutput {
    statusCode: number;
}

export class AmendOrder implements IAmendOrder {
    async AmendOrder(input: AmendOrderInput): Promise<AmendOrderOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/orders/" +
                input.paymentID,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                order: input.order
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IGetIntegrations {
    GetIntegrations(input: GetIntegrationsInput): Promise<GetIntegrationsOutput | Error>
}

export interface GetIntegrationsInput {
    identityToken: string;
    shopID: string;
}

export interface GetIntegrationsOutput {
    statusCode: number;
    body?: ShopIntegrations;
}

export interface ShopIntegrations {
    integrations: Integration[]
}

export interface Integration {
    name: string
    expiry?: number
}

export class GetIntegrations implements IGetIntegrations {
    async GetIntegrations(input: GetIntegrationsInput): Promise<GetIntegrationsOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/integrations`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface ToggleActiveShopInput {
    identityToken: string;
    shopID: string;
    enable: boolean;
}

export interface ToggleActiveShopOutput {
    statusCode: number;
}

export interface IToggleActiveShop {
    ToggleActiveShop(input: ToggleActiveShopInput): Promise<ToggleActiveShopOutput>;
}

export class ToggleActiveShop implements IToggleActiveShop {
    async ToggleActiveShop(input: ToggleActiveShopInput): Promise<ToggleActiveShopOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/${(input.enable) ? "enable" : "disable"}`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetShopContactDetailsOutput {
    statusCode: number;
    body?: ShopContactDetails
}

export interface GetShopContactDetailsInput {
    shopId: string
    address?: AddressDetails
}

export interface ShopContactDetails {
    id: string
    name: string
    address: string
    phone: string
    email: string
    logo?: string
}

export interface ShopContact {
    addressId: string
    address: string
}

export interface FormElement {
    type: string
    name: string
}

export interface IGetShopContactDetails {
    GetShopContactDetails(input: GetShopContactDetailsInput): Promise<GetShopContactDetailsOutput | Error>
}

export class GetShopContactDetails implements IGetShopContactDetails {
    async GetShopContactDetails(input: GetShopContactDetailsInput): Promise<GetShopContactDetailsOutput | Error> {
        let url = `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopId}/contact`
        if (input.address && input.address.id !== "") {
            url += `?addressId=${input.address.id}`
        }
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: url
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            return err
        }
    }
}

export interface AssignUserToShopRequestInput {
    identityToken: string
    shopId: string
}

export interface AssignUserToShopRequestOutput {
    statusCode: number
}

export interface IAssignUserShopRequest {
    AssignUserToShopRequest(input: AssignUserToShopRequestInput): Promise<AssignUserToShopRequestOutput | Error>
}

export class AssignUserToShopRequest implements IAssignUserShopRequest {
    async AssignUserToShopRequest(input: AssignUserToShopRequestInput): Promise<AssignUserToShopRequestOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopId}/assign-user-request`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            return err
        }
    }
}