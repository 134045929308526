import { Auth } from "aws-amplify";
import { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, Row, Col } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { RouteComponentProps } from "react-router-dom";
import { ContactDetails, ISetShopContactDetails } from "../../../client/core";
import { Color, Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";

export interface SetShopContactDetailsState {
    error: string;
    contactDetails: ContactDetails;
}

export interface SetShopContactDetailsProps extends RouteComponentProps {
    coreAPI: ISetShopContactDetails;
    auth: typeof Auth;
}

export default class ShopContactDetails extends Component<
    SetShopContactDetailsProps,
    SetShopContactDetailsState
> {
    constructor(props: SetShopContactDetailsProps) {
        super(props);
        this.state = {
            error: "",
            contactDetails: {
                email: "",
                phone: "",
            },
        };
    }

    handleSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Your shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }

        if (!this.state.contactDetails.email || this.state.contactDetails.email.trim() === "") {
            return this.setState({
                error: "Please enter your customer support email address",
            });
        }

        if (!this.state.contactDetails.phone || this.state.contactDetails.phone.trim() === "") {
            return this.setState({
                error: "Please enter your customer support phone number",
            });
        }
        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .SetShopContactDetails({
                    identityToken: token,
                    shopID: shopID,
                    contactDetails: {
                        email: this.state.contactDetails.email,
                        phone: this.state.contactDetails.phone,
                    },
                })
                .then((output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        this.props.auth.signOut();
                        return this.props.history.push(Path.Login)
                    }
                    if (output.statusCode == 400) {
                        return this.setState({
                            error: "Please double check the contact details entered. It appears at least one of the fields is invalid."
                        })
                    }
                    return this.props.history.push(Path.RegisterShopAddress);
                })
                .catch(() =>
                    this.setState({
                        error: "We were unable to set your contact details at this time.",
                    })
                );
        }).catch(() => {
            localStorage.removeItem(Storage.ShopID);
            this.props.auth.signOut();
            return this.props.history.push(Path.Login)
        })
    };

    handlePhoneChange = (value: string): void => {
        this.setState({
            contactDetails: {
                ...this.state.contactDetails,
                phone: value
            }
        })
    }

    handleEmailChange = (value: string): void => {
        this.setState({
            contactDetails: {
                ...this.state.contactDetails,
                email: value
            }
        })
    }

    render() {
        const props: SetShopContactDetailsFormProps = {
            error: this.state.error,
            email: this.state.contactDetails.email,
            phone: this.state.contactDetails.phone,
            onSubmit: this.handleSubmit,
            onEmailChange: this.handleEmailChange,
            onPhoneChange: this.handlePhoneChange,
        };
        return <SetShopContactDetailsForm {...props} />;
    }
}

export interface SetShopContactDetailsFormProps {
    error: string;
    email: string;
    phone: string;
    onSubmit: () => void;
    onPhoneChange: (value: string) => void
    onEmailChange: (value: string) => void
}

export const SetShopContactDetailsForm: FunctionComponent<SetShopContactDetailsFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={7}>
                <Card>
                    <Card.Body>
                        <Form.Group style={{ "width": "100%" }}>
                            <h4 style={{ textAlign: "center" }}>Registration Form</h4>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Customer support email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" onChange={(e: any) => props.onEmailChange(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPhone">
                                        <Form.Label>Customer support phone number</Form.Label>
                                        <Form.Control type="text" placeholder="Enter phone number" onChange={(e: any) => props.onPhoneChange(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Button
                                    id={"set-contact-details-btn"}
                                        variant={variant.Primary}
                                        name={"Next"}
                                        onClick={() => props.onSubmit()}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={5}>
                <Card>
                    <Card.Body>
                        <Card.Title><InfoCircle size={18} />Info about contact details</Card.Title>
                        <Card.Text>
                            <p>These details will be available to customers and potential customers through the platform. If your customers have any queries they will be able to contact you through the contact details provided here.</p>
                        </Card.Text>
                    </Card.Body>
                </Card >
            </Col>
        </Row>
    </Form>
);
