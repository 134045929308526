import { ShopsAction, ShopsState, UPDATE_SHOPS } from "../types/shops";

const INITIAL_STATE: ShopsState = {
    shops: []
};

export const shopsReducer = (state = INITIAL_STATE, action: ShopsAction): ShopsState => {
    switch (action.type) {
        case UPDATE_SHOPS:
            return {
                shops: action.payload
            }
        default:
            return state;
    }
};
