import { Component, FunctionComponent } from "react";
import { Storage as S3 } from "aws-amplify";
import { v4 as uuid } from 'uuid';
import Autocomplete from "react-google-autocomplete";
import { Form, Alert, Card, Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Button, variant } from "../form/Button";
import { AddressDetails, IReferShop } from "../../client/core";

export interface ReferShopState {
    email: string;
    name: string;
    addressId: string;
    phone: string;
    whatsapp: string;
    instagram: string;
    facebook: string;
    logo?: File;
    logoName: string;
    error: string;
    success: string;
}

export interface ReferShopProps extends RouteComponentProps {
    googleApiKey: string;
    imageStore: typeof S3;
    referShopAPI: IReferShop
}

export default class ReferShop extends Component<ReferShopProps, ReferShopState> {
    constructor(props: ReferShopProps) {
        super(props);
        this.state = {
            email: "",
            name: "",
            addressId: "",
            facebook: "",
            instagram: "",
            phone: "",
            whatsapp: "",
            logoName: "",
            error: "",
            success: "",
        };
    }

    handleSubmit = async () => {
        let logo = ""
        if (this.state.logo) {
            const imageName = `referral/logos/${uuid()}`
            const imageResponse = await this.props.imageStore.put(
                imageName,
                this.state.logo,
                {
                    contentType: "image/jpeg",
                }
            );
            logo = imageResponse.key
        }

        this.props.referShopAPI.ReferShop({
            email: this.state.email,
            facebook: this.state.facebook,
            instagram: this.state.instagram,
            logo: logo,
            addressId: this.state.addressId,
            name: this.state.name,
            phone: this.state.phone,
            whatsapp: this.state.whatsapp,
        }).then(response => {
            if (response.statusCode != 204) {
                return this.setState({
                    error: `Failed to add shop details because of status ${response.statusCode}`
                })
            }
            this.setState({
                success: "Shop details added to the list"
            })
        })
    }

    handleAddressChange = (place: google.maps.places.PlaceResult) => {
        const addressDetails: AddressDetails = {
            id: place.place_id!,
            address: place.formatted_address!,
        };
        this.setState({
            addressId: addressDetails.id,
        });
    };

    render() {
        const props: ReferShopFormProps = {
            logoName: this.state.logoName,
            error: this.state.error,
            success: this.state.success,
            googleApiKey: this.props.googleApiKey,
            onAddressChange: this.handleAddressChange,
            onEmailChange: email => { this.setState({ email: email }) },
            onNameChange: name => { this.setState({ name: name }) },
            onPhoneChange: phone => { this.setState({ phone: phone }) },
            onFacebookChange: facebook => { this.setState({ facebook: facebook }) },
            onInstagramChange: instagram => { this.setState({ instagram: instagram }) },
            onWhatsappChange: whatsapp => { this.setState({ whatsapp: whatsapp }) },
            onLogoChange: (name, logo) => { this.setState({ logo: logo, logoName: name }) },
            onSubmit: this.handleSubmit
        };
        return <ReferShopRegistrationForm {...props} />;
    }
}

export interface ReferShopFormProps {
    logoName: string;
    error: string;
    success: string;
    googleApiKey: string;
    onEmailChange: (email: string) => void;
    onNameChange: (name: string) => void;
    onPhoneChange: (phone: string) => void;
    onFacebookChange: (facebook: string) => void;
    onInstagramChange: (instagram: string) => void;
    onWhatsappChange: (whatsapp: string) => void;
    onAddressChange: (address: google.maps.places.PlaceResult) => void;
    onLogoChange: (name: string, logo: File) => void;
    onSubmit: () => void;
}

export const ReferShopRegistrationForm: FunctionComponent<ReferShopFormProps> = (props) => (
    <Form>

        <Row>
            <Col xs={12} sm={8} lg={6} style={{ margin: "auto", padding: "1rem" }}>
                <Card>

                    <Card.Body>
                        <Form.Group style={{ "width": "100%" }}>
                            <h4 style={{ textAlign: "center" }}>Enter Shop Details</h4>
                            <Alert variant={"danger"} show={props.error !== ""}>
                                {props.error}
                            </Alert>
                            <Alert variant={"success"} show={props.success !== ""}>
                                {props.success}
                            </Alert>
                            <Row style={{ marginTop: "1rem" }}>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Logo</span>
                                    </div>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="logo"
                                            accept="image/jpeg"
                                            style={{
                                                display: "block",
                                            }}
                                            onChange={(e) => props.onLogoChange(e.target.files![0].name, e.target.files![0])}
                                        />
                                        <label className="custom-file-label">
                                            {props.logoName}
                                        </label>
                                    </div>
                                </div>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name={"email"}
                                        id={"email"}
                                        onChange={(e) => props.onEmailChange(e.target.value)}
                                        placeholder="Enter shop email"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"name"}
                                        id={"name"}
                                        onChange={(e) => props.onNameChange(e.target.value)}
                                        placeholder="Enter shop name"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"phone"}
                                        id={"phone"}
                                        onChange={(e) => props.onPhoneChange(e.target.value)}
                                        placeholder="Enter shop name"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Label>Whatsapp</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"whatsapp"}
                                        id={"whatsapp"}
                                        onChange={(e) => props.onWhatsappChange(e.target.value)}
                                        placeholder="Enter shop Whatsapp number"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"instagram"}
                                        id={"instagram"}
                                        onChange={(e) => props.onInstagramChange(e.target.value)}
                                        placeholder="Enter shop Instagram"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} xs={12}>
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"facebook"}
                                        id={"facebook"}
                                        onChange={(e) => props.onInstagramChange(e.target.value)}
                                        placeholder="Enter shop Facebook"
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} sm={12}>
                                    <Form.Label>Shop Address</Form.Label>
                                    <Autocomplete
                                        apiKey={props.googleApiKey}
                                        onPlaceSelected={(place) => props.onAddressChange(place)}
                                        style={{
                                            width: "100%",
                                            padding: ".375rem .75rem",
                                            borderRadius: ".25rem",
                                            border: "1px solid #ced4da",
                                        }}
                                        options={{
                                            types: ["address"],
                                        }}
                                    />
                                </Col>

                                <Col style={{ marginBottom: "1rem", textAlign: "right" }} xs={12}>
                                    <Button
                                        variant={variant.Primary}
                                        name={"Submit"}
                                        onClick={() => props.onSubmit()}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Card.Body>
                </Card >
            </Col>
        </Row>
    </Form >
);
