import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { BiTime } from "react-icons/bi";
import { Color, Path, Storage } from "../../env";
import { FaRegCheckCircle, FaSync } from "react-icons/fa";
import { HiOutlineIdentification } from "react-icons/hi";
import { MdOutlineErrorOutline } from "react-icons/md";
import { IUpdateZettleIntegration } from "../../client/core";

export interface ZettleState {
    isLoading: boolean
    error: string
}

export interface ZettleProps extends RouteComponentProps {
    auth: typeof Auth;
    updateZettleIntegrationAPI: IUpdateZettleIntegration
}

export default class Zettle extends Component<ZettleProps, ZettleState> {
    constructor(props: ZettleProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: ""
        };
    }

    componentDidMount(): void {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return this.setState({
                error: "Failed to identify which shop to synchronize products with Zettle. Please navigate back to the Dashboard and then try again."
            })
        }

        const code = new URLSearchParams(this.props.location.search).get("code")
        if (!code) {
            return this.setState({
                error: "Failed to integrate because Zettle did not return an authentication code. Please try again and if this continues to fail please contact: support@ecomni.co.uk"
            })
        }

        this.props.auth.currentSession().then(sess => {
            this.props.updateZettleIntegrationAPI.UpdateZettleIntegration({
                identityToken: sess.getIdToken().getJwtToken(),
                code: code,
                shopID: shopID,
            }).then(response => {
                if (response instanceof Error || response.statusCode !== 200) {
                    return this.setState({
                        error: "Failed to integrate with Zettle. Please try again and if this problem persists please contact: support@ecomni.co.uk",
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false,
                })
            })
        }).catch(() => {
            this.props.auth.signOut()
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.history.replace(Path.Login)
        })
    }

    handleSubmit = (): void => {
        this.props.history.replace(Path.ShopDashboard)
    }

    render() {
        const viewZettleProps: ZettleViewProps = {
            ...this.props,
            error: this.state.error,
            onSubmit: this.handleSubmit,
        };

        if (this.state.isLoading) {
            return <div style={{ textAlign: "center", marginTop: "5rem" }}><Spinner variant="success" animation="border" /></div>
        }

        if (this.state.error !== "") {
            return <ZettleFailure {...viewZettleProps} />
        }

        return <ZettleSuccess {...viewZettleProps} />;
    }
}



export interface ZettleViewProps extends RouteComponentProps {
    error: string
    onSubmit: () => void
}

export const ZettleFailure: FunctionComponent<ZettleViewProps> = (props) => (
    <Container fluid>
        <Row>
            <Col xs={12} md={6} lg={5} style={{ margin: "auto", marginTop: "5rem" }}>
                <Card>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center", marginBottom: "2rem", color: Color.Red, fontWeight: 700, fontSize: "1.5rem" }}>Integration Failure</Card.Title>
                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={12} style={{ textAlign: "center" }}>
                                <MdOutlineErrorOutline style={{ margin: "auto" }} size={100} color={Color.Red} />
                            </Col>
                        </Row>
                        <Card.Text>{props.error}</Card.Text>
                        <Button variant={variant.Primary} name={"Back to dashboard"} style={{ width: "100%" }} onClick={() => props.onSubmit()} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container >
)

export const ZettleSuccess: FunctionComponent<ZettleViewProps> = (props) => (
    <Container fluid>
        <Row>
            <Col xs={12} md={6} lg={5} style={{ margin: "auto", marginTop: "5rem" }}>
                <Card>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center", marginBottom: "2rem", color: Color.Primary, fontWeight: 700, fontSize: "1.5rem" }}>Integration Successful</Card.Title>
                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={12} style={{ textAlign: "center" }}>
                                <FaRegCheckCircle style={{ margin: "auto" }} size={100} color={Color.Primary} />
                            </Col>
                        </Row>
                        <Card.Text>Great news! Your integration with Zettle has been successfully completed.
                            From now on, whenever you make updates to your product information in Zettle, those
                            changes will automatically sync with Stumbled.</Card.Text>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={2} style={{ textAlign: "center" }}>
                                <HiOutlineIdentification size={60} color={Color.Primary} />
                            </Col>
                            <Col>
                                <Card.Text style={{color: Color.Primary, fontWeight: 700}}>Session active for 180 days. After the 180 day period, you will need to repeat the Zettle sign-in.</Card.Text>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={2} style={{ textAlign: "center" }}>
                                <BiTime size={60} color={Color.Primary} />
                            </Col>
                            <Col>
                                <Card.Text>This synchronization is automated and occurs every hour, ensuring that your data
                                    is always up to date.</Card.Text>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Col xs={2} style={{ textAlign: "center" }}>
                                <FaSync size={50} color={Color.Primary} />
                            </Col>
                            <Col>
                                <Card.Text>Each product is identified by its name. If a product name is not already present in Stumbled,
                                    it will be created automatically. However, if a product name already exists, any updated data will
                                    overwrite the existing values, ensuring that the most recent information is always reflected in Stumbled.</Card.Text>
                            </Col>
                        </Row>
                        <Button variant={variant.Primary} name={"Continue"} style={{ width: "100%" }} onClick={() => props.onSubmit()} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container >
)