import { Auth } from "aws-amplify";
import Papa, { ParseResult } from "papaparse";
import { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { RouteComponentProps } from "react-router-dom";
import { ISyncProducts, Product, ProductDetail } from "../../client/core";
import { Storage } from "../../env";
import { Button, icon, variant } from "../form/Button";

export interface ImportFromCSVState {
    fields: Map<formAttribute, string | Duo[]>;
    error: string;
    data: Map<string, any>[];
    keys: string[];
}

export interface Duo {
    key: string
    value: string
}

export interface ImportFromCSVProps extends RouteComponentProps {
    coreAPI: ISyncProducts;
    auth: typeof Auth;
    csvParser: typeof Papa
    postSubmit: (products: Product[]) => void;
}

export enum formAttribute {
    name = "name",
    price = "price",
    uom = "uom",
    volume = "volume",
    description = "description",
    category = "category",
}

export default class ImportFromCSV extends Component<
    ImportFromCSVProps,
    ImportFromCSVState
> {
    constructor(props: ImportFromCSVProps) {
        super(props);
        this.state = {
            fields: new Map(),
            error: "",
            data: [],
            keys: []
        };
    }

    handleChange = (name: formAttribute, value: string | Duo, index = 0) => {
        const fields = this.state.fields;

        if (typeof value === "string") {
            fields.set(name, value as string);
            return this.setState({
                fields: fields,
            });
        }

        const description: Duo[] = fields.get(name) as Duo[] || []
        description[index] = value as Duo
        fields.set(name, description)
        this.setState({
            fields: fields,
        })
    };

    handleAddDetail = () => {
        const fields: Map<formAttribute, string | Duo[]> = this.state.fields
        const description: Duo[] = fields.get(formAttribute.description) as Duo[] || []
        description.push({
            key: "",
            value: ""
        })
        fields.set(formAttribute.description, description)
        this.setState({
            fields: fields
        })
    }

    handleCSVChange = (file: any): void => {
        this.props.csvParser.parse(file, {
            encoding: "ISO-8859-1",
            complete: results => {
                const output = processCSVData(results)
                this.setState({
                    data: output.data,
                    keys: output.keys
                })
            }
        })
    }

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, string | Duo[]>
    ): boolean => {
        if (!fields.has(field)) {
            return false
        }

        const f: string | Duo[] = fields.get(field)!

        if (Array.isArray(f)) {
            return true
        }

        return f.trim() !== "" && this.state.keys.includes(f.trim());
    };

    handleSubmit = () => {
        if (this.state.data.length == 0) {
            const error = "Please select your CSV file";
            return this.setState({
                error: error,
            });
        }

        if (!this.isValidField(formAttribute.name, this.state.fields)) {
            const error = "Product name must contain the column name that contains the product name in your CSV file";
            return this.setState({
                error: error,
            });
        }

        if (!this.isValidField(formAttribute.price, this.state.fields)) {
            const error = "Product price must contain the column name that contains the product price in your CSV file";
            return this.setState({
                error: error,
            });
        }

        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }

        const products: Product[] = this.state.data.map(d => {
            const description: Duo[] = this.state.fields.get(formAttribute.description)! as Duo[] || []
            const productDescription: ProductDetail[] = description.map(desc => {
                if (!d.has(desc.value) || d.get(desc.value).trim() === "") {
                    return null
                }
                const productDetail: ProductDetail = {
                    name: desc.key,
                    description: d.get(desc.value).trim(),
                }
                return productDetail
            }).filter(desc => desc != null) as ProductDetail[]

            const p: Product = {
                name: d.get(this.state.fields.get(formAttribute.name)! as string),
                price: Number(d.get(this.state.fields.get(formAttribute.price) as string)),
                volume: Number(d.get(this.state.fields.get(formAttribute.volume) as string || "erroneous")),
                uom: d.get(this.state.fields.get(formAttribute.uom) as string || "erroneous"),
                category: d.get(this.state.fields.get(formAttribute.category) as string || "erroneous"),
                details: productDescription,
                disabled: false,
            }
            return p
        })

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .SyncProducts({
                    identityToken: token,
                    shopID: shopID!,
                    products: products,
                })
                .then(async (output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to import the CSV. Please navigate back to the shop dashboard.",
                        });
                    }

                    if (output.statusCode !== 200) {
                        return this.setState({
                            error: "Could not update the products at this time. Please try again later."
                        });
                    }

                    this.props.postSubmit(output.products!)
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    render() {
        const props: ImportFromCSVFormProps = {
            name: this.state.fields.get(formAttribute.name) as string,
            price: this.state.fields.get(formAttribute.price) as string,
            volume: this.state.fields.get(formAttribute.volume) as string,
            uom: this.state.fields.get(formAttribute.uom) as string,
            category: this.state.fields.get(formAttribute.category) as string,
            description: this.state.fields.get(formAttribute.description) as Duo[],
            error: this.state.error,
            dataKeys: this.state.keys,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onCSVChange: this.handleCSVChange,
            onAddDetail: this.handleAddDetail,
        };
        return <ImportFromCSVForm {...props} />;
    }
}

export interface processCSVDataOutput {
    data: Map<string, any>[]
    keys: string[]
}

export function processCSVData<T>(results: ParseResult<T>): processCSVDataOutput {
    if (results.data.length == 0) {
        return {
            data: [],
            keys: []
        };
    }
    const attributes = results.data[0] as unknown as []
    const data = results.data.slice(1, results.data.length)
    const result = data.map(d => {
        const object = new Map<string, any>()
        const values = d as unknown as []
        values.forEach((value, key) => {
            object.set(attributes[key], value)
        })
        return object
    })
    return {
        data: result,
        keys: attributes,
    }
}

export interface ImportFromCSVFormProps {
    name?: string;
    price?: string;
    volume?: string;
    uom?: string;
    category?: string;
    description?: Duo[];
    error: string;
    dataKeys: string[];
    onChange: (field: formAttribute, name: string | Duo, index?: number) => void;
    onCSVChange: (value: any) => void;
    onSubmit: () => void;
    onAddDetail: () => void;
}

export interface ImportFromCSVFormState {
    FileName: string;
}

export class ImportFromCSVForm extends Component<
    ImportFromCSVFormProps,
    ImportFromCSVFormState
> {
    constructor(props: ImportFromCSVFormProps) {
        super(props);
        this.state = {
            FileName: "Select CSV",
        };
    }

    render() {
        return (
            <Form>
                <Alert variant={"danger"} show={this.props.error != ""}>
                    {this.props.error}
                </Alert>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">File</span>
                    </div>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="csv-file"
                            style={{
                                display: "block",
                            }}
                            accept=".csv"
                            onChange={(e) => {
                                this.props.onCSVChange(
                                    e.target.files![0]
                                );
                                this.setState({
                                    FileName: e.target.files![0].name,
                                });
                            }}
                        />
                        <label className="custom-file-label">
                            {this.state.FileName}
                        </label>
                    </div>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Name</div>
                    </div>
                    <Typeahead
                        labelKey="name"
                        style={{ flex: "1 1 auto" }}
                        id={"name"}
                        placeholder="Enter CSV column name for product name"
                        onInputChange={value => {
                            this.props.onChange(formAttribute.name, value)
                        }}
                        onChange={value => {
                            this.props.onChange(formAttribute.name, value[0] as string)
                        }}
                        options={this.props.dataKeys}
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Price</div>
                    </div>
                    <Typeahead
                        labelKey="price"
                        style={{ flex: "1 1 auto" }}
                        id={"price"}
                        placeholder="Enter CSV column name for product price"
                        onInputChange={value => {
                            this.props.onChange(formAttribute.price, value)
                        }}
                        onChange={value => {
                            this.props.onChange(formAttribute.price, value[0] as string)
                        }}
                        options={this.props.dataKeys}
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Units</div>
                    </div>
                    <Typeahead
                        labelKey="volume"
                        style={{ flex: "1 1 auto" }}
                        id={"volume"}
                        placeholder="Enter CSV column name for product volume"
                        onInputChange={value => {
                            this.props.onChange(formAttribute.volume, value)
                        }}
                        onChange={value => {
                            this.props.onChange(formAttribute.volume, value[0] as string)
                        }}
                        options={this.props.dataKeys}
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">UOM</div>
                    </div>
                    <Typeahead
                        labelKey="uom"
                        style={{ flex: "1 1 auto" }}
                        id={"uom"}
                        placeholder="Enter CSV column name for product UOM"
                        onInputChange={value => {
                            this.props.onChange(formAttribute.uom, value)
                        }}
                        onChange={value => {
                            this.props.onChange(formAttribute.uom, value[0] as string)
                        }}
                        options={this.props.dataKeys}
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Category</div>
                    </div>
                    <Typeahead
                        labelKey="category"
                        style={{ flex: "1 1 auto" }}
                        id={"category"}
                        placeholder="Enter CSV column name for product Category"
                        onInputChange={value => {
                            this.props.onChange(formAttribute.category, value)
                        }}
                        onChange={value => {
                            this.props.onChange(formAttribute.category, value[0] as string)
                        }}
                        options={this.props.dataKeys}
                    />
                </div>
                <div className="input-group mb-3" style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "5px" }}>
                    <div className="input-group-prepend" style={{ width: "15%" }}>
                        <div className="input-group-text" style={{ border: 0 }}>Details</div>
                    </div>
                    <div style={{ width: "85%" }}>
                        <Row>
                            {(this.props.description) && this.props.description.map((detail, index) => (
                                <Col xs={10}>
                                    <Row>
                                        <ProductDetailForm value={detail} dataKeys={this.props.dataKeys} onChange={(value) => this.props.onChange(formAttribute.description, value, index)} />
                                    </Row>
                                </Col>
                            ))}
                            {(!this.props.description) && <Col xs={10} />}
                            <Col xs={2} style={{ textAlign: "right" }}>
                                <Button variant={variant.Primary} icon={icon.Plus} onClick={() => this.props.onAddDetail()} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <Button style={{ float: "right" }} id="import-from-csv-submit-btn" variant={variant.Primary} name={"Submit"} onClick={() => this.props.onSubmit()} />
            </Form>
        );
    }
}

export interface DetailFormProps {
    dataKeys: string[]
    value: Duo
    onChange: (value: Duo) => void;
}

export const ProductDetailForm: FunctionComponent<DetailFormProps> = (props) => (
    <>
        <Col style={{ margin: 0, padding: 0 }} xs={4}>
            <input
                style={{ border: 0 }}
                type="text"
                className="form-control"
                name={"detail-name"}
                id={"detail-name"}
                placeholder="Enter title"
                value={props.value.key}
                onChange={(e) =>
                    props.onChange!(
                        {
                            key: e.target.value,
                            value: props.value.value
                        }
                    )
                }
            />
        </Col>
        <Col style={{ margin: 0, padding: 0 }} xs={8}>
            <Typeahead
                labelKey="category"
                style={{ flex: "1 1 auto", border: 0 }}
                id={"category"}
                placeholder="Enter CSV column name"
                onInputChange={value => {
                    props.onChange({
                        key: props.value.key,
                        value: value as string,
                    })
                }}
                onChange={value => {
                    props.onChange({
                        key: props.value.key,
                        value: value[0] as string,
                    })
                }}
                options={props.dataKeys}
            />
        </Col>
    </>
)