import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FormEvent } from "react";
import { Form, Alert, Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Category, IAddProduct, Product } from "../../client/core";
import { Color, Storage } from "../../env";
import CurrencyInput from "react-currency-input-field";
import { Button, icon, variant } from "../form/Button";
import { ProductAttribute, ProductDetailForm, ProductVariantForm } from "./Product";
import { Typeahead } from "react-bootstrap-typeahead";

export interface AddProductState {
    product: Product;
    productImage?: File;
    error: string;
}

export interface AddProductProps extends RouteComponentProps {
    coreAPI: IAddProduct;
    auth: typeof Auth;
    imageStore: typeof S3;
    categories: Category[];
    postSubmit: (product: Product) => void;
    onImageUpdate: (productID: string, image: File) => void;
}

export default class AddProduct extends Component<
    AddProductProps,
    AddProductState
> {
    constructor(props: AddProductProps) {
        super(props);
        this.state = {
            product: {
                name: "",
                price: 0.0,
                disabled: false,
            },
            error: "",
        };
    }

    handleChange = (attribute: ProductAttribute, value: any, index = 0) => {
        let product = this.state.product;
        switch (attribute) {
            case ProductAttribute.Name:
                product.name = value;
                break;
            case ProductAttribute.Price:
                product.price = Number(value);
                break;
            case ProductAttribute.UOM:
                product.uom = value;
                break;
            case ProductAttribute.Volume:
                product.volume = Number(value);
                break;
            case ProductAttribute.Category:
                product.category = value;
                break;
            case ProductAttribute.DetailName:
                product.details![index].name = value
                break;
            case ProductAttribute.DetailDescription:
                product.details![index].description = value
                break;
        }
        this.setState({
            product: product,
        });
    };

    handleAddDetail = () => {
        let product = this.state.product;
        if (!product.details) {
            product.details = []
        }
        product.details?.push({
            name: "",
            description: "",
        })
        this.setState({
            product: product,
        })
    }

    handleImageChange = (image: File) => {
        this.setState({
            productImage: image,
        });
    };

    handleSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }

        if (this.state.product.name.trim() === "") {
            return this.setState({
                error: "Product name must be filled in before submission",
            });
        }

        if (this.state.product.price <= 0) {
            return this.setState({
                error: "Product price must be set in before submission",
            });
        }

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .AddProduct({
                    identityToken: token,
                    shopID: shopID!,
                    product: this.state.product,
                })
                .then(async (output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to add a product. Please navigate back to the shop dashboard.",
                        });
                    }

                    if (output.statusCode !== 201) {
                        return this.setState({
                            error: "Sorry we were unable to add a new product right now. If this problem persists please contact support@ecomni.co.uk",
                        });
                    }

                    if (!this.state.productImage) {
                        return this.props.postSubmit(output.product!);
                    }

                    const imageName =
                        localStorage.getItem(Storage.ShopID) +
                        "/products/" +
                        output.product?.id;

                    await this.props.imageStore.put(
                        imageName,
                        this.state.productImage,
                        {
                            contentType: "image/jpeg",
                        }
                    );

                    this.props.postSubmit(output.product!);
                    this.props.onImageUpdate(
                        output.product?.id!,
                        this.state.productImage
                    );
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    render() {
        const props: AddProductFormProps = {
            product: this.state.product,
            categories: this.props.categories,
            error: this.state.error,
            onChange: this.handleChange,
            addImage: this.handleImageChange,
            onProductDetailAdd: this.handleAddDetail,
            onSubmit: this.handleSubmit,
        };
        return <AddProductForm {...props} />;
    }
}

export interface AddProductFormProps {
    product: Product;
    categories: Category[];
    error: string;
    onChange: (attribute: ProductAttribute, value: any, index?: number) => void;
    addImage: (image: File) => void;
    onProductDetailAdd: () => void;
    onSubmit: () => void;
}

export interface AddProductFormState {
    ImageName: string;
    IsCustomUnits: boolean;
}

export class AddProductForm extends Component<
    AddProductFormProps,
    AddProductFormState
> {
    constructor(props: AddProductFormProps) {
        super(props);
        this.state = {
            ImageName: "Select Image",
            IsCustomUnits: false,
        };
    }

    render() {
        return (
            <Form>
                <Alert variant={"danger"} show={this.props.error != ""}>
                    {this.props.error}
                </Alert>
                <Row>
                    <h5>Image</h5>
                    <Col>
                        <div className="input-group mb-3">
                            <div className="custom-file" style={{ width: "100%" }}>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="inputGroupFile"
                                    accept="image/jpeg"
                                    style={{
                                        display: "block",
                                        width: "100%"
                                    }}
                                    onChange={(e) => {
                                        this.props.addImage!(e.target.files![0]);
                                        this.setState({
                                            ImageName: e.target.files![0].name,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h5>Summary</h5>
                    <Col xs={12} md={6}>
                        <div className="form-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                name={"name"}
                                id={"name"}
                                placeholder="Name"
                                onChange={(e) =>
                                    this.props.onChange(
                                        ProductAttribute.Name,
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="input-group mb-3">
                            <Typeahead
                                labelKey="category"
                                style={{ flex: "1 1 auto", border: 0 }}
                                id={"category"}
                                placeholder="Category"
                                onInputChange={value =>
                                    this.props.onChange!(
                                        ProductAttribute.Category,
                                        value as string,
                                    )
                                }
                                onChange={value =>
                                    this.props.onChange!(
                                        ProductAttribute.Category,
                                        value[0] as string,
                                    )
                                }
                                options={(this.props.categories) ? this.props.categories.map(category => category.name) : []}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                name={"price"}
                                id={"price"}
                                placeholder="Price"
                                onChange={(e) =>
                                    this.props.onChange(
                                        ProductAttribute.Price,
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    </Col>
                    {(this.state.IsCustomUnits) && (
                        <>
                            <Col xs={12} md={3}>
                                <div className="input-group mb-3">
                                    <input
                                        className={"form-control"}
                                        name={"volume"}
                                        id={"volume"}
                                        onChange={(e) =>
                                            this.props.onChange(
                                                ProductAttribute.Volume,
                                                e.target.value
                                            )
                                        }
                                        placeholder="Quantity"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="input-group mb-3">
                                    <input
                                        className={"form-control"}
                                        name={"uom"}
                                        id={"uom"}
                                        onChange={(e) =>
                                            this.props.onChange(
                                                ProductAttribute.UOM,
                                                e.target.value
                                            )
                                        }
                                        placeholder="Units"
                                    />
                                </div>
                            </Col>
                        </>
                    )}
                    <Col xs={12}>
                        <div className="input-group mb-3">
                            <Form.Check
                                inline
                                label="Set price using custom units"
                                name="is-custom-units"
                                type={"checkbox"}
                                id={"is-custom-units"}
                                onChange={(e) => {
                                    this.setState({
                                        IsCustomUnits: e.target.checked,
                                    })
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h5>Details</h5>
                    <div className="input-group mb-3">
                        <Row>
                            {(this.props.product.details) && this.props.product.details.map((detail, index) => (
                                <ProductDetailForm name={detail.name} description={detail.description} onChange={((attr: ProductAttribute, value: any) => this.props.onChange!(attr, value, index))} />
                            ))}
                            <Col>
                                <Button variant={variant.Primary} icon={icon.Plus} onClick={() => this.props.onProductDetailAdd!()} />
                            </Col>
                        </Row>
                    </div>
                </Row>
                <Button id={"add-product-submit-btn"} style={{ float: "right" }} variant={variant.Primary} name={"Submit"} onClick={() => this.props.onSubmit()} />
            </Form>
        );
    }
}