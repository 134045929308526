import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Container, Nav, Navbar as BNavbar, Dropdown, NavItem, NavLink, Row, Col } from "react-bootstrap";
import { FaBox, FaBoxes, FaCreditCard, FaHome, FaLocationArrow, FaPlug, FaPlus, FaStore } from "react-icons/fa";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../env";
import { IGetAttachedShops, ShopAbridged } from "../../client/core";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ShopsAction, UPDATE_SHOPS } from "../../state/types/shops";
import { RootState } from "../../state/store";
import { MdAccountCircle, MdLogout, MdPeople } from "react-icons/md";

export interface NavbarState {
    isMobile: boolean;
    isLoggedIn: boolean;
    activeShop: ShopAbridged;
}

export type ShopsDispatch = Dispatch<ShopsAction>;

export interface NavbarProps extends RouteComponentProps {
    auth: typeof Auth;
    getAttachedShopsAPI: IGetAttachedShops;
    shopsDispatch: ShopsDispatch;
    shops: ShopAbridged[];
}

export class Navbar extends Component<NavbarProps, NavbarState> {
    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            activeShop: {
                name: "",
                address: "",
                id: "",
                logo: "",
                addressId: "",
            },
            isMobile: false,
            isLoggedIn: localStorage.getItem(Storage.IsLoggedIn) != null,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.getShops();
    }

    isGlobal = (): boolean => {
        if (this.props.history.location.pathname == Path.ShopDashboard ||
            this.props.history.location.pathname == Path.Home) {
            return false
        }
        return true
    }

    getShops = (): void => {
        if (!this.state.isLoggedIn) {
            return
        }
        this.props.auth.currentSession().then(session => {
            this.props.getAttachedShopsAPI.GetAttachedShops({
                identityToken: session.getIdToken().getJwtToken(),
            }).then(response => {
                if (response.statusCode !== 200) {
                    return this.handleLogout()
                }

                if (!response.shops || response.shops.length < 1) {
                    return
                }

                this.props.shopsDispatch({
                    type: UPDATE_SHOPS,
                    payload: response.shops
                })

                let shopID = localStorage.getItem(Storage.ShopID)
                const firstShop: ShopAbridged = response.shops[0]

                if (!shopID) {
                    localStorage.setItem(Storage.ShopID, firstShop.id)
                    shopID = firstShop.id
                }

                let activeShop = response.shops.find(shop => shop.id == shopID && shop.addressId == (localStorage.getItem(Storage.ShopAddressID) || ""))
                if (!activeShop) {
                    localStorage.setItem(Storage.ShopID, firstShop.id)
                    activeShop = firstShop
                }

                this.setState({
                    activeShop: activeShop,
                })
            })
        }).catch(() => {
            this.handleLogout()
        })
    }

    handleAddShop = () => {
        this.props.history.push(Path.AddShop)
    }

    handleChangeShop = (shopID: string, addressID: string) => {
        localStorage.setItem(Storage.ShopID, shopID)
        localStorage.setItem(Storage.ShopAddressID, addressID)
        window.location.reload()
    }

    resize() {
        this.setState({
            isMobile: window.innerWidth <= 760
        });
    }

    componentDidUpdate() {
        const isLoggedIn = localStorage.getItem(Storage.IsLoggedIn) != null;
        if (this.state.isLoggedIn == isLoggedIn) {
            return;
        }
        this.setState({
            isLoggedIn: isLoggedIn,
        });
    }

    handleSelect = (path: Path) => {
        this.props.history.push(path);
    };

    handleLogout = () => {
        this.props.auth.signOut();
        localStorage.removeItem(Storage.IsLoggedIn);
        return this.props.history.push(Path.Login);
    };

    render() {
        const props: NavbarViewProps = {
            onSelect: this.handleSelect,
            onLogout: this.handleLogout,
            onAddShop: this.handleAddShop,
            onChangeShop: this.handleChangeShop,
            isGlobal: this.isGlobal,
            shops: this.props.shops,
            activeShop: this.state.activeShop,
        };
        if (this.state.isLoggedIn) {
            if (this.state.isMobile) {
                return <MobileShopAdminNavbar {...props} />
            } else {
                return <DesktopShopAdminNavbar {...props} />
            }
        } else {
            return <UnauthenticatedNavbar {...props} />
        }
    }
}

const mapState = (state: RootState) => ({
    shops: state.shops.shops || [],
});

const connector = connect(mapState, {});

export default connector(Navbar)

export interface SidebarState {
    isLoggedIn: boolean

}

export interface SidebarProps extends RouteComponentProps { }

export class Sidebar extends Component<SidebarProps, SidebarState> {
    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            isLoggedIn: localStorage.getItem(Storage.IsLoggedIn) != null,
        };
    }

    handleSelect = (path: Path) => {
        this.props.history.push(path);
    };

    render() {
        const props: SidebarViewProps = {
            onSelect: this.handleSelect,
        };
        if (!this.state.isLoggedIn) {
            return <></>
        }
        return <SidebarView {...props} />
    }
}

export interface SidebarViewProps {
    onSelect: (path: Path) => void;
}


export const SidebarView: FunctionComponent<SidebarViewProps> = (props) => (
    <BNavbar className="nav-pills flex-column" style={{ height: "100vh" }}>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Home)}>
            <FaHome size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Dashboard</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Products)}>
            <FaBox size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Products</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Delivery)}>
            <FaLocationArrow size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Delivery</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Orders)}>
            <FaBoxes size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Orders</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Payments)}>
            <FaCreditCard size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Payments</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Customers)}>
            <MdPeople size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Customers</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Integrations)}>
            <FaPlug size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Integrations</span>
        </Nav.Link>
    </BNavbar>
)

export interface NavbarViewProps {
    shops: ShopAbridged[]
    activeShop: ShopAbridged
    onChangeShop: (shopID: string, addressID: string) => void;
    onAddShop: () => void;
    onSelect: (path: Path) => void;
    onLogout: () => void;
    isGlobal: () => boolean;
}

export const DesktopShopAdminNavbar: FunctionComponent<NavbarViewProps> = (props) => (
    <BNavbar className="elevate" style={{ background: Color.White, padding: 0, width: "100vw", position: "fixed", zIndex: 9 }}>
        <Container style={{ maxWidth: "100%", paddingLeft: "2rem", paddingRight: "2rem" }} fluid>
            <Nav style={{ textAlign: "center" }}>
                <img src={`/logos/${process.env.REACT_APP_LOGO}`} width={50} height={50} style={{ display: "inline-block" }} />
            </Nav>
            <Nav>
                <Dropdown as={NavItem} style={{ color: Color.DarkGrey }}>
                    <Dropdown.Toggle as={NavLink}>
                        <BNavbar.Brand
                            style={{ cursor: "pointer" }}
                        >
                            {(props.activeShop.logo && props.activeShop.logo != "") ? <img src={props.activeShop.logo} width={50} height={50} style={{ marginRight: ".3rem" }} /> : <MdAccountCircle size={30} style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top", color: Color.Primary }} />}
                            <span style={{ color: Color.Primary, fontWeight: "bold", fontSize: "1rem" }}>{props.activeShop.name}</span>
                        </BNavbar.Brand>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={"end"}>
                        <Dropdown.Item onClick={() => props.onAddShop()}>
                            <FaPlus style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                            <span>Add new shop</span>
                        </Dropdown.Item>
                        {!props.isGlobal() && props.shops.map(shop => (
                            <Dropdown.Item active={props.activeShop.id === shop.id && props.activeShop.addressId === shop.addressId} onClick={() => props.onChangeShop(shop.id, shop.addressId)}>
                                <FaStore style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                                <div style={{ display: "inline-block" }}>
                                    <span style={{ display: "block" }}>{shop.name}</span>
                                    <span style={{ display: "block", fontSize: ".7rem", width: "100%", wordWrap: "break-word" }}>{shop.address}</span>
                                </div>
                            </Dropdown.Item>
                        ))}
                        {props.isGlobal() &&
                            <Dropdown.Item active={true}>
                                <FaStore style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                                <div style={{ display: "inline-block" }}>
                                    <span style={{ display: "block" }}>{props.activeShop.name}</span>
                                    <span style={{ display: "block", fontSize: ".7rem", width: "100%", wordWrap: "break-word" }}>Global</span>
                                </div>
                            </Dropdown.Item>
                        }
                        <Dropdown.Item onClick={() => props.onLogout()}>
                            <MdLogout style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                            <span>Logout</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav>
        </Container>
    </BNavbar>
);

export const MobileShopAdminNavbar: FunctionComponent<NavbarViewProps> = (props) => (
    <BNavbar className="elevate" style={{ background: Color.White, paddingLeft: "2rem", paddingRight: "2rem" }} collapseOnSelect expand="lg">
        <BNavbar.Brand onClick={() => props.onSelect(Path.ShopDashboard)}>
            <img src={`/logos/${process.env.REACT_APP_LOGO}`} width={50} height={50} style={{ display: "inline-block" }} />
        </BNavbar.Brand>
        <BNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BNavbar.Collapse id="responsive-navbar-nav">
            <Nav style={{ textAlign: "center" }} className="mr-auto">
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Home)}>
                    <FaHome size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Dashboard</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Products)}>
                    <FaBox size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Products</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Delivery)}>
                    <FaLocationArrow size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Delivery</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Orders)}>
                    <FaBoxes size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Orders</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Payments)}>
                    <FaCreditCard size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Payments</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Integrations)}>
                    <FaPlug size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Integrations</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onLogout()}>
                    <MdLogout size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Logout</span>
                </Nav.Link>
            </Nav>
        </BNavbar.Collapse>
    </BNavbar>
);

export const UnauthenticatedNavbar: FunctionComponent<NavbarViewProps> = (
    props
) => (
    <BNavbar style={{ background: Color.White, position: "fixed", width: "100vw", zIndex: 9 }} variant="dark">
        <BNavbar.Brand>
            <img src={`/logos/${process.env.REACT_APP_LOGO}`} width={50} height={50} style={{ display: "inline-block" }} />
        </BNavbar.Brand>
        <Container style={{ maxWidth: "100%", paddingLeft: "2rem", paddingRight: "2rem" }} fluid>
            <Nav>
                <Nav.Link onClick={() => props.onSelect(Path.Login)}>
                    Login
                </Nav.Link>
            </Nav>
        </Container>
    </BNavbar>
);
