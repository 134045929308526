import { ShopAbridged } from "../../client/core";

export interface ShopsState {
    shops: ShopAbridged[];
}

export const UPDATE_SHOPS = "UPDATE_SHOPS";

export interface ShopsAction {
    type: string;
    payload: ShopAbridged[];
}
