import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Path, Storage } from "../../env";
import { Alert, Form } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { ContactDetails, SetShopContactDetails } from "../../client/core";
import { RouteComponentProps } from "react-router-dom";

export interface UpdateContactState {
    email: string;
    phone: string;
    whatsapp?: string;
    error: string;
}

export interface UpdateContactProps extends RouteComponentProps {
    email: string;
    phone: string;
    whatsapp?: string;
    auth: typeof Auth;
    setShopContactDetailsAPI: SetShopContactDetails;
    postSubmit: (contact: ContactDetails) => void;
}

export default class UpdateContact extends Component<
    UpdateContactProps,
    UpdateContactState
> {
    constructor(props: UpdateContactProps) {
        super(props);
        this.state = {
            email: this.props.email,
            phone: this.props.phone,
            whatsapp: (!this.props.whatsapp || this.props.whatsapp.length < 12) ? undefined : this.props.whatsapp,
            error: "",
        };
    }

    handleEmailChange = (email: string) => {
        this.setState({
            email: email,
        });
    };

    handlePhoneChange = (phone: string) => {
        this.setState({
            phone: phone,
        })
    }

    handleWhatsAppChange = (whatsapp: string) => {
        this.setState({
            whatsapp: whatsapp
        })
    }

    handleSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        if (this.state.email.trim() === "") {
            const error = "The email value must be present before submission.";
            return this.setState({
                error: error,
            });
        }

        if (this.state.phone.trim() === "") {
            const error = "The phone value must be present before submission.";
            return this.setState({
                error: error,
            });
        }

        let whatsapp: string | undefined
        if (this.state.whatsapp && this.state.whatsapp.trim() !== "") {
            whatsapp = this.state.whatsapp
        }

        if (whatsapp && (whatsapp.length < 9 || whatsapp.length > 12)) {
            const error = "The WhatsApps number has been populated but does not look correct. This number is optional so you can leave it blank if it's not required."
        }

        this.props.auth.currentSession().then(session => {
            this.props.setShopContactDetailsAPI.SetShopContactDetails({
                contactDetails: {
                    email: this.state.email,
                    phone: this.state.phone,
                    whatsapp: whatsapp,
                },
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID,
            }).then(response => {
                if (response.statusCode !== 201) {
                    return this.setState({
                        error: "Sorry, we could not update your shop alias at this time. Please check that all the details look correct."
                    })
                }

                this.props.postSubmit({
                    email: this.state.email,
                    phone: this.state.phone,
                    whatsapp: this.state.whatsapp,
                })
            })

        }).catch(err => {
            localStorage.removeItem(Storage.ShopID)
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.auth.signOut()
            this.props.history.push(Path.Login)
        })

    }

    render() {
        const props: UpdateContactFormProps = {
            email: this.state.email,
            phone: this.state.phone,
            whatsapp: this.state.whatsapp || "",
            error: this.state.error,
            onEmailChange: this.handleEmailChange,
            onPhoneChange: this.handlePhoneChange,
            onWhatsAppChange: this.handleWhatsAppChange,
            onSubmit: this.handleSubmit,
        };
        return <UpdateContactForm {...props} />;
    }
}

export interface UpdateContactFormProps {
    email: string;
    error: string;
    phone: string;
    whatsapp: string;
    onEmailChange: (alias: string) => void;
    onPhoneChange: (phone: string) => void;
    onWhatsAppChange: (whatsapp: string) => void;
    onSubmit: () => void;
}

export interface UpdateContactFormState {
    ImageName: string;
}

export const UpdateContactForm: FunctionComponent<UpdateContactFormProps> = (props) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">Email</div>
            </div>
            <input
                type="text"
                className="form-control"
                name={"email"}
                id={"email"}
                placeholder="Enter email address"
                value={props.email}
                onChange={(e) => props.onEmailChange(e.target.value)}
            />
        </div>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">Phone</div>
            </div>
            <input
                type="text"
                className="form-control"
                name={"phone"}
                id={"phone"}
                placeholder="Enter phone number"
                value={props.phone}
                onChange={(e) => props.onPhoneChange(e.target.value)}
            />
        </div>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">Phone</div>
            </div>
            <input
                type="text"
                className="form-control"
                name={"whatsapp"}
                id={"whatsapp"}
                placeholder="Enter WhatsApp number"
                value={props.whatsapp}
                onChange={(e) => props.onWhatsAppChange(e.target.value)}
            />
        </div>
        <Button style={{ width: "100%" }} variant={variant.Primary} name={"Submit"} onClick={() => props.onSubmit()} />
    </Form>
)