import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { Color, Path, Storage } from "../../env";
import { IGetIntegrations, IRegisterAccount, Integration } from "../../client/core";
import { boolean } from "@pact-foundation/pact/src/dsl/matchers";

export interface IntegrationsState {
    error: string
    integrations: Integration[]
}

export interface IntegrationsProps extends RouteComponentProps {
    auth: typeof Auth;
    getIntegrationsApi: IGetIntegrations;
    registerStripeAccountApi: IRegisterAccount;
}

export default class Integrations extends Component<IntegrationsProps, IntegrationsState> {
    constructor(props: IntegrationsProps) {
        super(props);
        this.state = {
            error: "",
            integrations: []
        };
    }

    componentDidMount(): void {
        const shopId = localStorage.getItem(Storage.ShopID)
        if (!shopId) {
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.getIntegrationsApi.GetIntegrations({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopId
            }).then(response => {
                if (response instanceof Error) {
                    return
                }

                if (response.statusCode == 403) {
                    return this.logout()
                }

                if (response.statusCode != 200) {
                    return this.setState({
                        error: "Sorry, we could not retrieve the shop integrations at this time. If this problem persists please contact support@stumbled.online."
                    })
                }

                this.setState({
                    integrations: response.body?.integrations || []
                })
            }).catch(err => {
                return this.setState({
                    error: "Sorry, we could not retrieve the shop integrations at this time. If this problem persists please contact support@stumbled.online."
                })
            })
        }).catch(err => {
            return this.logout()
        })
    }

    logout = () => {
        localStorage.removeItem(Storage.ShopID)
        localStorage.removeItem(Storage.IsLoggedIn)
        this.props.auth.signOut()
        this.props.history.push(Path.Login)
    }

    handleZettleIntegration = () => {
        const clientID = process.env.REACT_APP_ZETTLE_CLIENT_ID
        const returnURL = `${window.location.protocol}//${window.location.host}${Path.Integrations}/zettle`
        const link = `https://oauth.zettle.com/authorize?response_type=code&scope=READ:PRODUCT WRITE:PRODUCT&client_id=${clientID}&redirect_uri=${returnURL}&state=abc123678`
        return window.location.replace(link)
    }

    handleStripeIntegration = () => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            return this.props.auth.signOut()
        }
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.registerStripeAccountApi
                    .RegisterAccount({
                        identityToken: token,
                        shopID: shopID!,
                        refreshURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.Payments,
                        returnURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.Payments,
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            localStorage.removeItem(Storage.IsLoggedIn)
                            return this.props.history.push(Path.Login);
                        }

                        if (output.statusCode != 200) {
                            return this.setState({
                                error: "Sorry, something unexpected went wrong during the registration. Please try again later.",
                            });
                        }

                        return window.location.replace(output.accountLink);
                    })
                    .catch(() =>
                        this.setState({
                            error: "Sorry, something unexpected went wrong during the registration. Please try again later.",
                        })
                    );
            })
            .catch(() => {
                localStorage.removeItem(Storage.IsLoggedIn)
                this.props.history.push(Path.Login);
            });
    };

    render() {
        const viewIntegrationsProps: IntegrationsViewProps = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
            activeIntegrations: this.state.integrations,
            onZettleIntegration: this.handleZettleIntegration,
            onStripeIntegration: this.handleStripeIntegration,
        };
        return <ViewIntegrations {...viewIntegrationsProps} />;
    }
}

export interface IntegrationsViewProps extends RouteComponentProps {
    activeIntegrations: Integration[];
    onZettleIntegration: () => void;
    onStripeIntegration: () => void;
}

export const ViewIntegrations: FunctionComponent<IntegrationsViewProps> = (props) => (
    <div>
        <h1 style={{ marginBottom: "2rem" }}>Integrations</h1>
        <Tabs
            defaultActiveKey={"Active"}
            className="mb-3"
            variant="pills"
        >
            <Tab eventKey={"Active"} title="Active">
                <Row style={{ marginBottom: "2rem" }}>
                    <Row>
                        {(isInIntegrations("Zettle", props.activeIntegrations)) && <Col xs={12} sm={6} xl={4} xxl={3} style={{ marginBottom: "1rem" }}>
                            <Card>
                                <Card.Img style={{ padding: "1.5rem" }} height={250} variant="top" src={`${window.location.protocol}//${window.location.hostname}${(window.location.port !== "") ? `:${window.location.port}` : ""}/thirdparty/zettle.png`} />
                                <Card.Body style={{ borderTop: `.5px solid ${Color.Grey}` }}>
                                    <Button style={{ width: "100%" }} variant={variant.Primary} name={"Reactivate"} onClick={() => props.onZettleIntegration()} />
                                </Card.Body>
                            </Card>
                        </Col>}
                        {(isInIntegrations("Stripe", props.activeIntegrations)) && <Col xs={12} sm={6} xl={4} xxl={3} style={{ marginBottom: "1rem" }}>
                            <Card>
                                <Card.Img variant="top" height={250} src={`${window.location.protocol}//${window.location.hostname}${(window.location.port !== "") ? `:${window.location.port}` : ""}/thirdparty/stripe.png`} />
                                <Card.Body style={{ borderTop: `.5px solid ${Color.Grey}` }}>
                                    <Button variant={variant.Primary} style={{ width: "100%" }} onClick={props.onStripeIntegration} name={"Settings"} />
                                </Card.Body>
                            </Card>
                        </Col>}
                    </Row>
                </Row>
            </Tab>
            <Tab eventKey={"Other"} title="Other">
                <Row>
                    {(!isInIntegrations("Zettle", props.activeIntegrations)) && <Col xs={12} sm={6} xl={4} xxl={3} style={{ marginBottom: "1rem" }}>
                        <Card>
                            <Card.Img style={{ padding: "1.5rem" }} height={250} variant="top" src={`${window.location.protocol}//${window.location.hostname}${(window.location.port !== "") ? `:${window.location.port}` : ""}/thirdparty/zettle.png`} />
                            <Card.Body style={{ borderTop: `.5px solid ${Color.Grey}` }}>
                                <Button style={{ width: "100%" }} variant={variant.Primary} name={"Activate"} onClick={() => props.onZettleIntegration()} />
                            </Card.Body>
                        </Card>
                    </Col>}
                    {(!isInIntegrations("Stripe", props.activeIntegrations)) && <Col xs={12} sm={6} xl={4} xxl={3} style={{ marginBottom: "1rem" }}>
                        <Card>
                            <Card.Img variant="top" height={250} src={`${window.location.protocol}//${window.location.hostname}${(window.location.port !== "") ? `:${window.location.port}` : ""}/thirdparty/stripe.png`} />
                            <Card.Body style={{ borderTop: `.5px solid ${Color.Grey}` }}>
                                <Button variant={variant.Primary} style={{ width: "100%" }} onClick={props.onStripeIntegration} name={"Activate"} />
                            </Card.Body>
                        </Card>
                    </Col>}
                </Row>
            </Tab>
        </Tabs>
    </div>
)

const isInIntegrations = (name: string, integrations: Integration[]): boolean => {
    if (!integrations) {
        return false
    }
    const filtered = integrations.filter(integration => integration.name == name)
    return filtered.length > 0
}
